import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { string, node, arrayOf, func, bool } from 'prop-types';
import clsx from 'clsx';
import { useClickAway } from 'react-use';
import { useChatStore } from 'stores/chat';

import Spinner from 'components/Spinner/Spinner';

import styles from './Modal.module.scss';

const chatSelector = (state) => state.isChatExpanded;

function Modal({
  children,
  className,
  title,
  secondaryTitle,
  content,
  buttons,
  onClickOutside,
  willJustifyContentLeft,
  willAlignContentBottom,
  willFill,
  isPrizeModal,
  isLoading,
}) {
  const modalWrapperRef = useRef(document.getElementById('modal-root'));
  const innerRef = useRef(null);
  const isChatExpanded = useChatStore(chatSelector);

  const renderContent = () =>
    children || (
      <div className={styles.modal}>
        {title && (
          <h3 className={clsx(styles.title, styles.lessMargin)}>{title}</h3>
        )}
        {secondaryTitle && <h3 className={styles.title}>{secondaryTitle}</h3>}
        <div className={styles.content}>{content && content}</div>

        {buttons.length > 0 && (
          <div className={styles.footer}>{buttons.map((button) => button)}</div>
        )}
      </div>
    );

  useClickAway(innerRef, onClickOutside);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.keyCode === 27) {
        onClickOutside();
      }
    };

    document.addEventListener('keydown', onKeyDown, false);

    // Clean up.
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onClickOutside]);

  useLayoutEffect(() => {
    // If the doom hasn't loaded when the modal is shown,
    // we fetch it again with useLayoutEffect
    modalWrapperRef.current = document.getElementById('modal-root');
  }, []);

  if (!modalWrapperRef.current) return null;

  return createPortal(
    <div
      className={clsx(className, styles.wrapper, {
        [styles.willJustifyLeft]: willJustifyContentLeft,
        [styles.willAlignBottom]: willAlignContentBottom,
        [styles.isPrizeModal]: isPrizeModal,
        [styles.isChatExpanded]: isChatExpanded,
      })}
    >
      <div
        ref={innerRef}
        className={clsx({
          [styles.willFill]: willFill,
        })}
      >
        {isLoading && <Spinner />}
        {!isLoading && renderContent()}
      </div>
    </div>,
    modalWrapperRef.current
  );
}

Modal.propTypes = {
  className: string,
  children: node,
  title: string,
  secondaryTitle: string,
  content: node,
  buttons: arrayOf(node),
  onClickOutside: func,
  willJustifyContentLeft: bool,
  willAlignContentBottom: bool,
  willFill: bool,
  isLoading: bool,
  isPrizeModal: bool,
};

Modal.defaultProps = {
  className: null,
  children: null,
  title: null,
  secondaryTitle: null,
  content: null,
  buttons: [],
  onClickOutside: () => {},
  willJustifyContentLeft: false,
  willAlignContentBottom: false,
  willFill: false,
  isLoading: false,
  isPrizeModal: false,
};

export default Modal;
