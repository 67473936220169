import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTransition, animated } from 'react-spring';
import { shallow } from 'zustand/shallow';

import { BINGO_30, BINGO_40, BINGO_60, BINGO_80 } from 'utils/constants';
import { generateBingoCardMap } from 'utils/helpers';
import { useChatStore } from 'stores/chat';
import useHistoryStore from 'stores/history';
import useGameStore, { GAME_REPLAY, PURCHASE_VIEW } from 'stores/game';
import useGameStatsStore from 'stores/current-game';
import useLayoutStore from 'stores/layout';

import Button from 'components/Button/Button';
import BingoCard from 'components/BingoCard/BingoCard';

import styles from './History.module.scss';

const numbersMap = generateBingoCardMap();
const longTicketNumbersMap = generateBingoCardMap(10, 9, false);
const bingo30NumbersMap = generateBingoCardMap(10, 3, false);
const bingo40NumbersMap = generateBingoCardMap(10, 4, false);
const bingo60NumbersMap = generateBingoCardMap(15, 4, false);
const bingo80NumbersMap = generateBingoCardMap(10, 8, false);

const isChatExpandedSelector = (state) => state.isChatExpanded;
const isVisibleSelector = (state) => [
  state.isHistoryVisible,
  state.setIsHistoryVisible,
];
const gameStateSelector = (state) => state.gameState;
const gameTypeSelector = (state) => state.currentGame.gameTypeCode;
const isLongTicketsSelector = (state) => state.isLongTickets;
const isTinyTicketsSelector = (state) => state.isTinyTickets;

function History() {
  const isChatExpanded = useChatStore(isChatExpandedSelector);
  const [isVisible, setIsVisible] = useHistoryStore(isVisibleSelector, shallow);
  const gameState = useGameStore(gameStateSelector);
  const gameTypeCode = useGameStatsStore(gameTypeSelector);
  const isLongsTicket = useLayoutStore(isLongTicketsSelector);
  const isTinyTickets = useLayoutStore(isTinyTicketsSelector);
  const isReplay = gameState === GAME_REPLAY;
  const isTinyButTall = isTinyTickets && gameTypeCode === BINGO_60;

  const isLongHistoryCard = isLongsTicket || gameTypeCode === BINGO_80;

  const onClickClose = () => setIsVisible(false);

  useEffect(() => {
    if (gameState === PURCHASE_VIEW && isVisible) {
      setIsVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState]);

  // We don't want the history bingo card to be rendering in the bg,
  // so we mount and unmount it when it becomes visible.
  const transitionsBingoCard = useTransition(isVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const returnNumbersMap = () => {
    if (isTinyTickets) {
      switch (gameTypeCode) {
        case BINGO_30:
          return bingo30NumbersMap;
        case BINGO_40:
          return bingo40NumbersMap;
        case BINGO_60:
          return bingo60NumbersMap;
        case BINGO_80:
          return bingo80NumbersMap;
        default:
          return bingo30NumbersMap;
      }
    }
    if (isLongsTicket) {
      return longTicketNumbersMap;
    }
    return numbersMap;
  };

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isVisible]: isVisible,
        [styles.isChatExpanded]: isReplay ? false : isChatExpanded,
        [styles.isLongsTicket]: isLongHistoryCard,
        [styles.isTinyTickets]:
          isTinyTickets && !isLongHistoryCard && gameTypeCode !== BINGO_60,
        [styles.isTinyButTall]: isTinyButTall,
      })}
    >
      <div className={styles.content}>
        <Button
          className={clsx(styles.closeButton, {
            [styles.isLongsTicket]: isLongHistoryCard,
          })}
          isModalCloseButton
          onClick={onClickClose}
        />

        {transitionsBingoCard.map(
          ({ item, key, props }) =>
            item && (
              <animated.div key={key} style={props}>
                <BingoCard
                  id="bingo-card-history"
                  numbersMap={returnNumbersMap()}
                  isLarge={!isLongHistoryCard}
                  isHistory
                  isDisabled
                  isLongTicketHistoryCard={!!isLongHistoryCard}
                />
              </animated.div>
            )
        )}
      </div>
    </div>
  );
}

export default History;
