import { useState, useEffect, useRef } from 'react';
import throttle from 'lodash.throttle';
import useMenuStore from 'stores/menu';

export const MOBILE = 'mobile';
// export const MOBILE_LANDSCAPE = 'mobile-landscape';
export const TABLET_PORTRAIT = 'tablet-portrait';
export const TABLET_LANDSCAPE = 'tablet-landscape';
export const DESKTOP = 'desktop';
// export const DESKTOP_LARGE = 'desktop-large';

const smallMobile = 374;
// const mobileLandscape = 667;
const tabletPortrait = 768;
// const tabletLandscape = 1024;
const desktop = 1181;
// const desktopLarge = 1400;

export const getBreakpointsConfig = (width, height) => {
  if (width < tabletPortrait) {
    return MOBILE;
  }
  // if (width >= mobileLandscape && width < tabletPortrait) {
  //   return MOBILE_LANDSCAPE;
  // }
  if (width >= tabletPortrait && width < desktop) {
    if (height < width && width > 1023) {
      return TABLET_LANDSCAPE;
    }
    return TABLET_PORTRAIT;
  }
  // if (width >= tabletLandscape && width < desktop) {
  //   return TABLET_LANDSCAPE;
  // }
  if (width >= desktop) {
    return DESKTOP;
  }
  // if (width >= desktopLarge) {
  //   return DESKTOP_LARGE;
  // }
};

const isMenuExpandedSelector = (state) => state.setIsMenuExpanded;

const useBreakpoint = () => {
  const setIsExpanded = useMenuStore(isMenuExpandedSelector);
  const [breakpoint, setBreakpoint] = useState(() =>
    getBreakpointsConfig(window.innerWidth, window.innerHeight)
  );
  const mountedRef = useRef(true);

  useEffect(() => {
    const onResize = throttle(() => {
      if (mountedRef.current) {
        if (
          window.innerWidth <= smallMobile ||
          window.innerWidth >= tabletPortrait
        ) {
          setIsExpanded(false);
        }
        setBreakpoint(
          getBreakpointsConfig(window.innerWidth, window.innerHeight)
        );
      }
    }, 500);
    window.addEventListener('resize', onResize);

    return () => {
      mountedRef.current = false;
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return breakpoint;
};

export default useBreakpoint;
