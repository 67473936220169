import React from 'react';
import { func } from 'prop-types';
import { text } from 'polyglot/polyglot';

import useBreakpoint, { DESKTOP, MOBILE } from 'hooks/useBreakpoint';
import tutorialStore from 'stores/tutorial';
import useGameStore, { ACTIVE_GAME } from 'stores/game';

import ChatIcon from 'assets/svg/chat-icon.svg';

import LayoutButtons from 'components/TopbarPanel/LayoutButtons/LayoutButtons';
import Dialogue from '../Dialogue/Dialogue';

import styles from './Step5ViewChat.module.scss';

const chatIconPositionSelector = (state) => [
  state.chatIconPosition,
  state.setChatIconPosition,
];
const layoutButtonsPositionSelector = (state) => state.layoutButtonsPosition;
const gameStateSelector = (state) => state.gameState;

function Step5ViewChat({ nextStep, closeTutorial }) {
  const [chatIconPosition] = tutorialStore(chatIconPositionSelector);
  const layoutButtonsPosition = tutorialStore(layoutButtonsPositionSelector);
  const gameState = useGameStore(gameStateSelector);
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === DESKTOP;
  const isMobile = breakpoint === MOBILE;

  let chatIconPositionLeft = 0;
  let chatIconPositionTop = 0;
  if (chatIconPosition.chatReference) {
    chatIconPositionLeft = chatIconPosition.chatReference.left;
    chatIconPositionTop = chatIconPosition.chatReference.top - 150;
  }

  let layoutButtonsTop = 0;
  let layoutButtonsRight = 0;
  if (layoutButtonsPosition.layoutButtonsPosition) {
    layoutButtonsTop = layoutButtonsPosition.layoutButtonsPosition.y;
    layoutButtonsRight =
      window.innerWidth - layoutButtonsPosition.layoutButtonsPosition.right;
  }

  return (
    <div
      className={styles.wrapper}
      style={
        !isDesktop
          ? {
              left: chatIconPositionLeft,
              top: chatIconPositionTop,
            }
          : {
              top: layoutButtonsTop,
              right: layoutButtonsRight,
            }
      }
    >
      {!isDesktop && (
        <Dialogue
          className={styles.step5Dialogue}
          dialogueText={
            isDesktop
              ? text.t('tutorial.step5ViewText')
              : text.t('tutorial.step5ChatText')
          }
          nextStep={nextStep}
          closeTutorial={closeTutorial}
          hasBottomArrow
          hasFarRightPositionedArrow
          hasCloseButtonLeft
          customStyling={isMobile ? { marginLeft: -277 } : { marginLeft: -268 }}
          isLastStep
          reversedTransition
        />
      )}
      {isDesktop ? (
        <div className={styles.layoutButtons}>
          <LayoutButtons isPartOfTutorial />
        </div>
      ) : (
        <div className={styles.chatIconWrapper}>
          <ChatIcon
            className={
              gameState === ACTIVE_GAME
                ? styles.chatIconActiveGame
                : styles.chatIcon
            }
          />
        </div>
      )}
      {isDesktop && (
        <Dialogue
          className={styles.step5Dialogue}
          dialogueText={
            isDesktop
              ? text.t('tutorial.step5ViewText')
              : text.t('tutorial.step5ChatText')
          }
          nextStep={nextStep}
          closeTutorial={closeTutorial}
          customStyling={{ marginRight: -128 }}
          isLastStep
        />
      )}
    </div>
  );
}

Step5ViewChat.propTypes = {
  nextStep: func.isRequired,
  closeTutorial: func.isRequired,
};

export default Step5ViewChat;
