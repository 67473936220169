import React, { useEffect, useState, useRef } from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';
import { shallow } from 'zustand/shallow';
import useUserStore from 'stores/user';
import useSettingsStore from 'stores/settings';
import useApiErrorStore from 'stores/api-error';
import useTutorialStore from 'stores/tutorial';
import SoundManager from 'utils/SoundManager';
import api from 'utils/api';
import { text } from 'polyglot/polyglot';
import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';

import Button from 'components/Button/Button';
import SettingsWindow from 'components/SettingsWindow/SettingsWindow';
import Modal from 'components/Modal/Modal';

import ReturnArrow from 'assets/svg/return-arrow.svg';
import Cogwheel from 'assets/svg/settings-cogwheel.svg';
import SoundOn from 'assets/svg/sound-speaker-on.svg';
import SoundOff from 'assets/svg/sound-speaker-off.svg';

import styles from './SettingButtons.module.scss';

const userSelector = (state) => state.user;
const soundSelector = (state) => [state.settings.muted, state.updateSettings];
const announcerSelector = (state) => state.settings.announcer;
const settingsSelector = (state) => state.settings;
const apiErrorSelector = (state) => state.setErrorMessage;
const setCogwheelPositionSelector = (state) => state.setCogwheelPosition;
const setSpeakerPositionSelector = (state) => state.setSpeakerPosition;

function SettingButtons({ isWhite, onlySoundButton }) {
  const settings = useSettingsStore(settingsSelector);
  const [isMute, setMute] = useSettingsStore(soundSelector, shallow);
  const announcer = useSettingsStore(announcerSelector);
  const [modalIsVisible, setModalVisibility] = useState(false);
  const user = useUserStore(userSelector);
  const setApiErrorMessage = useApiErrorStore(apiErrorSelector);
  const setCogwheelPosition = useTutorialStore(setCogwheelPositionSelector);
  const setSpeakerPosition = useTutorialStore(setSpeakerPositionSelector);
  const breakpoint = useBreakpoint();
  const cogwheelPositionRef = useRef();
  const soundPositionRef = useRef();

  const updateCogwheelPosition = () => {
    if (breakpoint === DESKTOP && cogwheelPositionRef.current) {
      setCogwheelPosition(cogwheelPositionRef.current.getBoundingClientRect());
      setSpeakerPosition(soundPositionRef.current.getBoundingClientRect());
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateCogwheelPosition);
    updateCogwheelPosition();
    return () => {
      window.removeEventListener('resize', updateCogwheelPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSetting = () => {
    setModalVisibility(!modalIsVisible);
  };

  const onClickSound = () => {
    const data = {
      autoSort: settings.autosort,
      autoMark: settings.automark,
      symbol: settings.symbol,
      color: settings.color,
      announcer: settings.announcer,
      effects: settings.effects,
      music: settings.music,
      userAvatar: settings.userAvatar,
      muted: !isMute,
    };
    setMute({ muted: !isMute });
    if (user.isAuthenticated) {
      api
        .put('/api/Bingo/clientsetting', data)
        .then(() => {})
        .catch((err) => {
          console.log('err: ', err);
          setApiErrorMessage(4);
        });
    }
  };

  useEffect(() => {
    SoundManager.instance.muteApp(isMute);
  }, [isMute]);

  useEffect(() => {
    SoundManager.instance.updateAnnouncerSound(announcer);
  }, [announcer]);

  if (onlySoundButton) {
    return (
      <button type="button" onClick={onClickSound}>
        {isMute ? (
          <span className={styles.soundText}>
            <SoundOff className={styles.menuSoundIcon} />
            {text.t('topPanel.soundOffText')}
          </span>
        ) : (
          <span className={styles.soundText}>
            <SoundOn className={styles.menuSoundIcon} />
            {text.t('topPanel.soundOnText')}
          </span>
        )}
      </button>
    );
  }

  return (
    <div className={styles.wrapper}>
      {modalIsVisible && (
        <Modal willJustifyContentLeft willAlignContentBottom>
          <div className={styles.modalWrapper}>
            <SettingsWindow />
            <Button
              isModalCloseButton
              onClick={onClickSetting}
              className={styles.modalCloseButton}
            />
          </div>
        </Modal>
      )}
      <a href={user.returnButtonURL}>
        <Button isIcon onClick={() => {}}>
          <ReturnArrow
            className={clsx(styles.icon, styles.arrow, {
              [styles.white]: isWhite,
            })}
          />
        </Button>
      </a>
      {user.isAuthenticated && (
        <span ref={cogwheelPositionRef}>
          <Button isIcon onClick={onClickSetting}>
            <Cogwheel
              className={clsx(styles.icon, {
                [styles.white]: isWhite,
              })}
            />
          </Button>
        </span>
      )}
      <span ref={soundPositionRef}>
        <Button isIcon onClick={onClickSound}>
          {isMute ? (
            <SoundOff
              className={clsx(styles.icon, {
                [styles.white]: isWhite,
              })}
            />
          ) : (
            <SoundOn
              className={clsx(styles.icon, {
                [styles.white]: isWhite,
              })}
            />
          )}
        </Button>
      </span>
    </div>
  );
}

SettingButtons.propTypes = {
  isWhite: bool,
  onlySoundButton: bool,
};

SettingButtons.defaultProps = {
  isWhite: false,
  onlySoundButton: false,
};

export default SettingButtons;
