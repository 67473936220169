/* eslint-disable no-unreachable */
/* eslint-disable import/no-unresolved */
import { Application, Assets } from 'pixi.js';

import debounce from 'utils/debounce';
import gsap from 'gsap';
import FontFaceObserver from 'fontfaceobserver';
import MathUtils from 'utils/MathUtils';

// import SheetJSON from 'assets/sheets/pot_booster_anim.json';
// import SheetImage from 'assets/sheets/pot_booster_anim.png';
import RocketAnim from './RocketAnim';
import Header from './Header';
import PrizeInfo from './PrizeInfo';

// import Resources from './Resources';

export default class PixiApp {
  init(htmlElement, potData, completeCallback) {
    this.width = htmlElement.clientWidth;
    this.height = htmlElement.clientHeight;
    this.htmlElement = htmlElement;
    this.completeCallback = completeCallback;

    this.potData = potData;
    /*
    this.potData = {
      multiplier: 1000,
      multiplierList: [2, 3, 4, 5, 10, 20, 100, 500, 1000],
      pot: 160,
      total: 10000,
      time: 12000,
    };
    */

    this.animType = 'default';
    this.totalTime = this.potData.time;
    if (this.totalTime < 4000) {
      this.animType = 'fast';
    } else if (this.totalTime < 8000) {
      this.animType = 'medium';
    }

    window.addEventListener('resize', this.onResize);

    this.setupApp();
    this.setupLoading();
  }

  setupApp() {
    this.app = new Application({
      width: this.width,
      height: this.height,
      antialias: true,
      autoDensity: true,
      resolution: window.devicePixelRatio,

      backgroundColor: 0x000000,
      backgroundAlpha: 0,
    });
    this.htmlElement.appendChild(this.app.view);
    this.display = this.app.stage;

    const scale = MathUtils.map(this.height, 500, 1200, 0.3, 0.5);
    // console.log(this.height, scale);
    this.defaultScale = scale;
  }

  async setupLoading() {
    let assetPath = '';
    if (window.publicPath) {
      assetPath = window.publicPath;
    }
    await Assets.load(`${assetPath}assets/sheets/pot_booster_anim.png`);
    this.assets = await Assets.load(
      `${assetPath}assets/sheets/pot_booster_anim.json`
    );

    const font = new FontFaceObserver('Zing Rust Demo Base');
    font.load().then(() => {
      console.log('Zing Rust Demo Base');
      this.setupHeader();
      this.setupRocketAnimation();
      this.start();
    });

    const fontA = new FontFaceObserver('Apercu Pro');
    const fontB = new FontFaceObserver('Apercu Pro Bold');

    Promise.all([fontA.load(), fontB.load()]).then(() => {
      console.log('Apercu');
      this.setupPrizeInfo();
    });
  }

  setupHeader() {
    this.header = new Header(this.defaultScale);
    this.display.addChild(this.header.container);

    this.header.container.x = this.width * 0.5;
    this.header.container.y = this.height * 0.16;
  }

  setupPrizeInfo() {
    this.prizeInfo = new PrizeInfo(
      this.potData.pot,
      this.potData.multiplier,
      this.potData.total,
      this.animType,
      () => {
        this.onCompleteTotalPot();
      },
      this.defaultScale
    );
    this.display.addChild(this.prizeInfo.container);

    this.prizeInfo.container.x = this.width * 0.5;
    this.prizeInfo.container.y = this.height * 0.75;
  }

  setupRocketAnimation() {
    this.rocketAnim = new RocketAnim(
      this.defaultScale,
      this.assets,
      this.potData,
      this.animType,
      () => {
        this.onMultiplierShowComplete();
      },
      () => {
        this.onEngineStopComplete();
      }
    );
    this.rocketHeight = this.rocketAnim.rocketAnim.height * this.defaultScale;

    this.rocketDefaultPos = {
      x: this.width / 2,
      y: this.height / 2,
    };
    this.rocketAnim.container.x = this.rocketDefaultPos.x;
    this.rocketAnim.container.y = this.height + this.defaultScale * 250;
    this.rocketAnim.container.alpha = 0;
    this.display.addChild(this.rocketAnim.container);
  }

  showRocket() {
    this.tlIntro = gsap.timeline({ paused: true });

    const smallScale = 0.3;
    // --------------------- 1 ---------------------
    this.tlIntro.to(this.rocketAnim.container, 1, {
      ease: 'power1.inOut',
      startAt: { alpha: 1 },
      y: this.height - this.defaultScale * 250,
    });
    this.tlIntro.to(
      this.rocketAnim.container.scale,
      0.5,
      { startAt: { x: smallScale, y: smallScale } },
      '<'
    );
    // --------------------- 2 ---------------------
    this.tlIntro.to(this.rocketAnim.container, 1.3, {
      ease: 'power3.inOut',
      y: this.height * 0.3,
    });
    this.tlIntro.to(
      this.rocketAnim.container.scale,
      0.65,
      {
        ease: 'power3.inOut',
        x: smallScale - 0.1,
      },
      '<'
    );
    this.tlIntro.to(
      this.rocketAnim.container.scale,
      0.6,
      {
        ease: 'power3.inOut',
        x: smallScale,
      },
      '<0.5'
    );
    // --------------------- 3 ---------------------
    this.tlIntro.to(this.rocketAnim.container.scale, 2.2, {
      ease: 'power3.inOut',
      x: 1,
      y: 1,
      onStart: () => {
        console.log('start');
        this.rocketAnim.showMultiplier(this.potData.multiplier);
      },
    });
    this.tlIntro.to(
      this.rocketAnim.container,
      2.2,
      {
        ease: 'power3.inOut',
        y: this.rocketDefaultPos.y + 40 * this.defaultScale,
      },
      '<'
    );
    this.tlIntro.to(
      this.rocketAnim.container,
      0.5,
      {
        alpha: 1,
      },
      'end'
    );
    if (this.animType === 'fast') {
      // this.tlIntro.timeScale(4);
      this.tlIntro.seek('end');
      this.rocketAnim.showMultiplier(this.potData.multiplier);
    } else if (this.animType === 'medium') {
      this.tlIntro.timeScale(2);
    }

    this.tlIntro.play();
  }

  onMultiplierShowComplete() {
    this.tlOutro = gsap.timeline({ paused: true });

    this.tlOutro.to(this.rocketAnim.container, {
      ease: 'power1.inOut',
      duration: 1.2,
      y: this.rocketDefaultPos.y + 50 * this.defaultScale,
    });
    this.tlOutro.to(
      this.rocketAnim.container.scale,
      { duration: 1, x: 0.85, y: 0.85 },
      '<'
    );
    this.tlOutro.call(
      () => {
        this.rocketAnim.stopEngine();
      },
      null,
      '<'
    );
    this.tlOutro.call(
      () => {
        this.prizeInfo.show();
      },
      null,
      '+=0.1'
    );

    if (this.animType === 'fast') {
      this.tlOutro.timeScale(4);
    } else if (this.animType === 'medium') {
      this.tlOutro.timeScale(2);
    }

    this.tlOutro.play();
  }

  onEngineStopComplete() {
    // console.log('onEngineStopComplete');
  }

  onCompleteTotalPot() {
    // eslint-disable-next-line no-unused-vars
    const diff = (this.startTime - new Date().getTime()) / 1000;
    console.log('Total time seconds: ', diff);
    gsap.delayedCall(2, () => {
      this.completeCallback();
    });
  }

  start() {
    this.startTime = new Date().getTime();

    this.header.show();
    this.showRocket();
    this.app.ticker.add(() => {
      this.update();
    });
  }

  update() {
    // update stuff
    this.rocketAnim.update();
  }

  onResize = debounce(() => {
    if (this.app) {
      const parent = this.app.view.parentNode;
      this.width = parent.clientWidth;
      this.height = parent.clientHeight;
      this.app.renderer.resize(parent.clientWidth, parent.clientHeight);

      if (this.header) {
        this.header.container.x = this.width * 0.5;
        this.header.container.y = this.height * 0.16;
      }

      if (this.rocketAnim?.container) {
        this.rocketAnim.container.x = this.width / 2;
      }

      if (this.prizeInfo) {
        this.prizeInfo.container.x = this.width * 0.5;
        this.prizeInfo.container.y = this.height * 0.75;
      }
    }
  }, 150);

  destroy() {
    if (this.header) {
      this.header.destroy();
      this.header = null;
    }
    if (this.rocketAnim) {
      this.rocketAnim.destroy();
    }
    if (this.app) {
      this.app.destroy();
      this.app = null;
    }

    if (this.prizeInfo) {
      this.prizeInfo.destroy();
      this.prizeInfo = null;
    }

    if (this.tlIntro) {
      this.tlIntro.kill();
      this.tlIntro = null;
    }
  }
}
