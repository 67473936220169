import React from 'react';
import { func, string, bool, shape } from 'prop-types';
import { text } from 'polyglot/polyglot';
import clsx from 'clsx';

import Button from 'components/Button/Button';
import ChevronIcon from 'assets/svg/arrowhead-no-fill.svg';
import ArrowHead from 'assets/svg/tutorial-triangle.svg';

import styles from './Dialogue.module.scss';

function Dialogue({
  className,
  dialogueText,
  nextStep,
  closeTutorial,
  hasCloseButtonLeft,
  hasBottomArrow,
  hasRightPositionedArrow,
  hasFarRightPositionedArrow,
  hasLeftPositionedArrow,
  hasMiddleLeftPositionedArrow,
  customStyling,
  reversedTransition,
  delayTransition,
  isLastStep,
}) {
  return (
    <div className={clsx(styles.wrapper, className)} style={customStyling}>
      {hasCloseButtonLeft && (
        <Button
          className={clsx(styles.closeButton, styles.transition, {
            [styles.isReversed]: reversedTransition,
            [styles.isDelayed]: delayTransition,
          })}
          onClick={closeTutorial}
          isModalCloseButton
        />
      )}
      <div
        className={clsx(styles.dialogueBox, styles.transition, {
          [styles.isReversed]: reversedTransition,
          [styles.isDelayed]: delayTransition,
        })}
      >
        <ArrowHead
          className={clsx(styles.arrowIcon, {
            [styles.hasBottomArrow]: hasBottomArrow,
            [styles.hasRightPositionedArrow]: hasRightPositionedArrow,
            [styles.hasFarRightPositionedArrow]: hasFarRightPositionedArrow,
            [styles.hasLeftPositionedArrow]: hasLeftPositionedArrow,
            [styles.hasMiddleLeftPositionedArrow]: hasMiddleLeftPositionedArrow,
          })}
        />
        <p>{dialogueText}</p>
        <Button
          onClick={isLastStep ? closeTutorial : nextStep}
          isClean
          className={styles.nextButton}
        >
          {isLastStep
            ? text.t('tutorial.lastStepButton')
            : text.t('tutorial.nextStepButton')}
          {!isLastStep && <ChevronIcon className={styles.icon} />}
        </Button>
      </div>
      {!hasCloseButtonLeft && (
        <Button
          className={clsx(styles.closeButton, styles.transition, {
            [styles.isReversed]: reversedTransition,
            [styles.isDelayed]: delayTransition,
          })}
          onClick={closeTutorial}
          isModalCloseButton
        />
      )}
    </div>
  );
}

Dialogue.propTypes = {
  className: string,
  dialogueText: string.isRequired,
  nextStep: func.isRequired,
  closeTutorial: func.isRequired,
  hasCloseButtonLeft: bool,
  hasBottomArrow: bool,
  hasRightPositionedArrow: bool,
  hasFarRightPositionedArrow: bool,
  hasLeftPositionedArrow: bool,
  hasMiddleLeftPositionedArrow: bool,
  customStyling: shape(),
  reversedTransition: bool,
  delayTransition: bool,
  isLastStep: bool,
};

Dialogue.defaultProps = {
  className: '',
  hasCloseButtonLeft: false,
  hasBottomArrow: false,
  hasRightPositionedArrow: false,
  hasFarRightPositionedArrow: false,
  hasLeftPositionedArrow: false,
  hasMiddleLeftPositionedArrow: false,
  customStyling: {},
  reversedTransition: false,
  delayTransition: false,
  isLastStep: false,
};

export default Dialogue;
