/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { text, switchLanguage } from 'polyglot/polyglot';
import { shallow } from 'zustand/shallow';
import { install } from 'resize-observer';
import Bowser from 'bowser';
import NoSleep from 'nosleep.js';

import { object } from 'prop-types';
import api from 'utils/api';
import usePurchaseStore from 'stores/purchase';
import useUserStore from 'stores/user';
import useSettingsStore from 'stores/settings';
import useWinnerStatsStore from 'stores/winner-stats';
import useNumbersDrawnStore from 'stores/numbers-drawn';
import useApiErrorStore from 'stores/api-error';
import { useChatStore } from 'stores/chat';
import useGameStore, {
  NONE,
  ACTIVE_GAME,
  PURCHASE_VIEW,
  GAME_REPLAY,
  POT_BOOSTER,
} from 'stores/game';
import useCurrentGameStore from 'stores/current-game';
import useGameErrorStore from 'stores/game-error';
import SocketManager from 'socket/SocketManager';
import SoundManager from 'utils/SoundManager';
import useCardRankingStore from 'stores/card-ranking';
import useLeaderboardStore from 'stores/leaderboard';
import useTutorialStore from 'stores/tutorial';

import TopbarPanel from 'components/TopbarPanel/TopbarPanel';
import BingoPanel from 'components/BingoPanel/BingoPanel';
import GameInfoPanel from 'components/GameInfoPanel/GameInfoPanel';
import Chat from 'components/Chat/Chat';
import History from 'components/History/History';
import Spinner from 'components/Spinner/Spinner';
import ScoreboardModal from 'components/ScoreboardModal/ScoreboardModal';
import BingoPrizeNotification from 'components/BingoPrizeNotification/BingoPrizeNotification';
import GameErrorModal from 'components/GameErrorModal/GameErrorModal';
import ApiErrorModal from 'components/ApiErrorModal/ApiErrorModal';
import Tutorial from 'components/Tutorial/Tutorial';
import PotBoosterNotification from 'components/PotBoosterNotification/PotBoosterNotification';

// import DevMenu from 'components/DevMenu/DevMenu';

import { checkIfUserHasActiveBonus } from 'utils/customerBonusHelpers';
import useClientProgress from 'hooks/useClientProgress';
import styles from './App.module.scss';

const userSelector = (state) => [state.user, state.setUser];
const gameStateSelector = (state) => [state.gameState, state.setGameState];
const numbersDrawnSelector = (state) => [state.numbers, state.resetNumbers];
const prizeNotificationSelector = (state) => state.prizeNotification;
const potBoosterNotificationSelector = (state) => state.potBoosterNotification;
const currentGameSelector = (state) => state.currentGame;
const gameErrorSelector = (state) => state.isGameErrorVisible;
const apiErrorSelector = (state) => state.isModalVisible;
const setApiError = (state) => state.setErrorMessage;
const setPotBoosterNotificationSelector = (state) =>
  state.setPotBoosterNotification;
const resetSettingsSelector = (state) => state.resetSettings;
const resetPrizeNotificationSelector = (state) => state.resetPrizeNotification;
const resetWinnersSelector = (state) => state.resetWinners;
const scoreboardSelector = (state) => state.scoreboard;
const gameStartVoSelector = (state) => [
  state.gameStartVO,
  state.setGameStartVO,
];
const resetCardRankingSelector = (state) => state.resetCardRanking;
const resetLeaderboardSelector = (state) => state.resetLeaderboard;
const resetPrizesSelector = (state) => state.resetPrizes;
const tutorialIsActiveSelector = (state) => [
  state.tutorialIsActive,
  state.setTutorialIsActive,
];
const isChatExpandedSelector = (state) => state.setIsChatExpanded;

function App({ appData }) {
  const mountedRef = useRef(true);
  const socketManager = useRef();

  const [user, setUser] = useUserStore(userSelector, shallow);
  const [gameState, setGameState] = useGameStore(gameStateSelector, shallow);
  const [numbersDrawn, resetNumbers] = useNumbersDrawnStore(
    numbersDrawnSelector,
    shallow
  );
  const prizeNotification = useGameStore(prizeNotificationSelector);
  const potBoosterNotification = useGameStore(potBoosterNotificationSelector);
  const gameError = useGameErrorStore(gameErrorSelector);
  const apiError = useApiErrorStore(apiErrorSelector);
  const setApiErrorMessage = useApiErrorStore(setApiError);
  const setBoosterNotification = useGameStore(
    setPotBoosterNotificationSelector
  );
  const currentGame = useCurrentGameStore(currentGameSelector);
  const resetSettings = useSettingsStore(resetSettingsSelector);
  const resetPrizeNotification = useGameStore(resetPrizeNotificationSelector);
  const resetWinners = useWinnerStatsStore(resetWinnersSelector);
  const scoreboard = useWinnerStatsStore(scoreboardSelector);
  const [gameStartVO, setGameStartVO] = useGameStore(
    gameStartVoSelector,
    shallow
  );
  const resetCardRanking = useCardRankingStore(resetCardRankingSelector);
  const resetLeaderboard = useLeaderboardStore(resetLeaderboardSelector);
  const resetPrizes = useCurrentGameStore(resetPrizesSelector);
  const [tutorialIsActive, setTutorialIsActive] = useTutorialStore(
    tutorialIsActiveSelector,
    shallow
  );
  const setIsChatExpanded = useChatStore(isChatExpandedSelector);

  const hasPrizeNotification = prizeNotification !== NONE;
  const hasPotBoosterNotification = potBoosterNotification === POT_BOOSTER;
  const hasGameError = gameError;
  const isReplay = gameState === GAME_REPLAY;

  // prettier-ignore
  const [
    willBePlayingPotboosterAnim,
    setWillBePlayingPotboosterAnim,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileDevice, setMobileDevice] = useState(false);
  const [isIE, setIsIE] = useState(false);
  // const [hasOtherActiveSession, setHasOtherActiveSession] = useState(false);
  const noSleep = useRef(null);
  // const clock = useRef(null);

  const currentGameId = currentGame.bingoRoundId;
  const displayBonus = checkIfUserHasActiveBonus(user, currentGameId);
  const { getCurrentProgress } = useClientProgress();
  const currentProgress = getCurrentProgress();
  const showBonusProgress =
    (currentProgress || currentProgress === 0) &&
    currentProgress < displayBonus?.qualification?.goal;

  const numberOfTicketsCurrentGame = usePurchaseStore(
    (state) => state.numberOfTicketsCurrentGame
  );

  // Update customer bonus on game start if user has tickets
  useEffect(() => {
    const updateCustomerBonus = async () => {
      await api
        .get('/api/CustomerBonus')
        .then((res) => {
          setUser({
            customerBonus: res,
          });
        })
        .catch((err) => {
          console.log('error on getting bonus information: ', err);
        });
    };

    if (gameState === ACTIVE_GAME && numberOfTicketsCurrentGame > 0) {
      setTimeout(updateCustomerBonus, Math.random() * 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState, numberOfTicketsCurrentGame]);

  useEffect(() => {
    const browser = Bowser.parse(window.navigator.userAgent);
    if (browser.platform.type === 'mobile') {
      setMobileDevice(true);
      noSleep.current = new NoSleep();
    }
    if (browser.platform.type === 'tablet') {
      noSleep.current = new NoSleep();
    }
    if (browser.browser.name === 'Internet Explorer') {
      setIsIE(true);
    }
  }, []);

  const noSleepInit = () => {
    if (noSleep.current) {
      noSleep.current.enabled = true;
    }
    document.removeEventListener('click', noSleepInit);
  };

  useEffect(() => {
    document.addEventListener('click', noSleepInit);
    // Remove session in localStorage when browser/tab is closing
    // window.addEventListener('beforeunload', () => {
    //   localStorage.removeItem('ml-bingo-session-timestamp');
    // });

    // window.addEventListener('pagehide', () => {
    //   localStorage.removeItem('ml-bingo-session-timestamp');
    // });

    return () => {
      // localStorage.removeItem('ml-bingo-session-timestamp');
      // clearInterval(clock.current);
      document.removeEventListener('click', noSleepInit);
      // window.removeEventListener('beforeunload', () => {
      //   localStorage.removeItem('ml-bingo-session-timestamp');
      // });
      // window.removeEventListener('pagehide', () => {
      //   localStorage.removeItem('ml-bingo-session-timestamp');
      // });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPlatform = () => {
    const browser = Bowser.parse(window.navigator.userAgent);
    if (browser.platform.type === 'mobile') {
      setMobileDevice(true);
    } else {
      setMobileDevice(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', checkPlatform);
    return () => {
      window.removeEventListener('resize', checkPlatform);
    };
  }, []);

  useEffect(() => {
    if (!willBePlayingPotboosterAnim) return;
    if (currentGame.selectedPotBoosterMultiplier === null) return;
    if (currentGame.selectedPotBoosterMultiplier === undefined) return;
    if (
      (currentGame.potBoosterPauseOnlyIfBoosted &&
        currentGame.selectedPotBoosterMultiplier === 1) ||
      numbersDrawn.length > 0
    ) {
      setWillBePlayingPotboosterAnim(false);
    } else {
      setWillBePlayingPotboosterAnim(false);
      setBoosterNotification(POT_BOOSTER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willBePlayingPotboosterAnim, currentGame]);

  useEffect(() => {
    if (gameState === PURCHASE_VIEW) {
      if (noSleep.current) {
        noSleep.current.enabled = false;
      }
    }
    if (gameState === ACTIVE_GAME) {
      if (currentGame.potBoosterActive) {
        setWillBePlayingPotboosterAnim(true);
      }
      if (noSleep.current) {
        noSleep.current.enabled = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState]);

  const collectUserSettings = async () => {
    await api
      .get('/api/Bingo/clientsetting')
      .then((res) => {
        const {
          autoSort,
          autoMark,
          symbol,
          color,
          announcer,
          effects,
          music,
          userAvatar,
          muted,
        } = res;
        const settings = {
          autosort: autoSort,
          automark: autoMark,
          symbol,
          color,
          announcer,
          effects,
          music,
          userAvatar,
          muted,
        };

        resetSettings(settings);
      })
      .catch((err) => {
        if (err.statusText !== 'Not Found') {
          console.log('error on getting settings: ', err);
          setApiErrorMessage(3);
        }
      });
  };

  const collectUserBalance = async () => {
    await api
      .get('/api/CustomerTransaction/CombinedBalance')
      .then((res) => {
        setUser({
          balance: res.totalValue,
        });
      })
      .catch((err) => {
        console.log('error on getting balance: ', err);
        setApiErrorMessage(2);
      });
  };

  const collectUserData = async () => {
    await api
      .get('/api/Customer')
      .then((res) => {
        const { userId, nickname } = res;
        //  If user does not have a nickname, set error message
        if (!nickname) {
          setIsLoading(false);
          return setApiErrorMessage(12);
        }
        setUser({
          nickname,
          userId,
        });
        collectUserBalance();
        collectUserSettings();
      })
      .catch((err) => {
        console.log('error on getting user: ', err);
        setApiErrorMessage(1);
      });
  };

  useEffect(() => {
    if (!window.ResizeObserver) install();
  }, []);

  useEffect(() => {
    if (user.updateBalance) {
      collectUserBalance();
      setUser({ updateBalance: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (gameError && user.isAuthenticated) {
      collectUserBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameError]);

  useEffect(() => {
    if (gameStartVO) {
      // Play sound
      SoundManager.instance.playVO('bingostart');
      setGameStartVO(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStartVO]);

  useEffect(() => {
    if (currentGame.bingoRoundId && isLoading) {
      setIsLoading(false);

      // Check if user has seeen tutorial
      // if (!hasOtherActiveSession) {
      const hasSeenTutorial = localStorage.getItem('has-viewed-tutorial');
      if (!hasSeenTutorial && appData.tokenData) {
        localStorage.setItem('has-viewed-tutorial', JSON.stringify(Date.now()));
        setTimeout(() => {
          setTutorialIsActive(true);
        }, 1500);
      }
      // }
    }
    if (currentGame.isDrawing && gameState !== ACTIVE_GAME) {
      setGameState(ACTIVE_GAME);
    }
    if (!currentGame.isDrawing && gameState === ACTIVE_GAME) {
      resetNumbers();
      resetCardRanking();
      resetLeaderboard();
      resetPrizes();

      setGameState(PURCHASE_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame]);

  useEffect(() => {
    if (hasGameError && gameState !== PURCHASE_VIEW) {
      setGameState(PURCHASE_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGameError]);

  const updateBaseUrl = async (url) => {
    await api.setBaseURL(url);
  };

  const connect = () => {
    // Update localStorage time session
    // clock.current = setInterval(() => {
    //   localStorage.setItem(
    //     'ml-bingo-session-timestamp',
    //     JSON.stringify(Date.now())
    //   );
    // }, 1000);

    const {
      gameResponsibilityLink,
      callback_deposit,
      callback_login,
      apiURL,
      room,
      returnButtonURL,
      bingocardId,
      callback_purchase,
      callback_game_finished,
      callback_wallet,
      bingo_room_links,
      callback_jackpot,
      tracking_ga4,
      language,
    } = appData;
    if (language) {
      switchLanguage(language);
    }
    updateBaseUrl(apiURL);
    if (appData.tokenData) {
      const { access_token, refresh_token } = appData.tokenData;
      setUser({
        access_token,
        refresh_token,
        isAuthenticated: true,
        room,
        apiURL,
        callback_login,
        callback_deposit,
        gameResponsibilityLink,
        returnButtonURL,
        bingocardId,
        callback_purchase,
        callback_game_finished,
        callback_wallet,
        bingo_room_links,
        callback_jackpot,
        tracking_ga4,
      });
      collectUserData();
    } else {
      setUser({
        room,
        apiURL,
        callback_login,
        callback_deposit,
        gameResponsibilityLink,
        returnButtonURL,
        bingocardId,
        callback_purchase,
        callback_game_finished,
        callback_wallet,
        bingo_room_links,
      });
    }
  };

  // const checkForActiveSessions = () => {
  //   //  Check local storage for session
  //   const activeSession = JSON.parse(
  //     localStorage.getItem('ml-bingo-session-timestamp')
  //   );
  //   const currentTime = Date.now();
  //   //  If there is no active session or if it's older than 15 seconds you can connect
  //   if (!activeSession || activeSession + 4000 < currentTime) {
  //     setHasOtherActiveSession(false);
  //     connect();
  //   } else {
  //     setHasOtherActiveSession(true);
  //   }
  // };

  useEffect(() => {
    // checkForActiveSessions();
    connect();

    return () => {
      mountedRef.current = false;
      socketManager.current = null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user.bingocardId) {
      if (user.access_token && user.nickname) {
        if (!socketManager.current) {
          socketManager.current = SocketManager.instance;
        }
      }
      if (user.room && !user.access_token) {
        if (!socketManager.current) {
          socketManager.current = SocketManager.instance;
        }
      }
    }
    if (user.bingocardId && !user.access_token) {
      setIsLoading(false);
      setApiErrorMessage(11);
    }
    if (user.bingocardId && user.access_token && gameState !== GAME_REPLAY) {
      SoundManager.instance.muteApp(true);
      setGameState(GAME_REPLAY);
      setIsChatExpanded(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const closePrizeNotification = () => {
    resetPrizeNotification();
    resetWinners();
  };

  useEffect(() => {
    if (hasPrizeNotification || (hasPrizeNotification && scoreboard)) {
      closePrizeNotification();
    }
    if (numbersDrawn.length) {
      // Play sound
      SoundManager.instance.playVO(
        String(numbersDrawn[numbersDrawn.length - 1])
      );
      // Clean up.
      return () => {
        SoundManager.instance.stopVO(
          String(numbersDrawn[numbersDrawn.length - 1])
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numbersDrawn, scoreboard]);

  return (
    <div className={styles.wrapper} id="rsbingoclient">
      {isMobileDevice && (
        <div className={styles.doNotSupportLandscape}>
          <div>{text.t('doNotSupportLandscape')}</div>
        </div>
      )}
      {isIE && (
        <div className={styles.doNotSupportIE}>
          <div>
            <p>{text.t('doNotSupportIE')}</p>
            <button type="button" onClick={() => setIsIE(false)}>
              Ok
            </button>
          </div>
        </div>
      )}
      {/* {hasOtherActiveSession && (
        <div className={styles.doNotSupportIE}>
          <div>
            <p>{text.t('isOtherActiveSession')}</p>
            <button
              style={{ width: 'auto' }}
              type="button"
              onClick={() => checkForActiveSessions()}
            >
              {text.t('isOtherActiveSessionButton')}
            </button>
          </div>
        </div>
      )} */}
      {isLoading ? (
        <>
          <Spinner isLarge />
          {apiError && <ApiErrorModal />}
        </>
      ) : (
        <div className={styles.innerWrapper}>
          <TopbarPanel displayBonus={showBonusProgress ? displayBonus : null} />
          <BingoPanel />
          <GameInfoPanel
            displayBonus={showBonusProgress ? displayBonus : null}
          />
          <History />
          {!isReplay && <Chat />}
          {hasPrizeNotification && (
            <BingoPrizeNotification onClose={closePrizeNotification} />
          )}
          {scoreboard && currentGame && currentGame.name !== null && (
            <ScoreboardModal />
          )}
          {hasPotBoosterNotification && <PotBoosterNotification />}
          {hasGameError && <GameErrorModal />}
          {apiError && <ApiErrorModal />}
          {/* <DevMenu /> */}
          {tutorialIsActive && <Tutorial />}
        </div>
      )}
      <div id="modal-root" />
      <div id="modal-root-tutorial" />
    </div>
  );
}

App.propTypes = {
  appData: object,
};

App.defaultProps = {
  appData: {},
};

export default App;
