import React from 'react';
import { string, bool, func } from 'prop-types';
import clsx from 'clsx';

import styles from './SettingsToggleButton.module.scss';

function SettingsToggleButton({ label, isActive, onClick }) {
  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{label}</span>
      <button
        type="button"
        className={styles.button}
        onClick={() => {
          onClick();
        }}
      >
        <span
          className={clsx(styles.indicator, { [styles.isActive]: isActive })}
        />
      </button>
    </div>
  );
}

SettingsToggleButton.propTypes = {
  label: string.isRequired,
  isActive: bool,
  onClick: func.isRequired,
};

SettingsToggleButton.defaultProps = {
  isActive: false,
};

export default SettingsToggleButton;
