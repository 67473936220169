import React from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';
import { text } from 'polyglot/polyglot';

import useCurrentGameStore from 'stores/current-game';
import useUpcomingGameStore from 'stores/upcoming-game';
import usePrizeTypesStore from 'stores/prize-types';
import useGameStore, { PURCHASE_VIEW, ACTIVE_GAME } from 'stores/game';

import RocketIcon from 'assets/svg/rocket-icon.svg';

import BingoSquare from './BingoSquare/BingoSquare';

import styles from './ListOfPrizes.module.scss';

const currentGameSelector = (state) => state.currentGame;
const nextPrizeLevelSelector = (state) => state.currentGame.nextPrizeLevel;
const nextGameSelector = (state) => state.nextGame;
const bingoRoundIdSelector = (state) => state.currentGame.bingoRoundId;
const prizeTypesSelector = (state) => state.prizes;
const gameStateSelector = (state) => state.gameState;

function ListOfPrizes({
  isExpandedView,
  purchaseView,
  isVertical,
  isReplay,
  displayNextGame,
}) {
  const currentGame = useCurrentGameStore(currentGameSelector);
  const nextGame = useUpcomingGameStore(nextGameSelector);
  const activeGame = displayNextGame ? nextGame : currentGame;
  const prizes = displayNextGame ? nextGame.prizes : currentGame.prizes;
  const activePrizeLevel = useCurrentGameStore(nextPrizeLevelSelector);
  const bingoRoundId = useCurrentGameStore(bingoRoundIdSelector);
  const prizeTypes = usePrizeTypesStore(prizeTypesSelector);
  const gameState = useGameStore(gameStateSelector);

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  const activePrize =
    purchaseView || isReplay || displayNextGame ? null : activePrizeLevel;

  const isPurchase = gameState === PURCHASE_VIEW;
  const isActiveGame = gameState === ACTIVE_GAME;

  return (
    <div key={bingoRoundId} className={styles.wrapper}>
      {prizes &&
        prizes.map((item) => (
          <div
            key={`prize-${item.prizeTypeCode}-${item.aggregateAmount}`}
            className={clsx(
              styles.prizeContainer,
              `prize-${item.prizeTypeCode}-${item.aggregateAmount}`,
              {
                [styles.threeRows]: item.prizeTypeCode === '1rowverthordiag',
                [styles.fourRows]: item.prizeTypeCode === '1rowverthordiag4cor',
                [styles.dark]: activePrize && activePrize === item.orderNumber,
                [styles.isExpandedView]: isExpandedView,
                [styles.isVertical]: isVertical,
                [styles.isReplay]: isReplay,
                [styles.isActive]: activePrize === item.orderNumber,
                [styles.isPotBoosterPurchaseView]:
                  currentGame.potBoosterActive && isPurchase,
                [styles.isPotBoosterActiveGameView]:
                  currentGame.potBoosterActive &&
                  (isActiveGame || isReplay) &&
                  currentGame.selectedPotBoosterMultiplier > 1,
              }
            )}
          >
            <span className={styles.background} />
            <span
              className={clsx(styles.text, {
                [styles.short]:
                  item.prizeTypeCode.includes('1rowverthordiag') ||
                  item.prizeTypeCode === '1rowhor' ||
                  item.prizeTypeCode === '1rowvert' ||
                  item.prizeTypeCode === '1rowdiag',
              })}
            >
              <span
                className={styles.textFade}
                title={
                  prizeTypes[activeGame.gameTypeName]
                    ? prizeTypes[activeGame.gameTypeName].label
                    : item.prizeTypeName
                }
              >
                {prizeTypes[activeGame.gameTypeName]
                  ? prizeTypes[activeGame.gameTypeName].label
                  : item.prizeTypeName}
              </span>
            </span>
            {item.prizeTypeCode.includes('1rowverthordiag') ? (
              <span
                className={clsx(styles.iconList, {
                  [styles.iconListThree]:
                    item.prizeTypeCode === '1rowverthordiag',
                  [styles.iconListFour]:
                    item.prizeTypeCode === '1rowverthordiag4cor',
                })}
              >
                <BingoSquare
                  type="1rowhor"
                  isDark={
                    activePrize === item.orderNumber ||
                    (currentGame.potBoosterActive &&
                      (isActiveGame || isReplay) &&
                      currentGame.selectedPotBoosterMultiplier > 1)
                  }
                  isVertical={isVertical}
                  isGolden={
                    activePrize === item.orderNumber &&
                    currentGame.potBoosterActive &&
                    currentGame.selectedPotBoosterMultiplier > 1
                  }
                  isPotBoosterActive={
                    currentGame.potBoosterActive &&
                    currentGame.selectedPotBoosterMultiplier > 1
                  }
                />
                {!isVertical && (
                  <BingoSquare
                    type="1rowvert"
                    isDark={
                      activePrize === item.orderNumber ||
                      (currentGame.potBoosterActive &&
                        (isActiveGame || isReplay) &&
                        currentGame.selectedPotBoosterMultiplier > 1)
                    }
                    isGolden={
                      activePrize === item.orderNumber &&
                      currentGame.potBoosterActive &&
                      currentGame.selectedPotBoosterMultiplier > 1
                    }
                    isPotBoosterActive={
                      currentGame.potBoosterActive &&
                      currentGame.selectedPotBoosterMultiplier > 1
                    }
                  />
                )}
                {!isVertical && (
                  <BingoSquare
                    type="1rowdiag"
                    isDark={
                      activePrize === item.orderNumber ||
                      (currentGame.potBoosterActive &&
                        (isActiveGame || isReplay) &&
                        currentGame.selectedPotBoosterMultiplier > 1)
                    }
                    isGolden={
                      activePrize === item.orderNumber &&
                      currentGame.potBoosterActive &&
                      currentGame.selectedPotBoosterMultiplier > 1
                    }
                    isPotBoosterActive={
                      currentGame.potBoosterActive &&
                      currentGame.selectedPotBoosterMultiplier > 1
                    }
                  />
                )}
                {!isVertical &&
                  item.prizeTypeCode === '1rowverthordiag4cor' && (
                    <BingoSquare
                      type="4cor"
                      isDark={
                        activePrize === item.orderNumber ||
                        (currentGame.potBoosterActive &&
                          (isActiveGame || isReplay) &&
                          currentGame.selectedPotBoosterMultiplier > 1)
                      }
                      isGolden={
                        activePrize === item.orderNumber &&
                        currentGame.potBoosterActive &&
                        currentGame.selectedPotBoosterMultiplier > 1
                      }
                      isPotBoosterActive={
                        currentGame.potBoosterActive &&
                        currentGame.selectedPotBoosterMultiplier > 1
                      }
                    />
                  )}
              </span>
            ) : (
              <span className={styles.icon}>
                <BingoSquare
                  type={
                    prizeTypes[activeGame.gameTypeName]
                      ? prizeTypes[activeGame.gameTypeName].pattern
                      : item.prizeTypeCode
                  }
                  isDark={
                    activePrize === item.orderNumber ||
                    (currentGame.potBoosterActive &&
                      (isActiveGame || isReplay) &&
                      currentGame.selectedPotBoosterMultiplier > 1)
                  }
                  isVertical={isVertical}
                  isGolden={
                    activePrize === item.orderNumber &&
                    currentGame.potBoosterActive &&
                    currentGame.selectedPotBoosterMultiplier > 1
                  }
                  isPotBoosterActive={
                    currentGame.potBoosterActive &&
                    currentGame.selectedPotBoosterMultiplier > 1
                  }
                />
              </span>
            )}
            <span className={styles.prize}>
              {new Intl.NumberFormat(text.t('currencyLocale'), settings).format(
                item.aggregateAmount
              )}
            </span>
            {currentGame.potBoosterActive && isPurchase && (
              <RocketIcon className={styles.rocketIcon} />
            )}
          </div>
        ))}
    </div>
  );
}

ListOfPrizes.propTypes = {
  isExpandedView: bool,
  purchaseView: bool,
  isVertical: bool,
  isReplay: bool,
  displayNextGame: bool,
};

ListOfPrizes.defaultProps = {
  isExpandedView: false,
  purchaseView: false,
  isVertical: false,
  isReplay: false,
  displayNextGame: false,
};
export default ListOfPrizes;
