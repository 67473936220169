import React from 'react';
import clsx from 'clsx';
import { string, number as numberPropType, bool } from 'prop-types';
import { useTransition, animated } from 'react-spring';
import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';

import getBingoBallColor from 'utils/getBingoBallColor';

import darkBlueBall from 'assets/theme/images/bingoBalls/bingoball-blue-600x600.png';
import greenBall from 'assets/theme/images/bingoBalls/bingoball-green-600x600.png';
import lightBlueBall from 'assets/theme/images/bingoBalls/bingoball-lightblue-600x600.png';
import orangeBall from 'assets/theme/images/bingoBalls/bingoball-orange-600x600.png';
import pinkBall from 'assets/theme/images/bingoBalls/bingoball-pink-600x600.png';

import styles from './BingoBall.module.scss';

const bingoballSrc = [
  pinkBall,
  lightBlueBall,
  darkBlueBall,
  orangeBall,
  greenBall,
];

function BingoBall({
  className,
  classNameNumber,
  number,
  isLarge,
  willAnimate,
  isVertical,
  currentIndex,
  isLastBall,
}) {
  const breakpoint = useBreakpoint();
  let fromTransform;
  let enterTransform;
  let updateTransform;
  const spaceBetween = breakpoint === MOBILE ? 58 : 50;
  const distance = breakpoint === MOBILE ? 25 : 30;
  const top = breakpoint === MOBILE ? 19 : 23;

  if (isVertical) {
    fromTransform = 'translate(-100%, 0%) scale(1)';
    enterTransform = 'translate(0%, 0%) scale(1)';
    updateTransform = isLastBall
      ? 'translate(0%, 0%) scale(1)'
      : `translate(-25%, ${30 + currentIndex * 58}%) scale(0.5)`;
  } else if (willAnimate) {
    fromTransform = `translate(-100%, 0px) scale(1)`;
    enterTransform = `translate(0%, 0px) scale(1)`;
    updateTransform = isLastBall
      ? `translate(0%, 0%) scale(1)`
      : `translate(${
          distance + currentIndex * spaceBetween
        }%, -${top}px) scale(0.45)`;
  } else {
    fromTransform = '';
    enterTransform = '';
    updateTransform = '';
  }

  const ballTransitions = useTransition(currentIndex, null, {
    from: {
      transform: fromTransform,
      opacity: 0,
    },
    enter: {
      transform: enterTransform,
      opacity: 1,
    },
    update: {
      transform: updateTransform,
      opacity: currentIndex === 4 ? 0 : 1,
    },
    leave: { opacity: 0 },
    keys: (item, index) => index,
  });

  return (
    <>
      {ballTransitions.map(({ props }) => (
        <animated.div
          key={`ball-number-${number}`}
          style={{
            backgroundImage: `url(${
              bingoballSrc[getBingoBallColor(number, true) - 1]
            })`,
            ...props,
          }}
          className={clsx(className, styles.wrapper, {
            [styles.isLarge]: isLarge,
            [styles.isVertical]: isVertical,
            [styles.isAbsolute]: currentIndex,
          })}
        >
          <span className={clsx(classNameNumber, styles.number)}>{number}</span>
        </animated.div>
      ))}
    </>
  );
}
BingoBall.propTypes = {
  number: numberPropType.isRequired,
  className: string,
  classNameNumber: string,
  isLarge: bool,
  willAnimate: bool,
  isVertical: bool,
  currentIndex: numberPropType,
  isLastBall: bool,
};

BingoBall.defaultProps = {
  className: null,
  classNameNumber: null,
  isLarge: false,
  willAnimate: false,
  isVertical: false,
  currentIndex: null,
  isLastBall: false,
};

export default BingoBall;
