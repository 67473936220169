import React from 'react';
import { bool, func } from 'prop-types';

import { text } from 'polyglot/polyglot';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

import styles from './IncuffientFunds.module.scss';

function IncuffientFunds({ isLoading, onClickLoadMoney, onClose }) {
  return (
    <Modal
      isLoading={isLoading}
      onClickOutside={onClose}
      title={text.t('purchaseModal.incuffientFunds.title')}
      content={
        <div className={styles.text}>
          <p>{text.t('purchaseModal.incuffientFunds.firstText')}</p>
          <p>{text.t('purchaseModal.incuffientFunds.secondText')}</p>
        </div>
      }
      buttons={[
        <Button
          key="confirm-button"
          className={styles.button}
          onClick={onClickLoadMoney}
          isTertiary
        >
          {text.t('purchaseModal.incuffientFunds.button')}
        </Button>,
      ]}
    />
  );
}

IncuffientFunds.propTypes = {
  isLoading: bool,
  onClickLoadMoney: func,
  onClose: func,
};

IncuffientFunds.defaultProps = {
  isLoading: false,
  onClickLoadMoney: () => {},
  onClose: () => {},
};

export default IncuffientFunds;
