import React from 'react';
import { oneOf, func } from 'prop-types';
import clsx from 'clsx';

import {
  MARKING_SYMBOL_OVAL,
  MARKING_SYMBOL_POLYGON,
  MARKING_SYMBOL_RECTANGLE,
  MARKING_SYMBOL_TRIANGLE,
  MARKING_SYMBOL_STAR,
} from 'stores/settings';

import Oval from 'assets/svg/oval-shape.svg';
import Polygon from 'assets/svg/polygon-shape.svg';
import Rectangle from 'assets/svg/rectangle-shape.svg';
import Star from 'assets/svg/star-shape.svg';
import Triangle from 'assets/svg/triangle-shape.svg';

import styles from './MarkingSymbols.module.scss';

function MarkingSymbols({ currentSymbol, onClick }) {
  return (
    <div className={styles.wrapper}>
      <Oval
        className={clsx(styles.shapes, {
          [styles.active]: currentSymbol === MARKING_SYMBOL_OVAL,
        })}
        onClick={() => onClick('symbol', MARKING_SYMBOL_OVAL)}
      />
      <Polygon
        className={clsx(styles.shapes, {
          [styles.active]: currentSymbol === MARKING_SYMBOL_POLYGON,
        })}
        onClick={() => onClick('symbol', MARKING_SYMBOL_POLYGON)}
      />
      <Rectangle
        className={clsx(styles.shapes, {
          [styles.active]: currentSymbol === MARKING_SYMBOL_RECTANGLE,
        })}
        onClick={() => onClick('symbol', MARKING_SYMBOL_RECTANGLE)}
      />
      <Star
        className={clsx(styles.shapes, {
          [styles.active]: currentSymbol === MARKING_SYMBOL_STAR,
        })}
        onClick={() => onClick('symbol', MARKING_SYMBOL_STAR)}
      />
      <Triangle
        className={clsx(styles.shapes, {
          [styles.active]: currentSymbol === MARKING_SYMBOL_TRIANGLE,
        })}
        onClick={() => onClick('symbol', MARKING_SYMBOL_TRIANGLE)}
      />
    </div>
  );
}

MarkingSymbols.propTypes = {
  currentSymbol: oneOf([
    MARKING_SYMBOL_OVAL,
    MARKING_SYMBOL_POLYGON,
    MARKING_SYMBOL_RECTANGLE,
    MARKING_SYMBOL_TRIANGLE,
    MARKING_SYMBOL_STAR,
  ]).isRequired,
  onClick: func.isRequired,
};

export default MarkingSymbols;
