/* eslint-disable import/prefer-default-export */
import { text } from 'polyglot/polyglot';

// Getting a random integer between two values
export const formatCurrency = (value) =>
  Number(value).toLocaleString(text.t('currencyLocale'), {
    style: 'currency',
    currency: text.t('currencyFormat'),
    minimumFractionDigits: 0,
  });
