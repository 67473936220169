import React, { useEffect, useState } from 'react';
import { shape, string, number, bool, object, func } from 'prop-types';
import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';
import { shallow } from 'zustand/shallow';
import bingoAssetsStore from 'stores/bingoAssetsStore';
import useCountdownStore from 'stores/countdown-state';
import useUserStore from 'stores/user';
import useGameStatsStore from 'stores/current-game';
import useUpcomingGameStore from 'stores/upcoming-game';
import useGameStore, { PURCHASE_VIEW, ACTIVE_GAME } from 'stores/game';
import axios from 'axios';
import { text } from 'polyglot/polyglot';
import clsx from 'clsx';

import Button from 'components/Button/Button';
import Users from 'assets/svg/user.svg';
import Star from 'assets/svg/star.svg';
import VariantIcon from 'assets/svg/variant-icon.svg';
import RocketIcon from 'assets/svg/rocket-icon.svg';

import styles from './BingoRoom.module.scss';

const userBaseUrlSelector = (state) => state.user.apiURL;
const assetsSelector = (state) => [state.assets, state.updateAssets];
const activeCountdownGameSelector = (state) => state.activeCountdownGame;
const hoursSelector = (state) => state.hours;
const minutesSelector = (state) => state.minutes;
const secondsSelector = (state) => state.seconds;
const currentGameSelector = (state) => state.currentGame;
const nextGameSelector = (state) => state.nextGame;
const gameStateSelector = (state) => state.gameState;

function BingoRoom({
  room,
  callbackUpdateRooms,
  isOnlyOneRoom,
  isCurrentRoom,
}) {
  const baseURL = useUserStore(userBaseUrlSelector);
  const [assets, updateAssets] = bingoAssetsStore(assetsSelector, shallow);
  const [background, setBackground] = useState('');
  const [logo, setLogo] = useState('');
  const [chathostImg, setChathostImg] = useState('');
  const [startOfGame, setStartOfGame] = useState(null);
  const [interval, updateInterval] = useState(null);
  const [clockIsStopped, stopCountdown] = useState(false);
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const activeCountdownGame = useCountdownStore(activeCountdownGameSelector);
  const [isCurrentCountdown, setIsCurrentCountdown] = useState(false);
  const countDownHours = useCountdownStore(hoursSelector);
  const countDownMinutes = useCountdownStore(minutesSelector);
  const countDownSeconds = useCountdownStore(secondsSelector);
  const currentGame = useGameStatsStore(currentGameSelector);
  const nextGame = useUpcomingGameStore(nextGameSelector);
  const breakpoint = useBreakpoint();
  const gameState = useGameStore(gameStateSelector);

  const isPurchase = gameState === PURCHASE_VIEW;
  const isActiveGame = gameState === ACTIVE_GAME;

  let isCurrentGame = false;
  let isNextGame = false;

  if (room.nextRound) {
    isCurrentGame =
      currentGame.bingoRoundId === room.nextRound.bingoGame.bingoRoundId;
    isNextGame =
      nextGame.bingoRoundId === room.nextRound.bingoGame.bingoRoundId;
  }

  const retrieveBG = async () => {
    if (!assets[room.nextRound.bingoGame.backgroundSmall]) {
      axios
        .get(`${baseURL}/${room.nextRound.bingoGame.backgroundSmall}`)
        .then((res) => {
          setBackground(res.data);
          updateAssets({
            [room.nextRound.bingoGame.backgroundSmall]: res.data,
          });
        })
        .catch((err) => {
          console.log('error on getting small background: ', err);
        });
    } else {
      setBackground(assets[room.nextRound.bingoGame.backgroundSmall]);
    }
  };

  const retrieveLogo = async () => {
    if (!assets[room.nextRound.bingoGame.logoSmall]) {
      axios
        .get(`${baseURL}/${room.nextRound.bingoGame.logoSmall}`)
        .then((res) => {
          setLogo(res.data);
          updateAssets({
            [room.nextRound.bingoGame.logoSmall]: res.data,
          });
        })
        .catch((err) => {
          console.log('error on getting small logo: ', err);
        });
    } else {
      setLogo(assets[room.nextRound.bingoGame.logoSmall]);
    }
  };

  const retrieveChathostImg = async () => {
    if (!assets[room.chatHost.name]) {
      axios
        .get(`${baseURL}/${room.chatHost.image}`)
        .then((res) => {
          setChathostImg(res.data.imageData);
          updateAssets({
            [room.chatHost.name]: res.data.imageData,
          });
        })
        .catch((err) => {
          console.log('error on getting chathost image: ', err);
        });
    } else {
      setChathostImg(assets[room.chatHost.name]);
    }
  };

  const countdown = () => {
    const now = new Date().getTime();
    const timeleft = startOfGame - now;
    if (startOfGame) {
      let remainingHours = String(
        Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      if (remainingHours.length === 1) {
        remainingHours = `0${remainingHours}`;
      }
      let remainingMinutes = String(
        Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
      );
      if (remainingMinutes.length === 1) {
        remainingMinutes = `0${remainingMinutes}`;
      }
      let remainingSeconds = String(
        Math.floor((timeleft % (1000 * 60)) / 1000)
      );
      if (remainingSeconds.length === 1) {
        remainingSeconds = `0${remainingSeconds}`;
      }
      if (now > startOfGame) {
        stopCountdown(true);
      } else {
        setHours(remainingHours);
        setMinutes(remainingMinutes);
        setSeconds(remainingSeconds);
      }
    }
  };

  const sendDataLayer = () => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'ga4_event',
      ga4_data: {
        event_name: 'bingo_change_room',
        section: 'Bingo client',
        bingo_name: room.name,
        allow_auto_collect: true,
      },
    });
  };

  useEffect(() => {
    if (startOfGame) {
      updateInterval(setInterval(countdown, 1000));
      return () => updateInterval(clearInterval(interval));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOfGame]);

  const switchGames = () => {
    setTimeout(() => {
      callbackUpdateRooms();
    }, Math.random() * 3000 + 5000);
  };

  useEffect(() => {
    if (clockIsStopped) {
      updateInterval(clearInterval(interval));
      switchGames();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockIsStopped]);

  useEffect(() => {
    if (isCurrentCountdown) {
      switchGames();
      setIsCurrentCountdown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCountdownGame]);

  useEffect(() => {
    if (room && room.nextRound) {
      retrieveBG();
      if (
        room.nextRound.bingoGame.bingoRoundId ===
        activeCountdownGame.bingoRoundId
      ) {
        setIsCurrentCountdown(true);
      } else {
        setStartOfGame(new Date(room.nextRound.drawStartTime).getTime());
      }

      if (breakpoint === DESKTOP) {
        retrieveLogo();
      }

      if (room.chatHost) {
        retrieveChathostImg();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
    minimumFractionDigits: 0,
  };

  const isPotBoosterActiveGameView =
    currentGame.potBoosterActive &&
    isActiveGame &&
    currentGame.selectedPotBoosterMultiplier > 1;
  const isPotBoosterPurchaseView = currentGame.potBoosterActive && isPurchase;

  if (!room || !room.nextRound) {
    return null;
  }

  if (breakpoint !== DESKTOP) {
    return (
      <a
        href={room.url}
        className={clsx(styles.wrapper, {
          [styles.isOnlyOneRoom]: isOnlyOneRoom,
          [styles.isCurrentRoom]: isCurrentRoom,
        })}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className={clsx({ [styles.justifyEnd]: isOnlyOneRoom })}>
          {!isOnlyOneRoom && (
            <span className={styles.roomName}>{room.name}</span>
          )}
          <span className={styles.time}>
            <Users className={styles.icon} />
            {isCurrentGame && currentGame.numberOfPlayers}
            {isNextGame && nextGame.numberOfPlayers}
            {!isCurrentGame && !isNextGame && room.numberOfPlayers}
          </span>
        </div>
        <div className={clsx({ [styles.isOnlyOneRoom]: isOnlyOneRoom })}>
          <span className={styles.bingoName}>
            {isCurrentGame &&
              `${currentGame.name} ${new Intl.NumberFormat(
                text.t('currencyLocale'),
                settings
              ).format(currentGame.cardPrice)}`}
            {isNextGame &&
              `${nextGame.name} ${new Intl.NumberFormat(
                text.t('currencyLocale'),
                settings
              ).format(nextGame.cardPrice)}`}
            {!isCurrentGame &&
              !isNextGame &&
              `${room.nextRound.bingoGame.name} ${new Intl.NumberFormat(
                text.t('currencyLocale'),
                settings
              ).format(room.nextRound.bingoGame.cardPrice)}`}
          </span>
        </div>
        <div className={clsx({ [styles.lastOrder]: isOnlyOneRoom })}>
          <span className={styles.time}>
            {isCurrentCountdown && countDownMinutes && countDownSeconds
              ? `${
                  countDownHours !== '00' ? `${countDownHours}:` : ''
                }${countDownMinutes}:${countDownSeconds}`
              : ''}
            {!isCurrentCountdown && minutes && seconds
              ? `${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`
              : ''}
            {(isPotBoosterActiveGameView || isPotBoosterPurchaseView) && (
              <RocketIcon className={styles.rocketIcon} />
            )}
          </span>
          <span className={styles.jackpot}>
            {isCurrentGame &&
              currentGame.jackpots.length > 0 &&
              text.t('bingoRooms.jackpotWithSum', {
                value: currentGame.jackpots[0]?.jackpotValueDisplayName
                  ? currentGame.jackpots[0].jackpotValueDisplayName
                  : new Intl.NumberFormat(
                      text.t('currencyLocale'),
                      settings
                    ).format(Math.floor(currentGame.jackpots[0].amount)),
              })}

            {isNextGame &&
              nextGame.jackpots.length > 0 &&
              text.t('bingoRooms.jackpotWithSum', {
                value: nextGame.jackpots[0]?.jackpotValueDisplayName
                  ? nextGame.jackpots[0].jackpotValueDisplayName
                  : new Intl.NumberFormat(
                      text.t('currencyLocale'),
                      settings
                    ).format(Math.floor(nextGame.jackpots[0].amount)),
              })}

            {!isCurrentGame &&
              !isNextGame &&
              (room.nextRound.bingoGame?.jackpotAmount > 0 ||
                room.nextRound.bingoGame?.jackpotValueDisplayName) &&
              text.t('bingoRooms.jackpotWithSum', {
                value: room.nextRound.bingoGame?.jackpotValueDisplayName
                  ? room.nextRound.bingoGame.jackpotValueDisplayName
                  : new Intl.NumberFormat(
                      text.t('currencyLocale'),
                      settings
                    ).format(
                      Math.floor(room.nextRound.bingoGame.jackpotAmount)
                    ),
              })}
          </span>
        </div>
        {!isCurrentRoom && (
          <Button
            className={styles.mobileButton}
            onClick={() => {
              sendDataLayer();
              // localStorage.removeItem('ml-bingo-session-timestamp');
            }}
          >
            {text.t('bingoRooms.buttonText')}
          </Button>
        )}
      </a>
    );
  }

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isCurrentRoom]: isCurrentRoom,
      })}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className={room.chatHost ? styles.minHeight : null}>
        <img
          src={logo}
          className={clsx(styles.logo, { [styles.isChatHost]: room.chatHost })}
          alt=""
        />
        <div className={styles.nameAndChatHost}>
          <span className={styles.roomName}>{room.name}</span>
          {room.chatHost && (
            <div className={styles.chatHostWrapper}>
              <span className={styles.chatHost}>
                {text.t('chat.bingoRoomsChathostOnlineText', {
                  name: room?.chatHost?.name,
                })}
              </span>
              <div className={styles.imgWrapper}>
                <img src={chathostImg} alt="" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.whiteBG}>
        <div>
          <span className={styles.nextGame}>
            {text.t('bingoRooms.nextGameText')}
          </span>
          <span className={styles.bingoName}>
            {isCurrentGame &&
              `${currentGame.name} ${new Intl.NumberFormat(
                text.t('currencyLocale'),
                settings
              ).format(currentGame.cardPrice)}`}
            {isNextGame &&
              `${nextGame.name} ${new Intl.NumberFormat(
                text.t('currencyLocale'),
                settings
              ).format(nextGame.cardPrice)}`}
            {!isCurrentGame &&
              !isNextGame &&
              `${room.nextRound.bingoGame.name} ${new Intl.NumberFormat(
                text.t('currencyLocale'),
                settings
              ).format(room.nextRound.bingoGame.cardPrice)}`}
          </span>
        </div>
        <div className={styles.alignCenter}>
          <VariantIcon className={styles.variantIcon} />
          <span className={styles.variantName}>
            {room.nextRound.bingoGame.bingoLayoutName}
          </span>
          <span className={styles.players}>
            <Users className={styles.icon} />
            {isCurrentGame && `${currentGame.numberOfPlayers} st`}
            {isNextGame && `${nextGame.numberOfPlayers} st`}
            {!isCurrentGame && !isNextGame && `${room.numberOfPlayers} st`}
          </span>
        </div>

        <div>
          <Star className={styles.starIcon} />
          <span className={styles.jackpotText}>
            {`${text.t('bingoRooms.jackpot')}`}
          </span>
          <span className={styles.jackpotSum}>
            {isCurrentGame && currentGame.jackpots.length > 0 && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {currentGame.jackpots[0]?.jackpotValueDisplayName
                  ? currentGame.jackpots[0].jackpotValueDisplayName
                  : new Intl.NumberFormat(
                      text.t('currencyLocale'),
                      settings
                    ).format(Math.floor(currentGame.jackpots[0]?.amount))}
              </>
            )}
            {isNextGame && nextGame.jackpots.length > 0 && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {nextGame.jackpots[0]?.jackpotValueDisplayName
                  ? nextGame.jackpots[0].jackpotValueDisplayName
                  : new Intl.NumberFormat(
                      text.t('currencyLocale'),
                      settings
                    ).format(Math.floor(nextGame.jackpots[0]?.amount))}
              </>
            )}

            {!isCurrentGame &&
              !isNextGame &&
              (room.nextRound.bingoGame?.jackpotAmount > 0 ||
                room.nextRound.bingoGame?.jackpotValueDisplayName) && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {room.nextRound.bingoGame?.jackpotValueDisplayName
                    ? room.nextRound.bingoGame.jackpotValueDisplayName
                    : new Intl.NumberFormat(
                        text.t('currencyLocale'),
                        settings
                      ).format(
                        Math.floor(room.nextRound.bingoGame.jackpotAmount)
                      )}
                </>
              )}
          </span>
        </div>
        {(isPotBoosterActiveGameView || isPotBoosterPurchaseView) && (
          <div className={styles.rocketWrapper}>
            <RocketIcon className={styles.rocketIcon} />
          </div>
        )}
        <div id={styles.paddingBottom}>
          <span className={styles.time}>
            {isCurrentCountdown && countDownMinutes && countDownSeconds
              ? `${
                  countDownHours !== '00' ? `${countDownHours}:` : ''
                }${countDownMinutes}:${countDownSeconds}`
              : ''}
            {!isCurrentCountdown && minutes && seconds
              ? `${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`
              : ''}
          </span>
          {!isOnlyOneRoom && !isCurrentRoom && (
            <a href={room.url}>
              <Button
                onClick={() => {
                  sendDataLayer();
                  // localStorage.removeItem('ml-bingo-session-timestamp');
                }}
              >
                {text.t('bingoRooms.buttonText')}
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

BingoRoom.propTypes = {
  room: shape({
    // eslint-disable-next-line react/forbid-prop-types
    nextRound: object,
    // eslint-disable-next-line react/forbid-prop-types
    currentRound: object,
    name: string,
    id: number,
    allowPrivateChat: bool,
    chatActive: bool,
    chatId: string,
  }).isRequired,
  callbackUpdateRooms: func.isRequired,
  isOnlyOneRoom: bool.isRequired,
  isCurrentRoom: bool.isRequired,
};

export default BingoRoom;
