const en = {
  topPanel: {
    listItem1: 'Game rules & prize plan',
    listItem2: 'Play responsibly',
    listItem3: 'Chat',
    listItem4: 'Settings',
    listItem5: 'See guide',
    listItem6: 'Bingo Lobby',
    soundOffText: 'SOUND OFF',
    soundOnText: 'SOUND ON',
    guideButton: 'See guide',
    responsibilitiesButton: 'Gaming responsibility',
    loginButton: 'Log in to play',
  },
  gameWallet: {
    walletText: 'Wallet',
    buttonText: 'Deposit',
  },
  gameBanner: {
    largeBannerTitlePrebuy: 'Time until next game',
    largeBannerTitleBuy: 'Time until start',
    smallBannerTitle: 'Next bingo game',
  },
  gameInfo: {
    price: 'Price',
    numberOfPlayersText: 'No of players',
    numberOfPlayers: '%{amount}',
    totalPott: 'Total pot',
    jackpott: 'JACKPOT',
    withinText: 'within',
    withinBalls: '%{amount} balls',
  },
  settings: {
    heading: 'Settings',
    autosort: 'Auto sorting',
    autosortExplanation:
      'Auto sorting ensures that the best bingo card is displayed first.',
    automark: 'Auto daubing',
    automarkExplanation:
      "Use auto daubing if you do not want to manually mark the bingo cards. (Don't worry, even when you mark yourself, we correct the card).",
    symbols: 'Daub symbols',
    colors: 'Colors',
    sound: 'Sound',
    announcer: 'Announcer',
    effects: 'Effects',
    music: 'Music',
    profileimage: 'Profile picture',
  },
  buyLotteryTicketButtons: {
    heading: 'Buy cards',
    minusButton: '-',
    plusButton: '+',
    predefinedButtons: '%{amount} pcs',
    maxButton: 'Max',
    buyButton: 'Buy for next ',
    payButton: 'Pay',
    amount: '%{amount}',
    totalAmount: 'Total %{amount}',
  },
  prepurchasedTickets: {
    oneTicket: '%{amount} purchased card for next game',
    moreTickets: '%{amount} purchased cards for next game',
  },
  leaderboard: {
    heading: 'Leaderboard',
  },
  chat: {
    bingoRoomsChathostOnlineText: 'Chat host online:\n %{name}',
    bingoRoomsButtonOpen: 'Show all rooms',
    bingoRoomsButtonClose: 'Show chat',
    isClosedMessage: 'The chat is closed',
    loginMessage: 'Log in to chat',
    toggleButton: 'Open / close chat',
    closeButton: 'Close chat',
    admin: 'Chat host:',
    private: 'Private chat:',
    modal: {
      startPrivateChat: {
        title: 'Start private chat',
        text: 'Do you want to start a private chat with',
        secondaryText:
          'You cannot have more tabs open. Close a chat to be able to start a new one.',
        cancelButton: 'Cancel',
        confirmButton: 'Confirm',
        okButton: 'Ok',
      },
      privateChatRequest: {
        title: 'Private chat invitation',
        subTitle: 'Wants to start a private chat with you',
        secondarySubtitle:
          'You cannot have more tabs open. Close a chat to be able to open a new one.',
        cancelButton: 'Decline',
        confirmButton: 'Accept',
        okButton: 'Ok',
      },
    },
  },
  bingoCard: {
    demo: 'Demo',
    purchase: {
      selectButton: 'Select card',
      isSelected: 'Selected card',
      isPurchased: 'Payed card',
    },
    numbersLeft: '%{numbers} left until bingo!',
    bingo: 'Bingo!',
  },
  prizeplan: {
    title: '%{name} prize plan',
    priceText: 'Price',
    numberOfPlayersText: 'No of players',
    totalPottText: 'Total pot',
    withinBallsText: 'No of balls',
    header1: 'Prize level',
    header2: 'Pattern',
    header3: 'Prize share',
    header4: 'Prize',
    jackpotFullText: 'JACKPOT within %{number} balls',
    jackpotText1: 'JACKPOT',
    jackpotText2: 'within %{number} balls',
    subtitle: 'Game rules',
  },
  gamingResponsibility: {
    heading1: 'Our work with gaming responsibility',
    preamble:
      'Too much fun is never good – and this is not least true of games. The dream of the big win that will change our lives exists with the vast majority of us and is an important driving force in gambling.',
    content1:
      'Our goal at Random State is to be at the forefront when it comes to gaming responsibility. Gaming responsibility work reduces the risk of players developing gambling problems so that gambling can continue to be fun.',
    heading2: 'Age limit',
    content2:
      'You must be 18 years old to be able to play our games. Research shows that the earlier you start gambling, the greater the risk of ending up in unhealthy gambling.',
  },
  winnerListModal: {
    title: 'Game over',
    table: {
      header1: 'Prize level',
      header2: 'Within',
      header3: 'Prize',
      header4: 'Winner',
    },
    closeButton: 'Close',
  },
  prizeNotification: {
    bingo: {
      title: 'Congratulations on winning!',
    },
    jackpot: {
      title: 'Congratulations on the jackpot!',
    },
  },
  purchaseModal: {
    purchase: {
      title: 'Purchase confirmation',
      bingoCardPrice: 'Card price',
      bingoCardsAmount: 'No of cards',
      total: 'Total',
      countAppendix: '%{total} pcs',
      currencyAppendix: '%{amount}',
      vouchersPayForAllTickets:
        'The purchase is free of charge as it refers to games that you have vouchers for.',
      vouchersPayForSomeTickets:
        '%{numberOfVouchers} of the cards are paid for by vouchers, the rest is deducted from your gaming wallet.',
      confirmButton: 'Confirm',
      cancelButton: 'Cancel',
    },
    receipt: {
      title: 'Receipt',
      goodLuck: 'Good luck %{name}!',
      returnButton: 'Go to my cards',
    },
    incuffientFunds: {
      title: 'Out of funds',
      firstText:
        'Unfortunately, you do not have enough money in your gaming wallet.',
      secondText: 'Top up so you can continue',
      button: 'Deposit',
    },
    noGameLimitsSet: {
      title: 'Purchase cannot be completed',
      text: 'You must set a deposit limit before you can play.',
      closeModalButtonText: 'OK',
    },
  },
  gameErrorModal: {
    tooFewPlayers: {
      title: 'Not enough players',
      content1:
        'This bingo game have too few players to start. If you have purchased cards, your money will be refunded.',
      content2: 'The next bingo round is now open for purchase.',
      nextButton: 'Go to next bingo',
      backButton: 'Back',
    },
  },
  apiErrorModal: {
    title: 'Something went wrong!',
    titleWhenBuying: 'Unable to purchase cards!',
    content0: '',
    content1:
      'A problem occurred when fetching user data, try logging out and logging in again to try again.',
    content2:
      'We failed to update your balance, reload the page for the correct value.',
    content3: 'We failed to fetch your settings.',
    content4: 'We failed to save your settings.',
    content5: 'No cards could be fetched, try again.',
    content6:
      'A problem occurred when fetching cards, reload the page to try again.',
    content7:
      'A problem occurred with the connection, we are trying to reconnect.',
    content8: 'Reconnection failed. Reload the page to connect again.',
    content9: 'Reconnection successful!',
    content10: 'A problem occurred during purchase, try again.',
    content11: 'You must log in to view the replay of the card.',
    content12: 'You must set a chat name to be able to play.',
    content13: 'You have exceeded your loss limit.',
    content14: 'You have exceeded your time limit.',
    content15: 'You have activated a gambling break.',
    content16: 'You have been blocked from bingo games.',
    content17: 'The bingo round has been closed for sales.',
    closeButtonText: 'OK',
  },
  bingoRooms: {
    header: 'Next game in our bingo rooms:',
    jackpotWithSum: 'Jackpot: %{value}',
    jackpot: 'Jackpot:',
    nextGameText: 'Next game:',
    buttonText: 'To the game',
  },
  tutorial: {
    greetingHeading: 'Hi, nice to see you here!',
    greetingParagraph:
      'Would you like a tour of our new bingo? You can always choose to see it later.',
    yesButton: 'Yes',
    noButton: 'No',
    nextStepButton: 'Next step',
    lastStepButton: 'Close guide',
    step1Text: 'Here you deposit money to start playing',
    step2Text:
      'How do you want your bingo? Here you choose if you want to daub yourself, which daub symbol you want, color and profile picture.',
    step3Text: 'Here you can turn off and turn on the sound',
    step4Text: 'Here you can find information about ongoing games',
    step5ViewText: 'Here you can change the view on the bingo cards',
    step5ChatText: 'Here you can expand the chat',
  },
  bonusModal: {
    purchaseConfirmation: {
      firstParagraph1: 'You will have',
      firstParagraph2: 'achieved',
      firstParagraph3: 'your bonus!',
      firstParagraph4: 'left to bonus!',
      bonusPayoutText: 'Bonus!',
      voucherText: '%{amount} free games!',
    },
    receipt: {
      firstParagraph1: 'You have',
      firstParagraph2: 'achieved',
      firstParagraph3: 'your bonus!',
      firstParagraph4: 'left to bonus!',
      moneyPayout: '%{amount} is deposited in your gaming wallet.',
      voucherPayout: 'Your free games have been paid out.',
      percentPayout: 'Your bonus has been paid out!',
    },
    purchaseView: {
      purchaseView: 'left to bonus!',
      bonusPayoutText: 'Bonus!',
      voucherText: '%{amount} free games!',
    },
  },
  potbooster: {
    header: 'POT BOOSTER',
    totalPot: 'Total pot:',
  },
  currencyFormat: 'EUR',
  currencyLocale: 'en-MT',
  doNotSupportLandscape: 'Rotate your device to play bingo',
  doNotSupportIE: 'The client does not support this web browser.',
  isOtherActiveSession:
    'You can only be connected to one bingo room at a time. Close down your other session and try again',
  isOtherActiveSessionButton: 'Try again',
  blacklistedWordMessage: 'The message was not sent due to blocked words',
};

export default en;
