/* eslint-disable no-param-reassign */
import { create } from 'zustand';
// eslint-disable-next-line import/no-unresolved
import produce from 'immer';

const useNumbersMarkedStore = create((set) => ({
  markedNumbers: {},
  resetMarkedNumbers: () => set({ markedNumbers: {} }),
  addNewMarkedNumberItem: (newId) =>
    set(
      produce((draft) => {
        draft.markedNumbers[newId] = [];
      })
    ),
  addMarkedNumbers: (index, newValues) =>
    set(
      produce((draft) => {
        draft.markedNumbers[index] = newValues;
      })
    ),
}));

export default useNumbersMarkedStore;
