import Polyglot from 'node-polyglot';
import sv from './languages/sv';
import en from './languages/en';
import nb from './languages/nb';

const defaultLocale = 'sv';
const phrases = {
  sv,
  en,
  nb,
};

const onMissingKey = (key) => {
  console.warn(`Missing translation for key: "${key}"`);

  return key;
};

const text = new Polyglot({
  locale: defaultLocale,
  phrases: sv,
  onMissingKey,
});

const switchLanguage = (locale) => {
  text.locale(locale);
  text.replace(phrases[locale]);
};

export { text, switchLanguage };
