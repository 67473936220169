// eslint-disable-next-line import/no-unresolved
import gsap from 'gsap';
import {
  AnimatedSprite,
  Container,
  Graphics,
  Texture,
  Text,
  Sprite,
  SCALE_MODES,
  // eslint-disable-next-line import/no-unresolved
} from 'pixi.js';

import MathUtils from 'utils/MathUtils';

export default class RocketAnim {
  constructor(
    defaultScale,
    assets,
    potConfig,
    animType,
    onCompleteMultiCallback,
    onCompleteStopEngineCallback
  ) {
    this.defaultScale = defaultScale;
    this.windowWidth = 218 * this.defaultScale;
    this.container = new Container();
    this.counter = 12; // Magic number
    this.assets = assets;
    this.onCompleteMultiCallback = onCompleteMultiCallback;
    this.onCompleteStopEngineCallback = onCompleteStopEngineCallback;
    this.potConfig = potConfig;
    this.animType = animType;
    this.numberOfLoopsInAnimation = 3;
    if (this.animType === 'medium') {
      this.numberOfLoopsInAnimation = 2;
    }
    if (this.animType === 'fast') {
      this.numberOfLoopsInAnimation = 1;
    }

    this.vobbleFactor = 0.08;

    this.rocketAnim = this.createAnim(assets.data.animations.rocket);
    this.rocketAnim.animationSpeed = 0.5;
    this.rocketAnim.loop = true;
    this.rocketAnim.anchor.set(0.5, 0.5);
    this.rocketAnim.scale.x = this.defaultScale;
    this.rocketAnim.scale.y = this.defaultScale;
    this.rocketAnim.play();

    this.fireAnim = this.createAnim(assets.data.animations.fire);
    this.fireAnim.animationSpeed = 0.5;
    this.fireAnim.loop = true;
    this.fireAnim.anchor.set(0.5, 0);
    this.fireAnim.scale.x = this.defaultScale;
    this.fireAnim.scale.y = this.defaultScale;
    this.fireAnim.y = 305 * this.defaultScale;
    this.fireAnimDefaultY = this.fireAnim.y;
    this.fireAnim.play();

    this.animContainer = new Container();
    this.animContainer.addChild(this.fireAnim);
    this.animContainer.addChild(this.rocketAnim);

    this.container.addChild(this.animContainer);

    this.setupWindowMask();
    this.setupMultiplierList();
  }

  setupWindowMask() {
    this.mask = new Graphics();
    this.mask.beginFill(0x000000);
    this.mask.drawCircle(0, 0, this.windowWidth / 2 - 1);
    this.mask.endFill();
    this.mask.x = -13 * this.defaultScale;
    this.mask.y = -283 * this.defaultScale;
    this.animContainer.addChild(this.mask);
  }

  setupMultiplierList() {
    this.multiplierList = [...this.potConfig.multiplierList];
    this.multipListLabel = [];
    this.labelsYOffset = -330 * this.defaultScale;

    this.labelContainer = new Container();

    this.animContainer.addChild(this.labelContainer);

    this.labelContainer.x = -this.windowWidth * 0.55;
    this.labelContainer.y = this.labelsYOffset + 100;
    this.labelContainer.mask = this.mask;

    let multiplierRowsAdded = 0;
    const lastLoopIndex = this.numberOfLoopsInAnimation - 1;
    for (let loops = 0; loops <= lastLoopIndex; loops++) {
      for (let i = 0; i < this.multiplierList.length; i++) {
        const label = this.createLabel(this.multiplierList[i]);
        label.x = this.windowWidth * 0.5;
        label.y = multiplierRowsAdded * 80;
        if (loops === lastLoopIndex) {
          this.multipListLabel.push(label);
        }
        this.labelContainer.addChild(label);
        multiplierRowsAdded++;
      }
    }
    const flare = Sprite.from(this.assets.textures['window-flare.png']);
    flare.scale.set(this.defaultScale + 0.25);
    flare.x = -24 * this.defaultScale;
    flare.y = -374 * this.defaultScale;
    this.animContainer.addChild(flare);
  }

  createLabel(value) {
    const strLength = value.toString().length;
    const fontSize = MathUtils.map(
      strLength,
      1,
      4,
      92 * this.defaultScale,
      70 * this.defaultScale
    );

    const labelContainer = new Container();

    const labelX = new Text('x', {
      fontFamily: 'Zing Rust Demo Base',
      fontSize,
      fill: 0x032a3d,
    });
    labelX.scale.x = 0.65;
    labelX.scale.y = 0.65;
    labelContainer.addChild(labelX);

    const labelValue = new Text(`${value}`, {
      fontFamily: 'Zing Rust Demo Base',
      fontSize,
      fill: 0x032a3d,
    });
    labelContainer.addChild(labelValue);
    labelValue.x = labelX.width - 2 * this.defaultScale;
    labelX.y = labelValue.height - 1.11 * labelX.height;
    //    labelX.y -= 2 * this.defaultScale;

    labelContainer.pivot.set(
      labelContainer.width / 2,
      labelContainer.height / 2
    );
    return labelContainer;
  }

  showMultiplier(multiplier) {
    const index = this.multiplierList.findLastIndex((m) => m === multiplier);
    const label = this.multipListLabel[index];
    const yPos = label.y - 44 * this.defaultScale;
    const duration = index * 0.4 + 3.5;

    this.multiListTl = gsap.timeline({ paused: true });
    //  this.labelContainer.y = this.labelsYOffset - yPos;
    this.multiListTl.to(this.labelContainer, {
      y: this.labelsYOffset - yPos,
      duration,
      ease: 'power3.inOut',
    });
    this.multiListTl.to(
      label.scale,
      {
        x: 1.3,
        y: 1.3,
        duration: 0.3,
        ease: 'power1.out',
      },
      '-=0.2'
    );
    this.multiListTl.to(
      label.scale,
      {
        x: 1,
        y: 1,
        duration: 0.8,
        ease: 'elastic.out(1, 0.3)',
        onComplete: () => {
          this.onCompleteMultiCallback();
        },
      },
      'end'
    );

    if (this.animType === 'fast') {
      // this.multiListTl.seek('end');
      this.multiListTl.timeScale(4);
    } else if (this.animType === 'medium') {
      this.multiListTl.timeScale(2);
    }
    this.multiListTl.play();
  }

  stopEngine() {
    this.stopEngineTl = gsap.timeline({ paused: true });
    this.stopEngineTl.to(this, {
      vobbleFactor: 0,
      duration: 0.4,
    });
    this.stopEngineTl.to(this.fireAnim.scale, {
      x: 0.1,
      y: 0.1,
      duration: 0.8,
    });
    this.stopEngineTl.to(
      this.animContainer,
      {
        x: 0,
        duration: 0.5,
      },
      '<'
    );
    this.stopEngineTl.to(this.fireAnim, {
      alpha: 0,
      duration: 0.2,
    });
    this.stopEngineTl.to(
      this.fireAnim,
      {
        y: this.fireAnimDefaultY - 45 * this.defaultScale,
        duration: 0.6,
      },
      '<'
    );
    this.stopEngineTl.to(
      this.rocketAnim,
      {
        animationSpeed: 0,
        duration: 0.5,
      },
      '<'
    );
    this.stopEngineTl.call(
      () => {
        this.onCompleteStopEngineCallback();
      },
      null,
      '<'
    );

    if (this.animType === 'fast') {
      // this.multiListTl.seek('end');
      this.stopEngineTl.timeScale(4);
    } else if (this.animType === 'medium') {
      this.stopEngineTl.timeScale(2);
    }
    this.stopEngineTl.play();
  }

  createAnim(frames) {
    const textureArray = [];
    for (let i = 0; i < frames.length; i++) {
      const texture = Texture.from(frames[i]);
      texture.baseTexture.scaleMode = SCALE_MODES.LINEAR;
      textureArray.push(texture);
    }
    const animSprite = new AnimatedSprite(textureArray);
    return animSprite;
  }

  update() {
    if (this.vobbleFactor === 0) return;
    this.counter += this.vobbleFactor;
    this.animContainer.x = Math.sin(this.counter) * 16;
  }

  destroy() {
    gsap.killTweensOf(this.labelContainer);
    this.rocketAnim.destroy({ children: true });
    this.rocketAnim = null;

    this.multiListTl.kill();
    this.multiListTl = null;
  }
}
