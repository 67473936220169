import React from 'react';
import { bool, func, number, string, shape } from 'prop-types';
import clsx from 'clsx';

import { text } from 'polyglot/polyglot';
import { formatCurrency } from 'utils/currency';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import BonusDisplay from '../BonusDisplay/BonusDisplay';

import styles from './PurchaseReceipt.module.scss';

function PurchaseReceipt({
  isLoading,
  onClose,
  cardPrice,
  cardsCount,
  totalPrice,
  username,
  bingoName,
  displayBonus,
  sumNotIncludedInBonus,
}) {
  return (
    <Modal
      isLoading={isLoading}
      onClickOutside={onClose}
      title={text.t('purchaseModal.receipt.title')}
      secondaryTitle={bingoName}
      content={
        <div className={styles.table}>
          <div className={styles.row}>
            <p className={styles.th}>
              {text.t('purchaseModal.purchase.bingoCardPrice')}:
            </p>
            <p className={styles.td}>
              {text.t('purchaseModal.purchase.currencyAppendix', {
                amount: formatCurrency(cardPrice),
              })}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.th}>
              {text.t('purchaseModal.purchase.bingoCardsAmount')}:
            </p>
            <p className={styles.td}>
              {text.t('purchaseModal.purchase.countAppendix', {
                total: cardsCount,
              })}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.th}>
              {text.t('purchaseModal.purchase.total')}:
            </p>
            <p className={styles.td}>
              {text.t('purchaseModal.purchase.currencyAppendix', {
                amount: formatCurrency(totalPrice),
              })}
            </p>
          </div>
          {displayBonus && (
            <BonusDisplay
              displayBonus={displayBonus}
              purchaseTotal={totalPrice}
              isReceipt
              sumNotIncludedInBonus={sumNotIncludedInBonus}
            />
          )}
          <p
            className={clsx(styles.text, {
              [styles.hasBonusDisplay]: displayBonus,
            })}
          >
            {text.t('purchaseModal.receipt.goodLuck', {
              name: username,
            })}
          </p>
        </div>
      }
      buttons={[
        <Button
          key="cancel-button"
          className={styles.button}
          onClick={onClose}
          isTertiary
        >
          {text.t('purchaseModal.receipt.returnButton')}
        </Button>,
      ]}
    />
  );
}

PurchaseReceipt.propTypes = {
  isLoading: bool,
  onClose: func,
  cardPrice: number.isRequired,
  cardsCount: number.isRequired,
  totalPrice: number.isRequired,
  username: string.isRequired,
  bingoName: string.isRequired,
  displayBonus: shape(),
  sumNotIncludedInBonus: number.isRequired,
};

PurchaseReceipt.defaultProps = {
  isLoading: false,
  onClose: () => {},
  displayBonus: null,
};

export default PurchaseReceipt;
