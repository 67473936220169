import React from 'react';
import clsx from 'clsx';
import { arrayOf, shape, string, number, object } from 'prop-types';

import useGameStore, { ACTIVE_GAME } from 'stores/game';

import DragScroll from 'components/DragScroll/DragScroll';
import BingoCard from 'components/BingoCard/BingoCard';
import LastDrawnBalls from 'components/GameInfoPanel/LastDrawnBalls/LastDrawnBalls';
import ListOfPrizes from 'components/GameInfoPanel/ListOfPrizes/ListOfPrizes';

import styles from './LayoutDefault.module.scss';

const gameStateSelector = (state) => state.gameState;

function LayoutDefault({ items, layoutId }) {
  const [firstCard, ...restCards] = items;
  const gameState = useGameStore(gameStateSelector);

  return (
    <>
      <div
        className={clsx(styles.miniCard, {
          [styles.isVisible]: layoutId === 0 && gameState === ACTIVE_GAME,
        })}
      >
        {firstCard && (
          <span className={styles.card}>
            <BingoCard
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...firstCard}
              hasFooter
            />
          </span>
        )}
        <LastDrawnBalls isVertical />
        <div className={styles.prizes}>
          <ListOfPrizes isVertical />
        </div>
      </div>
      <DragScroll
        classNameScrollbar={clsx(styles.scrollbar, {
          [styles.isHidden]: layoutId === 0 && gameState === ACTIVE_GAME,
        })}
        forceUpdate={layoutId}
      >
        <div
          className={clsx(styles.wrapper, {
            [styles.isHidden]: layoutId === 0 && gameState === ACTIVE_GAME,
          })}
        >
          {firstCard && (
            <div
              key={firstCard.id}
              className={clsx(styles.item, styles.isFirst)}
            >
              <BingoCard
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...firstCard}
                isLarge
                hasFooter
              />
            </div>
          )}

          <div
            className={clsx(styles.row, {
              [styles.isGrid]: layoutId === 2,
              [styles.isList]: layoutId === 1,
            })}
          >
            {restCards.map((card) => (
              <div key={card.id} className={styles.item}>
                <BingoCard
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...card}
                  isMedium={layoutId === 2}
                  isLarge={layoutId === 1}
                  hasFooter
                />
              </div>
            ))}
          </div>
        </div>
      </DragScroll>
    </>
  );
}

LayoutDefault.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      numbersMap: arrayOf(arrayOf(object)),
    })
  ),
  layoutId: number,
};

LayoutDefault.defaultProps = {
  items: [],
  layoutId: 1,
};

export default LayoutDefault;
