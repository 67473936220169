import { create } from 'zustand';

export const MARKING_SYMBOL_OVAL = 'MARKING_SYMBOL_OVAL';
export const MARKING_SYMBOL_POLYGON = 'MARKING_SYMBOL_POLYGON';
export const MARKING_SYMBOL_RECTANGLE = 'MARKING_SYMBOL_RECTANGLE';
export const MARKING_SYMBOL_STAR = 'MARKING_SYMBOL_STAR';
export const MARKING_SYMBOL_TRIANGLE = 'MARKING_SYMBOL_TRIANGLE';

const useSettingsStore = create((set) => ({
  settings: {
    autosort: false,
    automark: false,
    symbol: MARKING_SYMBOL_OVAL,
    color: 1,
    announcer: true,
    effects: false,
    music: false,
    userAvatar: -1,
    muted: false,
  },
  updateSettings: (newSettings) =>
    set((state) => ({ settings: { ...state.settings, ...newSettings } })),
  resetSettings: (newSettings) =>
    set(() => ({
      settings: newSettings,
    })),
}));

export default useSettingsStore;
