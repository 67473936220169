import React from 'react';
import { string, bool } from 'prop-types';
import clsx from 'clsx';

import useLayoutStore from 'stores/layout';

import styles from './BingoSquare.module.scss';

const isLongTicketsSelector = (state) => state.isLongTickets;
const isTinyTicketsSelector = (state) => state.isTinyTickets;
const is4x4TicketsSelector = (state) => state.is4x4Tickets;

function BingoSquare({
  type,
  isDark,
  isVertical,
  isGolden,
  isPotBoosterActive,
}) {
  const isLongTicket = useLayoutStore(isLongTicketsSelector);
  const isTinyTickets = useLayoutStore(isTinyTicketsSelector);
  const is4x4Tickets = useLayoutStore(is4x4TicketsSelector);
  const is3x3Tickets = isTinyTickets && !is4x4Tickets;

  let totalFields = 25;
  if (isLongTicket) {
    totalFields = 15;
  }
  if (is3x3Tickets) {
    totalFields = 9;
  }
  if (is4x4Tickets) {
    totalFields = 16;
  }

  const collectFields = () => {
    const innerSquareIndex = [6, 7, 8, 11, 12, 13, 16, 17, 18];
    const onewRowIndexTop = [0, 1, 2, 3, 4];
    const onewRowIndex = [10, 11, 12, 13, 14];
    const oneRowIndex30Bingo = [3, 4, 5];
    const oneRowVertical = [2, 7, 12, 17, 22];
    const oneRowVertical30Bingo = [1, 4, 7];
    const oneRowVertical4x4 = [0, 4, 8, 12];
    const oneRowCross = [0, 6, 12, 18, 24];
    const oneRowCross30Bingo = [0, 4, 8];
    const oneRowCross4x4 = [0, 5, 10, 15];
    const fourCorners4x4 = [0, 3, 12, 15];
    const diamond = [2, 6, 7, 8, 10, 11, 12, 13, 14, 16, 17, 18, 22];
    const mSymbol = [0, 4, 5, 6, 8, 9, 10, 12, 14, 15, 19, 20, 24];
    const plus = [2, 7, 10, 11, 12, 13, 14, 17, 22];

    const elements = [];
    for (let i = 0; i < totalFields; i++) {
      elements.push(
        <div
          key={`square-${i}`}
          className={clsx(styles.field, {
            [styles.isVertical]: isVertical,
            [styles.is9Fields]: totalFields === 9,
            [styles.is4x4]: is4x4Tickets,
            [styles.darker]: isDark,
            [styles.filled]:
              type === 'fullcard' ||
              (type === '1rowhor' &&
                isLongTicket &&
                i <= 5 &&
                onewRowIndexTop.includes(i)) ||
              (type === '1rowhor' &&
                !isLongTicket &&
                !isTinyTickets &&
                i >= 5 &&
                onewRowIndex.includes(i)) ||
              (type === '1rowhor' &&
                is3x3Tickets &&
                oneRowIndex30Bingo.includes(i)) ||
              (type === '1rowhor' && is4x4Tickets && i < 4) ||
              (type === '2rowhor' && !isTinyTickets && i < 10) ||
              (type === '2rowhor' && is3x3Tickets && i < 6) ||
              (type === '2rowhor' && is4x4Tickets && i < 8) ||
              (type === '3rowhor' && !isTinyTickets && i < 15) ||
              (type === '3rowhor' && is4x4Tickets && i < 12) ||
              (type === '4rowhor' && i < 20) ||
              (type === 'square' && !innerSquareIndex.includes(i)) ||
              (type === 'innerSquare' && innerSquareIndex.includes(i)) ||
              (type === 'diamond' && diamond.includes(i)) ||
              (type === '1rowvert' &&
                !isTinyTickets &&
                oneRowVertical.includes(i)) ||
              (type === '1rowvert' &&
                is3x3Tickets &&
                oneRowVertical30Bingo.includes(i)) ||
              (type === '1rowvert' &&
                is4x4Tickets &&
                oneRowVertical4x4.includes(i)) ||
              (type === '1rowdiag' &&
                !isTinyTickets &&
                oneRowCross.includes(i)) ||
              (type === '1rowdiag' &&
                is3x3Tickets &&
                oneRowCross30Bingo.includes(i)) ||
              (type === '1rowdiag' &&
                is4x4Tickets &&
                oneRowCross4x4.includes(i)) ||
              (type === '4cor' && is4x4Tickets && fourCorners4x4.includes(i)) ||
              (type === 'm' && mSymbol.includes(i)) ||
              (type === 'plus' && plus.includes(i)),
            [styles.gold]: isGolden,
            [styles.isPotBoosterActive]: isPotBoosterActive,
          })}
        />
      );
    }
    return elements;
  };
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isVertical]: isVertical,
        [styles.isSmallSquare]: totalFields === 15,
        [styles.is4x4]: is4x4Tickets,
      })}
    >
      {collectFields()}
    </div>
  );
}

BingoSquare.propTypes = {
  type: string.isRequired,
  isDark: bool,
  isVertical: bool,
  isGolden: bool,
  isPotBoosterActive: bool,
};

BingoSquare.defaultProps = {
  isDark: false,
  isVertical: false,
  isGolden: false,
  isPotBoosterActive: false,
};

export default BingoSquare;
