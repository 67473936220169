import React, { useState, useEffect } from 'react';
import { bool } from 'prop-types';
import { text } from 'polyglot/polyglot';

import useCurrentGameStore from 'stores/current-game';
import useCountdownStore from 'stores/countdown-state';
import bingoAssetsStore from 'stores/bingoAssetsStore';

import SmallerBanner from './SmallerBanner/SmallerBanner';
import LargerBanner from './LargerBanner/LargerBanner';
import NakedBanner from './NakedBanner/NakedBanner';

const currentGameSelector = (state) => state.currentGame;
const hourSelector = (state) => state.hours;
const minutesSelector = (state) => state.minutes;
const secondsSelector = (state) => state.seconds;
const startOfGameSelector = (state) => state.startOfGame;
const activeCountdownGameSelector = (state) => state.activeCountdownGame;
const assetsSelector = (state) => state.assets;

function UpcomingGameBanner({ largeBanner, nakedBanner, hideInfoButton }) {
  const currentGame = useCurrentGameStore(currentGameSelector);
  const hours = useCountdownStore(hourSelector);
  const minutes = useCountdownStore(minutesSelector);
  const seconds = useCountdownStore(secondsSelector);
  const startOfGame = useCountdownStore(startOfGameSelector);
  const [background, setBackground] = useState('');
  const [logoSmall, setLogoSmall] = useState('');
  const [logoBig, setLogoBig] = useState('');
  const activeCountdownGame = useCountdownStore(activeCountdownGameSelector);
  const assets = bingoAssetsStore(assetsSelector);

  useEffect(() => {
    let mounted = true;
    if (activeCountdownGame && mounted) {
      if (assets[activeCountdownGame.backgroundSmall]) {
        setBackground(assets[activeCountdownGame.backgroundSmall]);
      }
      if (assets[activeCountdownGame.logoSmall]) {
        setLogoSmall(assets[activeCountdownGame.logoSmall]);
      }
      if (assets[activeCountdownGame.logoBig]) {
        setLogoBig(assets[activeCountdownGame.logoBig]);
      }
    }

    // Cleanup
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCountdownGame, assets]);

  if (largeBanner) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {startOfGame && hours && minutes && seconds && (
          <LargerBanner
            hideInfoButton={hideInfoButton}
            background={background}
            logoSmall={logoSmall}
            logoBig={logoBig}
            text={
              currentGame.isDrawing
                ? text.t('gameBanner.largeBannerTitlePrebuy')
                : text.t('gameBanner.largeBannerTitleBuy')
            }
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
        )}
      </>
    );
  }
  if (nakedBanner) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {startOfGame && minutes && seconds && (
          <NakedBanner
            name={
              activeCountdownGame && activeCountdownGame.name
                ? activeCountdownGame.name
                : ''
            }
            minutes={minutes}
            seconds={seconds}
          />
        )}
      </>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {startOfGame && minutes && seconds && (
        <SmallerBanner
          minutes={minutes}
          seconds={seconds}
          gameId={currentGame.bingoRoundId || 0}
        />
      )}
    </>
  );
}

UpcomingGameBanner.propTypes = {
  largeBanner: bool,
  nakedBanner: bool,
  hideInfoButton: bool,
};

UpcomingGameBanner.defaultProps = {
  largeBanner: false,
  nakedBanner: false,
  hideInfoButton: false,
};

export default UpcomingGameBanner;
