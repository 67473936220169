/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import useUserStore from 'stores/user';
import qs from 'qs';

let baseURL = '';

const client = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Axios request interceptor that adds token to headers.
 */
client.interceptors.request.use(
  (config) => {
    const { user } = useUserStore.getState();
    if (user.access_token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
        accept: 'application/json',
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/**
 * Axios response interceptor.
 * Checks if we get an 401 (Unauthorized) error.
 * Then requests a new token using the refresh_token.
 */
// response interceptor to refresh token on receiving token expired error
client.interceptors.response.use(
  (response) => response.data || response,

  (error) => {
    const originalRequest = error.config;
    const userStore = useUserStore.getState();
    const { user } = userStore;
    const refreshToken = user.refresh_token;
    if (
      refreshToken &&
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const formData = qs.stringify({
        client_secret: 'secret_for_randomstate_customer',
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: 'randomstate_customer',
      });
      return axios
        .post(`${baseURL}/connect/token`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((res) => {
          if (res.status === 200) {
            userStore.setUser({
              access_token: res.data.access_token,
              refresh_token: res.data.refresh_token,
            });

            return client.request(originalRequest);
          }
        });
    }
    return Promise.reject(error.response || error);
  }
);

const setBaseURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    try {
      client.defaults.baseURL = url;
      baseURL = url;
      console.log('baseUrl', client.defaults.baseURL);

      resolve(url);
    } catch (error) {
      reject(error);
    }
  });

  return promise;
};

// Not used anymore
const setClientToken = (token) => {
  const promise = new Promise((resolve, reject) => {
    try {
      client.defaults.headers.common.Authorization = `Bearer ${token}`;

      resolve(token);
    } catch (error) {
      reject(error);
    }
  });

  return promise;
};

const api = {
  setBaseURL,
  setClientToken,
  get: (url, options) => client.get(baseURL + url, options),
  post: (url, payload) => client.post(baseURL + url, payload),
  put: (url, payload) => client.put(baseURL + url, payload),
  patch: (url, payload) => client.patch(baseURL + url, payload),
  delete: (url) => client.delete(baseURL + url),
};

export default api;
