import { create } from 'zustand';

const useWinnerStatsStore = create((set) => ({
  winners: [],
  scoreboard: null,
  resetWinners: () => set({ winners: [] }),
  setWinnerList: (newWinnerList) =>
    set(() => ({
      winners: newWinnerList,
    })),
  resetScoreboard: () => set({ scoreboard: null }),
  setScoreboardList: (newScoreboardList) =>
    set(() => ({
      scoreboard: newScoreboardList,
    })),
}));

export default useWinnerStatsStore;
