import React from 'react';
import { arrayOf, shape, string, object, bool } from 'prop-types';

import useLayoutStore from 'stores/layout';
import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';

import LayoutDefault from './LayoutDefault/LayoutDefault';
import LayoutDefaultSmall from './LayoutDefaultSmall/LayoutDefaultSmall';
import LayoutWithFooterScroll from './LayoutWithFooterScroll/LayoutWithFooterScroll';
import LayoutWithFooterScrollSmall from './LayoutWithFooterScrollSmall/LayoutWithFooterScrollSmall';
import LayoutLongDefault from './LayoutLongDefault/LayoutLongDefault';
import Layout3Rows from './Layout3Rows/Layout3Rows';
import LayoutWithFooterScrollTiny from './LayoutWithFooterScrollTiny/LayoutWithFooterScrollTiny';

import styles from './BingoCardsLayout.module.scss';

const layoutSelector = (state) => state.layoutId;
const smallTicketSelector = (state) => state.isSmallTickets;
const isLongTicketsSelector = (state) => state.isLongTickets;
const isTinyTicketsSelector = (state) => state.isTinyTickets;

function BingoCardsLayout({ items, isSideMenuOpen }) {
  const currentLayoutId = useLayoutStore(layoutSelector);
  const isSmallTickets = useLayoutStore(smallTicketSelector);
  const isLongTickets = useLayoutStore(isLongTicketsSelector);
  const isTinyTickets = useLayoutStore(isTinyTicketsSelector);

  const breakpoint = useBreakpoint();

  const isMobile = breakpoint === MOBILE;
  const isLayoutWithFooter = currentLayoutId === 3 && !isLongTickets;
  const isLayoutWithFooterTiny = currentLayoutId === 2 && isTinyTickets;

  const renderCurrentLayout = () => {
    if (isMobile) {
      if (isSmallTickets || isTinyTickets) {
        return (
          <LayoutDefaultSmall
            items={items}
            layoutId={isSideMenuOpen ? 0 : 1}
            isTinyTickets={isTinyTickets}
          />
        );
      }
      if (isLongTickets) {
        return (
          <LayoutLongDefault items={items} layoutId={isSideMenuOpen ? 0 : 1} />
        );
      }
      return <LayoutDefault items={items} layoutId={isSideMenuOpen ? 0 : 1} />;
    }

    if (isLayoutWithFooter) {
      if (isSmallTickets) {
        return <LayoutWithFooterScrollSmall items={items} />;
      }
      if (isTinyTickets) {
        return <Layout3Rows items={items} />;
      }
      return <LayoutWithFooterScroll items={items} />;
    }

    if (isLayoutWithFooterTiny) {
      return <LayoutWithFooterScrollTiny items={items} />;
    }

    if (isSmallTickets || isTinyTickets) {
      return (
        <LayoutDefaultSmall
          items={items}
          layoutId={currentLayoutId}
          isTinyTickets={isTinyTickets}
        />
      );
    }
    if (isLongTickets) {
      return <LayoutLongDefault items={items} layoutId={currentLayoutId} />;
    }
    return <LayoutDefault items={items} layoutId={currentLayoutId} />;
  };

  return <div className={styles.wrapper}>{renderCurrentLayout()}</div>;
}

BingoCardsLayout.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      numbers: arrayOf(arrayOf(object)),
    })
  ),
  isSideMenuOpen: bool,
};

BingoCardsLayout.defaultProps = {
  items: [],
  isSideMenuOpen: false,
};

export default BingoCardsLayout;
