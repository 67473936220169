// eslint-disable-next-line import/no-unresolved
import { Container, Text } from 'pixi.js';
import gsap from 'gsap';
import { text } from 'polyglot/polyglot';

export default class Header {
  constructor(defaultScale = 0.5) {
    this.defaultScale = defaultScale;
    this.container = new Container();

    // Add extra space to avoid last letter being cropped
    this.label = new Text(`${text.t('potbooster.header')} `, {
      fontFamily: 'Zing Rust Demo Base',
      fontSize: 140 * defaultScale,
      fill: 0xfadc9c,
      letterSpacing: 1.2,
    });
    this.label.alpha = 0;
    this.label.anchor.set(0.5, 0.5);
    this.label.x = this.label.width / 2;
    this.label.y = this.label.height / 2;
    this.container.addChild(this.label);
    this.container.pivot.set(
      this.container.width / 2,
      this.container.height / 2
    );
  }

  show(delay = 0.5) {
    gsap.to(this.label, {
      startAt: { alpha: 0, y: 40 * this.defaultScale, scale: 0.5 },
      alpha: 1,
      y: 0,
      scale: 1,
      duration: 0.5,
      delay,
    });
    gsap.to(this.label.scale, {
      startAt: { x: 0.5, y: 0.5 },
      x: 1,
      y: 1,
      duration: 0.5,
      delay,
    });
  }

  destroy() {
    if (this.label) {
      gsap.killTweensOf(this.label);
      gsap.killTweensOf(this.label.scale);
      if (this.container) {
        this.container.destroy({ children: true });
        this.container = null;
      }
    }
    if (this.container) {
      this.container.destroy({ children: true });
      this.container = null;
    }
  }
}
