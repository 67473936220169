import React from 'react';
import { string, oneOf, oneOfType, number, bool, func, node } from 'prop-types';
import clsx from 'clsx';

import useSettingsStore, {
  MARKING_SYMBOL_OVAL,
  MARKING_SYMBOL_POLYGON,
  MARKING_SYMBOL_RECTANGLE,
  MARKING_SYMBOL_STAR,
  MARKING_SYMBOL_TRIANGLE,
} from 'stores/settings';
import useLayoutStore from 'stores/layout';

import Oval from 'assets/svg/oval-shape.svg';
import Polygon from 'assets/svg/polygon-shape.svg';
import Rectangle from 'assets/svg/rectangle-shape.svg';
import Star from 'assets/svg/star-shape.svg';
import Triangle from 'assets/svg/triangle-shape.svg';

import styles from './NumberBlock.module.scss';

const symbols = {
  [MARKING_SYMBOL_OVAL]: <Oval viewBox="0 0 30 30" />,
  [MARKING_SYMBOL_POLYGON]: <Polygon viewBox="0 0 32 30" />,
  [MARKING_SYMBOL_RECTANGLE]: <Rectangle viewBox="0 0 30 30" />,
  [MARKING_SYMBOL_STAR]: <Star viewBox="0 0 30 28" />,
  [MARKING_SYMBOL_TRIANGLE]: <Triangle viewBox="0 0 30 29" />,
};

const longTicketSelector = (state) => state.isLongTickets;
const isTinyTicketsSelector = (state) => state.isTinyTickets;
const is4x4TicketsSelector = (state) => state.is4x4Tickets;
const willAutomarkSelector = (state) => state.settings.automark;

function NumberBlock({
  value,
  symbol,
  symbolColorId,
  isActive,
  isHistory,
  onClick,
  isAnimating,
  valueIsIcon,
  isTallHistoryCard,
  isLongHistoryCard,
}) {
  const onClickNumber = () => onClick(value);
  const isLongTicket = useLayoutStore(longTicketSelector);
  const isTinyTickets = useLayoutStore(isTinyTicketsSelector);
  const is4x4Tickets = useLayoutStore(is4x4TicketsSelector);
  const willAutomark = useSettingsStore(willAutomarkSelector);

  const isDoubleAction = value?.length > 1;

  if (!valueIsIcon && !value?.length && !value?.[0] && !isLongTicket)
    return (
      <div
        className={clsx(styles.number, {
          [styles.isBlank]: true,
        })}
      />
    );

  return (
    <button
      type="button"
      className={clsx(styles.number, {
        [styles.isActive]: isActive,
        [styles.isAutomarking]: isActive && willAutomark,
        [styles.isHistory]: isHistory,
        [styles.isAnimating]: isAnimating,
        [styles.isLongTicket]: isLongTicket,
        [styles.isTinyTickets]: isTinyTickets,
        [styles.is4x4Tickets]: is4x4Tickets,
        [styles.isTallHistoryCard]: isTallHistoryCard,
        [styles.isLongHistoryCard]: isLongHistoryCard,
        [styles.isDoubleAction]: isDoubleAction,
      })}
      onClick={onClickNumber}
    >
      {isDoubleAction && <hr className={styles.divider} />}
      {!isHistory && (
        <span
          className={styles.symbol}
          style={{ fill: `var(--lot-highlight-color-${symbolColorId})` }}
        >
          {symbols[symbol]}
        </span>
      )}
      {value &&
        Array.isArray(value) &&
        value.map((num) => (
          <span
            key={num}
            className={clsx(styles.text, {
              [styles.valueIsIcon]: valueIsIcon,
            })}
          >
            {num}
          </span>
        ))}
    </button>
  );
}

NumberBlock.propTypes = {
  value: oneOfType([string, number, node]),
  symbol: oneOf([
    MARKING_SYMBOL_OVAL,
    MARKING_SYMBOL_POLYGON,
    MARKING_SYMBOL_RECTANGLE,
    MARKING_SYMBOL_TRIANGLE,
    MARKING_SYMBOL_STAR,
  ]),
  symbolColorId: number,
  isActive: bool,
  isHistory: bool,
  onClick: func,
  isAnimating: bool,
  valueIsIcon: bool,
  isTallHistoryCard: bool,
  isLongHistoryCard: bool,
};

NumberBlock.defaultProps = {
  value: null,
  symbol: MARKING_SYMBOL_OVAL,
  symbolColorId: 1,
  isActive: false,
  isHistory: false,
  onClick: func,
  isAnimating: false,
  valueIsIcon: false,
  isTallHistoryCard: false,
  isLongHistoryCard: false,
};

// Only update if not equal.
const propsAreEqual = (prev, next) => {
  // If it's not active we don't need to update,
  // this is very *important* for performance!
  // Author: @chris.a
  if (!prev.isActive && !next.isActive) {
    return true;
  }

  return (
    prev.isActive === next.isActive &&
    prev.symbol === next.symbol &&
    prev.symbolColorId === next.symbolColorId &&
    prev.canAnimate === next.canAnimate
  );
};

export default React.memo(NumberBlock, propsAreEqual);
