export const CLIENT_PROGRESS = 'client_progress';
export const CURRENT_PROGRESS = 'current_progress';
export const INIT_PROGRESS = 'init_progress';
export const GAME_ID = 'game_id';
export const BONUS_ID = 'bonus_id';

export const checkIfUserHasActiveBonus = (user, currentGameId) => {
  let hasBonus = null;
  if (user?.customerBonus) {
    user.customerBonus.forEach((bonus) => {
      if (hasBonus) return;
      const { qualificationType, baseGameType, gameId } = bonus.qualification;
      if (qualificationType !== 'TurnoverProducts') return;
      if (!baseGameType && !gameId) {
        hasBonus = bonus;
      }
      if (gameId === currentGameId && baseGameType === 'Bingo') {
        hasBonus = bonus;
      }
    });
  }
  return hasBonus;
};
