/* eslint-disable no-nested-ternary */
import React from 'react';
import { number, bool } from 'prop-types';
import clsx from 'clsx';

import useBreakpoint, {
  TABLET_PORTRAIT,
  TABLET_LANDSCAPE,
} from 'hooks/useBreakpoint';
import useHistoryStore from 'stores/history';
import useGameStatsStore from 'stores/current-game';
import useLayoutStore from 'stores/layout';

import Button from 'components/Button/Button';

import lottoIconLarge from 'assets/theme/svg/bingolott-icon-large.svg';
import lottoIconSmall from 'assets/theme/svg/bingolott-icon-small.svg';
import lottoIcon30 from 'assets/theme/svg/bingolott-icon-30.svg';

import styles from './TotalBallsDrawn.module.scss';

const totalNumberSelector = (state) => state.currentGame.numberOfBalls;
const isLongTicketsSelector = (state) => state.isLongTickets;
const isTinyTicketsSelector = (state) => state.isTinyTickets;

function TotalBallsDrawn({ currentNumber, isWhite }) {
  const breakpoint = useBreakpoint();
  const toggleIsHistoryVisible = useHistoryStore(
    (state) => state.toggleIsHistoryVisible
  );
  const totalNumber = useGameStatsStore(totalNumberSelector);
  const isLongsTickets = useLayoutStore(isLongTicketsSelector);
  const isTinyTickets = useLayoutStore(isTinyTicketsSelector);

  const isTinyButTall = isTinyTickets && totalNumber === 60;

  const onClick = () => toggleIsHistoryVisible();

  const returnImage = () => {
    if (isTinyTickets && !isTinyButTall) {
      return lottoIcon30;
    }
    if (breakpoint === TABLET_PORTRAIT || breakpoint === TABLET_LANDSCAPE) {
      return lottoIconSmall;
    }
    return lottoIconLarge;
  };

  return (
    <div className={clsx(styles.wrapper, { [styles.isWhite]: isWhite })}>
      <span className={styles.currentNumber}>{currentNumber}</span>/
      <span className={styles.totalNumber}>{totalNumber}</span>
      <Button
        className={clsx(styles.button, {
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
          [styles.isLongsTickets]: isLongsTickets,
          [styles.isTinyTickets]: isTinyTickets && !isTinyButTall,
        })}
        onClick={onClick}
        isIcon
      >
        <img
          src={returnImage()}
          className={clsx(styles.icon, {
            [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
            [styles.isLongsTickets]: isLongsTickets,
            [styles.isTinyTickets]: isTinyTickets && !isTinyButTall,
          })}
          alt="lotto-icon"
        />
      </Button>
    </div>
  );
}

TotalBallsDrawn.propTypes = {
  currentNumber: number.isRequired,
  isWhite: bool.isRequired,
};

export default TotalBallsDrawn;
