import React from 'react';
import { number, func } from 'prop-types';
import clsx from 'clsx';

import Rectangle from 'assets/svg/rectangle-shape.svg';

import styles from './ColorBoxes.module.scss';

function ColorBoxes({ selectedColor, onSelectNewColor }) {
  return (
    <div className={styles.wrapper}>
      <Rectangle
        className={clsx(styles.colorbox, {
          [styles.active]: selectedColor === 1,
        })}
        onClick={() => onSelectNewColor('color', 1)}
      />
      <Rectangle
        className={clsx(styles.colorbox, {
          [styles.active]: selectedColor === 2,
        })}
        onClick={() => onSelectNewColor('color', 2)}
      />
      <Rectangle
        className={clsx(styles.colorbox, {
          [styles.active]: selectedColor === 3,
        })}
        onClick={() => onSelectNewColor('color', 3)}
      />
      <Rectangle
        className={clsx(styles.colorbox, {
          [styles.active]: selectedColor === 4,
        })}
        onClick={() => onSelectNewColor('color', 4)}
      />
    </div>
  );
}

ColorBoxes.propTypes = {
  selectedColor: number.isRequired,
  onSelectNewColor: func.isRequired,
};

export default ColorBoxes;
