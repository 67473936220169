import React, { useEffect, useRef } from 'react';

import useGameStore from 'stores/game';
import useCurrentGameStore from 'stores/current-game';

import Modal from 'components/Modal/Modal';

import PotBoosterAnimation from './PotBoosterAnimation/PotBoosterAnimation';

import styles from './PotBoosterNotification.module.scss';

const currentGameSelector = (state) => state.currentGame;
const resetPotBoosterNotificationSelector = (state) =>
  state.resetPotBoosterNotification;

function PotBoosterNotification() {
  const currentGame = useCurrentGameStore(currentGameSelector);
  // eslint-disable-next-line no-unused-vars
  const resetPotBoosterNotification = useGameStore(
    resetPotBoosterNotificationSelector
  );
  const timer = useRef();
  const timerCompletedRef = useRef(false);
  const animCompletedRef = useRef(false);
  const potBeforePotBooster =
    currentGame.pot / currentGame.selectedPotBoosterMultiplier;

  useEffect(() => {
    timer.current = setTimeout(() => {
      timerCompletedRef.current = true;
      if (animCompletedRef.current) {
        resetPotBoosterNotification();
      }
    }, currentGame.potBoosterDisplayTimeInSeconds * 1000);

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onAnimComplete() {
    animCompletedRef.current = true;
    if (timerCompletedRef.current) {
      resetPotBoosterNotification();
    }
  }

  return (
    <Modal willFill isPrizeModal>
      <div className={styles.wrapper}>
        <PotBoosterAnimation
          potBoosterMultipliers={currentGame.potBoosterMultipliers}
          selectedPotBoosterMultiplier={
            currentGame.selectedPotBoosterMultiplier
          }
          pot={potBeforePotBooster}
          potTotal={currentGame.pot}
          time={currentGame.potBoosterDisplayTimeInSeconds}
          onAnimComplete={() => {
            onAnimComplete();
          }}
        />
      </div>
    </Modal>
  );
}

export default PotBoosterNotification;
