import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useClickAway } from 'react-use';

import { useChatStore } from 'stores/chat';
import useChatTextStore from 'stores/chat-text';
import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';
import { text } from 'polyglot/polyglot';
import { shallow } from 'zustand/shallow';
import useUserStore from 'stores/user';
import ChatManager from 'socket/ChatManager';
import api from 'utils/api';

import Spinner from 'components/Spinner/Spinner';
import ArrowHead from 'assets/svg/arrowhead-no-fill.svg';
import ChatIcon from 'assets/svg/chat-icon.svg';
import Scrollbars from 'components/Scrollbars/Scrollbars';
import Button from 'components/Button/Button';
import BingoRooms from 'components/BingoRooms/BingoRooms';
import ToggleButton from './ToggleButton/ToggleButton';
import GroupChatHeader from './GroupChatHeader/GroupChatHeader';
import PrivateChatHeader from './PrivateChatHeader/PrivateChatHeader';
import MessageList from './MessageList/MessageList';
import RoomList from './RoomList/RoomList';

import styles from './Chat.module.scss';
import PrivateChatReqModal from './PrivateChatReqModal/PrivateChatReqModal';

const userSelector = (state) => [state.user, state.setUser];
const currentRoomSelector = (state) => [
  state.currentRoom,
  state.setCurrentRoom,
];
const expandedSelector = (state) => [
  state.isChatExpanded,
  state.setIsChatExpanded,
];
const chatroomsSelector = (state) => state.chatrooms;
const activeRequestsSelector = (state) => state.activeChatRequest;
const removeChatroomSelector = (state) => state.removeChatroom;
const lastUpdatedSelector = (state) => state.setLastUpdated;
const resetUnreadCountSelector = (state) => state.resetUnreadCount;
const chatIsClosedSelector = (state) => state.setGroupChatIsClosed;
const bingoRoomsIsVisibleSelector = (state) => [
  state.bingoRoomsIsVisible,
  state.setBingoRoomsVisibility,
];

function Chat() {
  const [isExpanded, setIsChatExpanded] = useChatStore(
    expandedSelector,
    shallow
  );
  const activeRequests = useChatStore(activeRequestsSelector);
  const setGroupChatIsClosed = useChatTextStore(chatIsClosedSelector);

  const breakpoint = useBreakpoint();
  // const wasExpanded = usePrevious(isExpanded);
  const innerRef = useRef(null);

  const chatManager = useRef();

  const [user, setUser] = useUserStore(userSelector, shallow);
  const [currentRoom, setCurrentRoom] = useChatStore(
    currentRoomSelector,
    shallow
  );
  const rooms = useChatStore(chatroomsSelector);
  const removeChatroom = useChatStore(removeChatroomSelector);
  const setLastUpdated = useChatStore(lastUpdatedSelector);
  const resetUnreadCount = useChatStore(resetUnreadCountSelector);

  const [hasUserList, setHasUserList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bingoRoomsIsVisible, setBingoRoomsVisibility] = useChatStore(
    bingoRoomsIsVisibleSelector,
    shallow
  );
  const [numberOfBingoRooms, setNumberOfBingoRooms] = useState(0);

  const isDesktop = breakpoint === DESKTOP;

  const onToggleMenu = () => setIsChatExpanded(!isExpanded);
  const onClickRoom = (room) => {
    setCurrentRoom(room);
    if (bingoRoomsIsVisible) {
      setBingoRoomsVisibility(false);
    }
    if (hasUserList) {
      setHasUserList(false);
    }
  };

  const onClickOutside = () => {
    if (!isDesktop && isExpanded) {
      setIsChatExpanded(false);
    }
  };

  useClickAway(innerRef, onClickOutside);

  const onClickReturn = (id) => {
    // Get first non private room, to return to.
    const groupRoom = rooms.find((r) => !r.isPrivate);
    if (!groupRoom && !groupRoom.roomId) {
      setCurrentRoom(groupRoom);
    } else {
      setCurrentRoom(rooms[0]);
    }

    // Leave SignalR connection
    chatManager.current.leaveChatRoom(id, user.nickname);

    // Remove tab from global state
    removeChatroom(id);
  };

  const { isPrivate } = currentRoom || false;

  useEffect(() => {
    if (currentRoom) {
      if (isLoading) {
        setIsLoading(false);
      }
      if (currentRoom.unreadCount > 0) {
        const index = rooms.findIndex(
          (room) => room.roomId === currentRoom.roomId
        );
        if (index !== -1) {
          resetUnreadCount(index);
          setLastUpdated(Date.now());
        }
      }
    } else if (!currentRoom && !isLoading) {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom]);

  // Hide menu on resize.
  useEffect(() => {
    if (!isDesktop) {
      setIsChatExpanded(false);
      setBingoRoomsVisibility(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const collectChatId = async () => {
    await api
      .get('/api/Bingo/rooms')
      .then((res) => {
        const activeRoom = res.find((room) => room.id === Number(user.room));
        setNumberOfBingoRooms(res.length);
        if (activeRoom) {
          if (!activeRoom.chatActive) {
            setGroupChatIsClosed(true);
          }
          setUser({
            chatId: activeRoom.chatId,
          });
        }
      })
      .catch((err) => {
        console.log('error on getting chat id: ', err);
      });
  };

  useEffect(() => {
    if (!user.bingocardId) {
      if (!user.chatId && user.room) {
        collectChatId();
      }
      if (user.access_token && user.nickname && user.chatId) {
        if (!chatManager.current) {
          chatManager.current = ChatManager.instance;
        }
      }
      if (!user.access_token && user.chatId) {
        if (!chatManager.current) {
          chatManager.current = ChatManager.instance;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const sendDataLayer = () => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'ga4_event',
      ga4_data: {
        event_name: 'bingo_show_all_rooms',
        section: 'Bingo client',
        allow_auto_collect: true,
      },
    });
  };

  const onClickBingoRooms = () => {
    sendDataLayer();
    if (bingoRoomsIsVisible) {
      const index = rooms.findIndex(
        (room) => room.roomId === currentRoom.roomId
      );
      if (index !== -1) {
        resetUnreadCount(index);
        setLastUpdated(Date.now());
      }
    }
    setBingoRoomsVisibility(!bingoRoomsIsVisible);
  };

  const hideBingoRoomsButton = numberOfBingoRooms < 2 || !user.bingo_room_links;

  return (
    <div
      ref={innerRef}
      className={clsx(styles.wrapper, {
        [styles.isExpanded]: isExpanded,
      })}
    >
      {!isLoading && isDesktop && !hideBingoRoomsButton && (
        <div
          className={clsx(styles.bingoRoomsButton, {
            [styles.isDisplayingBingoRooms]: bingoRoomsIsVisible,
          })}
        >
          <Button isClean onClick={onClickBingoRooms}>
            <ArrowHead className={styles.arrow} />
            <span className={clsx({ [styles.isHidden]: bingoRoomsIsVisible })}>
              {text.t('chat.bingoRoomsButtonOpen')}
            </span>
            <span className={clsx({ [styles.isHidden]: !bingoRoomsIsVisible })}>
              {text.t('chat.bingoRoomsButtonClose')}
            </span>
          </Button>
          <ChatIcon
            className={clsx(styles.chatIcon, {
              [styles.isHidden]: !bingoRoomsIsVisible,
            })}
          />
        </div>
      )}
      <div
        className={clsx(styles.bingoRoomsContent, {
          [styles.isHidden]: !bingoRoomsIsVisible,
        })}
      >
        {bingoRoomsIsVisible && (
          <Scrollbars className={styles.scrollable}>
            <BingoRooms />
          </Scrollbars>
        )}
      </div>

      {!isLoading &&
        activeRequests.length > 0 &&
        activeRequests.map((req) => (
          <PrivateChatReqModal
            key={req.requestId}
            request={req}
            cantBeAccepted={rooms.length >= 8}
          />
        ))}

      <ToggleButton isActive={isExpanded} onClick={onToggleMenu} />

      {isLoading && <Spinner />}

      {rooms && rooms.length > 0 && (
        <RoomList
          rooms={rooms}
          isVisible={isExpanded}
          currentRoomId={currentRoom?.roomId}
          onClick={onClickRoom}
          hasUserList={hasUserList}
        />
      )}

      {!isLoading && currentRoom && (
        <div
          className={clsx(styles.content, {
            [styles.isHidden]: bingoRoomsIsVisible,
          })}
        >
          {isPrivate ? (
            <PrivateChatHeader
              currentRoomId={currentRoom?.roomId}
              onClickReturn={onClickReturn}
            />
          ) : (
            <GroupChatHeader
              currentRoomId={currentRoom?.roomId}
              cantRequest={rooms.length >= 8}
              hasUserList={hasUserList}
              setHasUserList={setHasUserList}
            />
          )}

          {rooms && rooms.length > 0 && currentRoom && (
            <MessageList
              currentRoomId={currentRoom?.roomId}
              isPrivate={currentRoom.isPrivate}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Chat;
