import React, { useState, useEffect, useRef } from 'react';
import { string, bool } from 'prop-types';
import clsx from 'clsx';
import { shallow } from 'zustand/shallow';
import ChatManager from 'socket/ChatManager';
import { useChatStore } from 'stores/chat';
import useChatTextStore from 'stores/chat-text';
import bingoAssetsStore from 'stores/bingoAssetsStore';
import { useUpdateEffect } from 'react-use';

import useUserStore from 'stores/user';

import Avatar from 'components/Chat/Avatar/Avatar';
import Scrollbars from 'components/Scrollbars/Scrollbars';

import InputField from './InputField/InputField';

import styles from './MessageList.module.scss';

const userSelector = (state) => state.user;
const chatroomsSelector = (state) => state.chatrooms;
const messageTextSelector = (state) => [state.isWritten, state.setIsWritten];
const isClosedSelector = (state) => state.groupChatIsClosed;
const userIsBlockedSelector = (state) => state.userIsBlocked;
const lastUpdatedSelector = (state) => state.lastUpdated;
const assetsSelector = (state) => state.assets;

function MessageList({ currentRoomId, isPrivate }) {
  const groupChatIsClosed = useChatTextStore(isClosedSelector);
  const chatUser = useUserStore(userSelector);
  const chatrooms = useChatStore(chatroomsSelector);
  const currentRoomIndex = chatrooms.findIndex(
    (room) => room.roomId === currentRoomId
  );
  const [chatIsClosed, setChatIsClosed] = useState(false);
  const userIsBlocked = useChatTextStore(userIsBlockedSelector);
  const lastUpdated = useChatStore(lastUpdatedSelector);
  const assets = bingoAssetsStore(assetsSelector);

  const [isAdminChat, setIsAdminChat] = useState(
    chatrooms[currentRoomIndex].isAdminChat
  );

  const inputRef = useRef();
  const listRef = useRef();
  const scrollRef = useRef();

  const [messages, setMessages] = useState(
    chatrooms[currentRoomIndex].messages
  );
  const [messageText, setMessageText] = useChatTextStore(
    messageTextSelector,
    shallow
  );

  useEffect(() => {
    setMessages(chatrooms[currentRoomIndex].messages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated]);

  const onChange = (e) => {
    if (messageText.length >= 250) return;
    setMessageText(e.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!messageText) return;

    ChatManager.instance.sendMessage(messageText, currentRoomId, isPrivate);
    setMessageText('');
    inputRef.current.focus();
  };

  useEffect(() => {
    if (listRef.current && scrollRef.current && messages.length > 0) {
      scrollRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages.length]);

  // Update message history, when change of room.
  useUpdateEffect(() => {
    const newRoomIndex = chatrooms.findIndex(
      (room) => room.roomId === currentRoomId
    );
    setMessages(chatrooms[newRoomIndex].messages);
    setMessageText('');
    setIsAdminChat(chatrooms[newRoomIndex].isAdminChat);
    if (groupChatIsClosed && !chatrooms[newRoomIndex].isPrivate) {
      setChatIsClosed(true);
    } else {
      setChatIsClosed(false);
    }
  }, [currentRoomId]);

  useEffect(() => {
    if (groupChatIsClosed && !chatrooms[currentRoomIndex].isPrivate) {
      setMessageText('');
      setChatIsClosed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupChatIsClosed]);

  return (
    <div className={styles.wrapper}>
      <Scrollbars className={styles.scroll} ref={scrollRef}>
        <div className={styles.list} ref={listRef}>
          {messages.map(
            ({
              sent,
              message,
              userName,
              nickname,
              avatarId,
              isChatHost,
              id,
              isBlackListed,
            }) => {
              const isMine =
                chatUser.nickname === userName ||
                chatUser.nickname === nickname;

              return (
                <div
                  key={sent || id}
                  className={clsx(styles.message, {
                    [styles.isBlacklistedMessage]: isBlackListed,
                    [styles.isAdmin]: isChatHost,
                    [styles.isMine]: isMine,
                    [styles.isPrivate]: isPrivate && isMine,
                    [styles.isPrivateAdminChat]:
                      isAdminChat && isPrivate && isMine,
                  })}
                >
                  {isChatHost && assets[userName] ? (
                    <div
                      className={clsx(styles.chathostImgWrapper, styles.avatar)}
                    >
                      <img src={assets[userName]} alt="" />
                    </div>
                  ) : (
                    <Avatar avatar={avatarId} className={styles.avatar} />
                  )}
                  <p className={styles.text}>
                    {!isBlackListed && <b>{`${userName || nickname}: `}</b>}
                    {message}
                  </p>
                </div>
              );
            }
          )}
        </div>
      </Scrollbars>

      <InputField
        key={chatIsClosed}
        ref={inputRef}
        value={messageText}
        isDisabled={!chatUser.isAuthenticated || userIsBlocked}
        anonymousUserFunc={
          chatUser.callback_login &&
          typeof chatUser.callback_login === 'function' &&
          !chatUser.isAuthenticated
            ? chatUser.callback_login
            : null
        }
        onChange={onChange}
        onSubmit={onSubmit}
        chatIsClosed={chatIsClosed}
      />
    </div>
  );
}

MessageList.propTypes = {
  currentRoomId: string.isRequired,
  isPrivate: bool.isRequired,
};

export default MessageList;
