import React from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';

import useNumbersDrawnStore from 'stores/numbers-drawn';
import useLayoutStore from 'stores/layout';

import TotalBallsDrawn from 'components/GameInfoPanel/TotalBallsDrawn/TotalBallsDrawn';
import BingoBall from 'components/BingoBall/BingoBall';

import styles from './LastDrawnBalls.module.scss';

const numbersDrawnSelector = (state) => [state.numbers];
const isLongTicketsSelector = (state) => state.isLongTickets;

function LastDrawnBalls({ isVertical }) {
  const [numbersDrawn] = useNumbersDrawnStore(numbersDrawnSelector);
  const isLongsTickets = useLayoutStore(isLongTicketsSelector);
  const hasNumbers = numbersDrawn && numbersDrawn.length > 0;
  const numbersDrawnAsc = [...numbersDrawn].reverse();

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isVertical]: isVertical,
        [styles.isLongsTickets]: isLongsTickets,
      })}
    >
      <div className={styles.drawnBallsWrapper}>
        {hasNumbers &&
          numbersDrawnAsc.map(
            (number, i) =>
              i < 5 && (
                <BingoBall
                  key={`last-drawn-balls-number-${number}`}
                  number={number}
                  currentIndex={i}
                  willAnimate
                  isVertical={isVertical}
                  isLastBall={i === 0}
                />
              )
          )}
      </div>
      <div
        className={clsx(styles.totalBallsWrapper, {
          [styles.isVertical]: isVertical,
        })}
      >
        <TotalBallsDrawn
          currentNumber={numbersDrawnAsc.length}
          isWhite={isVertical}
        />
      </div>
    </div>
  );
}

LastDrawnBalls.propTypes = {
  isVertical: bool,
};

LastDrawnBalls.defaultProps = {
  isVertical: false,
};

export default LastDrawnBalls;
