import React from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';

import styles from './Header.module.scss';

function Header({ isHistory }) {
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isHistory]: isHistory,
      })}
    >
      <p className={styles.circle}>
        <span className={styles.text}>B</span>
      </p>
      <p className={styles.circle}>
        <span className={styles.text}>I</span>
      </p>
      <p className={styles.circle}>
        <span className={styles.text}>N</span>
      </p>
      <p className={styles.circle}>
        <span className={styles.text}>G</span>
      </p>
      <p className={styles.circle}>
        <span className={styles.text}>O</span>
      </p>
    </div>
  );
}

Header.propTypes = {
  isHistory: bool,
};

Header.defaultProps = {
  isHistory: false,
};

export default Header;
