import React from 'react';
import clsx from 'clsx';
import { arrayOf, shape, string, number, object, bool } from 'prop-types';

import useMenuStore from 'stores/menu';
import useGameStore, { ACTIVE_GAME } from 'stores/game';
import useBreakpoint, { TABLET_LANDSCAPE } from 'hooks/useBreakpoint';
import useLayoutStore from 'stores/layout';

import DragScroll from 'components/DragScroll/DragScroll';
import BingoCard from 'components/BingoCard/BingoCard';
import LastDrawnBalls from 'components/GameInfoPanel/LastDrawnBalls/LastDrawnBalls';
import ListOfPrizes from 'components/GameInfoPanel/ListOfPrizes/ListOfPrizes';

import styles from './LayoutDefaultSmall.module.scss';

const gameStateSelector = (state) => state.gameState;
const menuExpandedSelector = (state) => state.isMenuExpanded;

function LayoutDefaultSmall({ items, layoutId, isTinyTickets }) {
  const [firstCard, secondCard, ...restCards] = items;
  const gameState = useGameStore(gameStateSelector);
  const is4x4Tickets = useLayoutStore((state) => state.is4x4Tickets);
  const breakpoint = useBreakpoint();
  const isMenuExpanded = useMenuStore(menuExpandedSelector);

  return (
    <>
      <div
        className={clsx(styles.miniCard, {
          [styles.isVisible]: layoutId === 0 && gameState === ACTIVE_GAME,
        })}
      >
        {firstCard && (
          <span className={styles.card}>
            <BingoCard
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...firstCard}
              hasFooter
            />
          </span>
        )}
        <LastDrawnBalls isVertical />
        <div className={styles.prizes}>
          <ListOfPrizes isVertical />
        </div>
      </div>
      <DragScroll
        classNameScrollbar={clsx(styles.scrollbar, {
          [styles.isHidden]: layoutId === 0 && gameState === ACTIVE_GAME,
        })}
        forceUpdate={layoutId}
      >
        <div
          className={clsx(styles.wrapper, {
            [styles.isHidden]: layoutId === 0 && gameState === ACTIVE_GAME,
            [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
            [styles.isOneTicket]: !secondCard,
            [styles.isMenuExpanded]: isMenuExpanded,
            [styles.isActiveGame]: gameState === ACTIVE_GAME,
            [styles.isGrid]: layoutId === 2,
            [styles.isTinyTickets]: isTinyTickets,
            [styles.is4x4Tickets]: is4x4Tickets,
          })}
        >
          <div className={styles.firstRow}>
            {firstCard && (
              <div key={firstCard.id} className={styles.item}>
                <BingoCard
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...firstCard}
                  isLargeTinyTicket
                  hasFooter
                />
              </div>
            )}
            {secondCard && (
              <div key={secondCard.id} className={styles.item}>
                <BingoCard
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...secondCard}
                  isLargeTinyTicket
                  hasFooter
                />
              </div>
            )}
          </div>
          <div
            className={clsx(styles.row, {
              [styles.isGrid]: layoutId === 2,
              [styles.isList]: layoutId === 1,
            })}
          >
            {restCards.map((card) => (
              <div key={card.id} className={styles.item}>
                <BingoCard
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...card}
                  isSmallTinyTicket={layoutId === 2}
                  isLargeTinyTicket={layoutId === 1}
                  hasFooter
                />
              </div>
            ))}
          </div>
        </div>
      </DragScroll>
    </>
  );
}

LayoutDefaultSmall.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      numbersMap: arrayOf(arrayOf(object)),
    })
  ),
  layoutId: number,
  isTinyTickets: bool,
};

LayoutDefaultSmall.defaultProps = {
  items: [],
  layoutId: 1,
  isTinyTickets: false,
};

export default LayoutDefaultSmall;
