import './publicPath';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import 'utils/innerHeightVariable';

import App from 'components/App/App';
import ThemeApp from 'components/App/Theme';

require('styles/index.scss');

const viewThemeVariables = false;
const displayApp = viewThemeVariables ? ThemeApp : App;

const renderHotHash = (Component, element, appData) => {
  const root = createRoot(element);
  root.render(<Component appData={appData} />);
};

const initApp = (appData, element) => {
  renderHotHash(displayApp, element, appData);
};

export default {
  init(data) {
    initApp(data, document.getElementById(data.elementId));
  },
};
