import React from 'react';
import { arrayOf, func, shape, string, bool, number } from 'prop-types';
import { useTransition, animated } from 'react-spring';

import Avatar from 'components/Chat/Avatar/Avatar';
import Scrollbars from 'components/Scrollbars/Scrollbars';

import styles from './UserList.module.scss';

function UserList({ users, isOpen, onClick }) {
  const usersTransition = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
  });

  return usersTransition.map(
    ({ item, key, props }) =>
      item && (
        <animated.div className={styles.wrapper} key={key} style={props}>
          <Scrollbars className={styles.scroll}>
            {users
              .filter(
                // Filter unique nicknames, duplicates might occur due to several connections
                (user, index, self) =>
                  index === self.findIndex((t) => t.nickname === user.nickname)
              )
              .sort((a, b) => a.nickname.localeCompare(b.nickname))
              .map(({ nickname, avatarId }) => (
                <button
                  key={nickname}
                  type="button"
                  className={styles.item}
                  onClick={() => onClick(nickname)}
                >
                  <Avatar avatar={avatarId} className={styles.avatar} />
                  <span className={styles.name}>{nickname}</span>
                </button>
              ))}
          </Scrollbars>
        </animated.div>
      )
  );
}

UserList.propTypes = {
  users: arrayOf(
    shape({
      nickname: string,
      avatarId: number,
    })
  ),
  isOpen: bool,
  onClick: func,
};

UserList.defaultProps = {
  users: [],
  isOpen: false,
  onClick: () => {},
};

export default UserList;
