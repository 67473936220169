const nb = {
  topPanel: {
    listItem1: 'Spilleregler og premieplan',
    listItem2: 'Spill ansvarlig',
    listItem3: 'Chat',
    listItem4: 'Innstillinger',
    listItem5: 'Se guide',
    listItem6: 'Bingo-lobby',
    soundOffText: 'LYD AV',
    soundOnText: 'LYD PÅ',
    guideButton: 'Se guide',
    responsibilitiesButton: 'Spillansvar',
    loginButton: 'Logg inn for å spille',
  },
  gameWallet: {
    walletText: 'Lommebok',
    buttonText: 'Innskudd',
  },
  gameBanner: {
    largeBannerTitlePrebuy: 'Tid til neste spill',
    largeBannerTitleBuy: 'Tid til start',
    smallBannerTitle: 'Neste bingospill',
  },
  gameInfo: {
    price: 'Pris',
    numberOfPlayersText: 'Antall spillere',
    numberOfPlayers: '%{amount}',
    totalPott: 'Total pott',
    jackpott: 'JACKPOT',
    withinText: 'innenfor',
    withinBalls: '%{amount} baller',
  },
  settings: {
    heading: 'Innstillinger',
    autosort: 'Automatisk sortering',
    autosortExplanation:
      'Automatisk sortering sørger for at det beste bingokortet vises først.',
    automark: 'Automatisk merking',
    automarkExplanation:
      'Bruk automatisk merking hvis du ikke vil markere bingokortene manuelt. (Ikke bekymre deg, selv når du markerer selv, korrigerer vi kortet).',
    symbols: 'Markeringsymboler',
    colors: 'Farger',
    sound: 'Lyd',
    announcer: 'Oppleser',
    effects: 'Effekter',
    music: 'Musikk',
    profileimage: 'Profilbilde',
  },
  buyLotteryTicketButtons: {
    heading: 'Kjøp kort',
    minusButton: '-',
    plusButton: '+',
    predefinedButtons: '%{amount} stk',
    maxButton: 'Max',
    buyButton: 'Kjøp for neste',
    payButton: 'Betal',
    amount: '€%{amount}',
    totalAmount: 'Totalt €%{amount}',
  },
  prepurchasedTickets: {
    oneTicket: '%{amount} kjøpt kort for neste spill',
    moreTickets: '%{amount} kjøpte kort for neste spill',
  },
  leaderboard: {
    heading: 'Ledertavle',
  },
  chat: {
    bingoRoomsChathostOnlineText: 'Chatvert online:\n %{name}',
    bingoRoomsButtonOpen: 'Vis alle rom',
    bingoRoomsButtonClose: 'Vis chat',
    isClosedMessage: 'Chatten er stengt',
    loginMessage: 'Logg inn for å chatte',
    toggleButton: 'Åpne / lukke chat',
    closeButton: 'Lukk chat',
    admin: 'Chatvert:',
    private: 'Privat chat:',
    modal: {
      startPrivateChat: {
        title: 'Start privat chat',
        text: 'Vil du starte en privat chat med',
        secondaryText:
          'Du kan ikke ha flere faner åpne. Lukk en chat for å kunne starte en ny.',
        cancelButton: 'Avbryt',
        confirmButton: 'Bekreft',
        okButton: 'Ok',
      },
      privateChatRequest: {
        title: 'Invitasjon til privat chat',
        subTitle: 'Vil starte en privat chat med deg',
        secondarySubtitle:
          'Du kan ikke ha flere faner åpne. Lukk en chat for å kunne åpne en ny.',
        cancelButton: 'Avslå',
        confirmButton: 'Aksepter',
        okButton: 'Ok',
      },
    },
  },
  bingoCard: {
    demo: 'Demo',
    purchase: {
      selectButton: 'Velg kort',
      isSelected: 'Valgt kort',
      isPurchased: 'Betalt kort',
    },
    numbersLeft: '%{numbers} igjen til bingo!',
    bingo: 'Bingo!',
  },
  prizeplan: {
    title: '%{name} premieplan',
    priceText: 'Pris',
    numberOfPlayersText: 'Antall spillere',
    totalPottText: 'Total pott',
    withinBallsText: 'Antall baller',
    header1: 'Premienivå',
    header2: 'Mønster',
    header3: 'Premieandel',
    header4: 'Premie',
    jackpotFullText: 'JACKPOT innen %{number} baller',
    jackpotText1: 'JACKPOT',
    jackpotText2: 'innen %{number} baller',
    subtitle: 'Spilleregler',
  },
  gamingResponsibility: {
    heading1: 'Vårt arbeid med spillansvar',
    preamble:
      'For mye av det gode er aldri bra – og dette gjelder ikke minst for spill. Drømmen om den store gevinsten som vil endre livet vårt finnes hos de aller fleste av oss og er en viktig drivkraft i spill.',
    content1:
      'Vårt mål hos Random State er å være i forkant når det gjelder spillansvar. Arbeidet med spillansvar reduserer risikoen for at spillere utvikler spillproblemer slik at spill kan fortsette å være moro.',
    heading2: 'Aldersgrense',
    content2:
      'Du må være 18 år for å kunne spille våre spill. Forskning viser at jo tidligere du begynner å spille, desto større er risikoen for å ende opp i usunt spill.',
  },
  winnerListModal: {
    title: 'Spill over',
    table: {
      header1: 'Premienivå',
      header2: 'Innen',
      header3: 'Premie',
      header4: 'Vinner',
    },
    closeButton: 'Lukk',
  },
  prizeNotification: {
    bingo: {
      title: 'Gratulerer med gevinsten!',
    },
    jackpot: {
      title: 'Gratulerer med jackpoten!',
    },
  },
  purchaseModal: {
    purchase: {
      title: 'Kjøpsbekreftelse',
      bingoCardPrice: 'Kortpris',
      bingoCardsAmount: 'Antall kort',
      total: 'Totalt',
      countAppendix: '%{total} stk',
      currencyAppendix: '%{amount}',
      vouchersPayForAllTickets:
        'Kjøpet er gratis da det gjelder spill som du har kuponger for.',
      vouchersPayForSomeTickets:
        '%{numberOfVouchers} av kortene betales med kuponger, resten trekkes fra din spillelommebok.',
      confirmButton: 'Bekreft',
      cancelButton: 'Avbryt',
    },
    receipt: {
      title: 'Kvittering',
      goodLuck: 'Lykke til %{name}!',
      returnButton: 'Gå til mine kort',
    },
    incuffientFunds: {
      title: 'Tomt for midler',
      firstText: 'Dessverre har du ikke nok penger i spill lommeboken din.',
      secondText: 'Topp opp så du kan fortsette',
      button: 'Innskudd',
    },
    noGameLimitsSet: {
      title: 'Kjøpet kan ikke fullføres',
      text: 'Du må sette en innskuddsgrense før du kan spille.',
      closeModalButtonText: 'OK',
    },
    errorFetchingGameLimits: {
      title: 'Ukjent feil',
      text: 'Prøv igjen.',
    },
  },
  gameErrorModal: {
    tooFewPlayers: {
      title: 'Ikke nok spillere',
      content1:
        'Dette bingospillet har for få spillere til å starte. Hvis du har kjøpt kort, vil pengene dine bli refundert.',
      content2: 'Neste bingerunde er nå åpen for kjøp.',
      nextButton: 'Gå til neste bingo',
      backButton: 'Tilbake',
    },
  },
  apiErrorModal: {
    title: 'Noe gikk galt!',
    titleWhenBuying: 'Kunne ikke kjøpe kort!',
    content0: '',
    content1:
      'Et problem oppstod ved henting av brukerdata, prøv å logge ut og inn igjen for å prøve på nytt.',
    content2:
      'Vi klarte ikke å oppdatere saldoen din, last siden på nytt for riktig verdi.',
    content3: 'Vi klarte ikke å hente innstillingene dine.',
    content4: 'Vi klarte ikke å lagre innstillingene dine.',
    content5: 'Ingen kort kunne hentes, prøv igjen.',
    content6:
      'Et problem oppstod ved henting av kort, last siden på nytt for å prøve igjen.',
    content7:
      'Et problem oppstod med tilkoblingen, vi prøver å koble til på nytt.',
    content8:
      'Gjenkobling mislyktes. Last siden på nytt for å koble til igjen.',
    content9: 'Gjenkobling vellykket!',
    content10: 'Et problem oppstod under kjøpet, prøv igjen.',
    content11: 'Du må logge inn for å se reprisen av kortet.',
    content12: 'Du må sette et chatnavn for å kunne spille.',
    content13: 'Du har overskredet din tapsgrense.',
    content14: 'Du har overskredet din tidsbegrensning.',
    content15: 'Du har aktivert en spillepause.',
    content16: 'Du har blitt utestengt fra bingospill.',
    content17: 'Bingorunden har blitt stengt for salg.',
    closeButtonText: 'OK',
  },
  bingoRooms: {
    header: 'Neste spill i våre bingorom:',
    jackpotWithSum: 'Jackpot: %{value}',
    jackpot: 'Jackpot:',
    nextGameText: 'Neste spill:',
    buttonText: 'Til spillet',
  },
  tutorial: {
    greetingHeading: 'Hei, hyggelig å se deg her!',
    greetingParagraph:
      'Vil du ha en omvisning i vår nye bingo? Du kan alltid velge å se den senere.',
    yesButton: 'Ja',
    noButton: 'Nei',
    nextStepButton: 'Neste steg',
    lastStepButton: 'Lukk guide',
    step1Text: 'Her setter du inn penger for å begynne å spille',
    step2Text:
      'Hvordan vil du ha din bingo? Her velger du om du vil merke selv, hvilket merkesymbol du vil ha, farge og profilbilde.',
    step3Text: 'Her kan du slå av og på lyden',
    step4Text: 'Her kan du finne informasjon om pågående spill',
    step5ViewText: 'Her kan du endre visningen på bingokortene',
    step5ChatText: 'Her kan du utvide chatten',
  },
  bonusModal: {
    purchaseConfirmation: {
      firstParagraph1: 'Du vil ha',
      firstParagraph2: 'oppnådd',
      firstParagraph3: 'din bonus!',
      firstParagraph4: 'igjen til bonus!',
      bonusPayoutText: 'Bonus!',
      voucherText: '%{amount} gratis spill!',
    },
    receipt: {
      firstParagraph1: 'Du har',
      firstParagraph2: 'oppnådd',
      firstParagraph3: 'din bonus!',
      firstParagraph4: 'igjen til bonus!',
      moneyPayout: '%{amount} er satt inn i din spill lommebok.',
      voucherPayout: 'Dine gratis spill er utbetalt.',
      percentPayout: 'Din bonus er utbetalt!',
    },
    purchaseView: {
      purchaseView: 'igjen til bonus!',
      bonusPayoutText: 'Bonus!',
      voucherText: '%{amount} gratis spill!',
    },
  },
  potbooster: {
    header: 'POTTFORSTERKER',
    totalPot: 'Total pott:',
  },
  currencyFormat: 'EUR',
  currencyLocale: 'no-NO',
  doNotSupportLandscape: 'Roter enheten din for å spille bingo',
  doNotSupportIE: 'Klienten støtter ikke denne nettleseren.',
  isOtherActiveSession:
    'Du kan bare være tilkoblet ett bingorom om gangen. Lukk ned din andre økt og prøv igjen',
  isOtherActiveSessionButton: 'Prøv igjen',
  blacklistedWordMessage: 'Meldingen ble ikke sendt på grunn av blokkerte ord',
};

export default nb;
