import { text } from 'polyglot/polyglot';
import React, { useEffect, useState, useRef } from 'react';
import api from 'utils/api';
import useUserStore from 'stores/user';

import Spinner from 'components/Spinner/Spinner';
import BingoRoom from './BingoRoom/BingoRoom';

import styles from './BingoRooms.module.scss';

const bingoRoomLinksSelector = (state) => state.user.bingo_room_links;
const userRoomSelector = (state) => state.user.room;

function BingoRooms() {
  const [rooms, setRooms] = useState([]);
  const bingoRoomLinks = useUserStore(bingoRoomLinksSelector);
  const userRoom = useUserStore(userRoomSelector);
  const isMounted = useRef(true);

  const fetchData = async () => {
    try {
      const bingoRooms = await api
        .get('/api/Bingo/rooms')
        .then((roomRes) => roomRes)
        .catch((err) => {
          console.log('error on getting rooms: ', err);
        });
      if (bingoRooms && isMounted.current) {
        const newRooms = [];
        bingoRooms.forEach((br) => {
          const linkObj = bingoRoomLinks
            ? bingoRoomLinks.find((o) => o.id === br.id)
            : null;
          newRooms.push({
            ...br,
            url: linkObj ? linkObj.url : '/',
          });
        });
        setRooms(newRooms);
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!rooms.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms]);

  return (
    <div className={styles.wrapper}>
      {rooms.length > 0 && (
        <p className={styles.header}>
          {rooms.length === 1
            ? text.t('bingoRooms.nextGameText')
            : text.t('bingoRooms.header')}
        </p>
      )}
      {rooms.length > 0 ? (
        <>
          {rooms.map((room) => (
            <BingoRoom
              key={room.id}
              room={room}
              callbackUpdateRooms={fetchData}
              isOnlyOneRoom={rooms.length === 1}
              isCurrentRoom={Number(userRoom) === Number(room.id)}
            />
          ))}
        </>
      ) : (
        <Spinner isMedium className={styles.loader} />
      )}
    </div>
  );
}

export default BingoRooms;
