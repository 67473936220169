import React, { useState, useEffect } from 'react';
import { bool, func, number, string, shape } from 'prop-types';
import clsx from 'clsx';
import api from 'utils/api';

import { text } from 'polyglot/polyglot';
import { formatCurrency } from 'utils/currency';

import Spinner from 'components/Spinner/Spinner';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import BonusDisplay from '../BonusDisplay/BonusDisplay';

import styles from './ConfirmPurchase.module.scss';

function ConfirmPurchase({
  isLoading,
  onClickConfirm,
  onClose,
  cardPrice,
  cardsCount,
  totalPrice,
  bingoName,
  displayBonus,
  bingoGameId,
  sumNotIncludedInBonus,
  setSumNotIncludedInBonus,
}) {
  const [userCanBuyData, setUserCanBuyData] = useState(null);

  const fetchUserCanBuyTicket = async () => {
    await api
      .get(
        `/api/Customer/CanBuyTickets?gameBaseId=${bingoGameId}&cost=${totalPrice}&isBingo=true`
      )
      .then((res) => {
        let sumToSubtractFromBonus = 0;
        setUserCanBuyData(res);
        sumToSubtractFromBonus += res.voucherCount * cardPrice;
        if (displayBonus?.qualification?.includeBonusMoney === false) {
          sumToSubtractFromBonus += res.bonusValue;
        }
        setSumNotIncludedInBonus(sumToSubtractFromBonus);
      })
      .catch((err) => {
        console.log('error on getting bonus information: ', err);
        setUserCanBuyData({});
      });
  };

  useEffect(() => {
    fetchUserCanBuyTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userCanBuyData) {
    return (
      <Modal
        isLoading={isLoading}
        content={
          <div className={styles.spinnerModal}>
            <Spinner />
          </div>
        }
      />
    );
  }

  return (
    <Modal
      isLoading={isLoading}
      onClickOutside={onClose}
      title={text.t('purchaseModal.purchase.title')}
      secondaryTitle={bingoName}
      content={
        <div className={styles.table}>
          <div className={styles.row}>
            <p className={styles.th}>
              {text.t('purchaseModal.purchase.bingoCardPrice')}:
            </p>
            <p className={styles.td}>
              {text.t('purchaseModal.purchase.currencyAppendix', {
                amount: formatCurrency(cardPrice),
              })}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.th}>
              {text.t('purchaseModal.purchase.bingoCardsAmount')}:
            </p>
            <p className={styles.td}>
              {text.t('purchaseModal.purchase.countAppendix', {
                total: cardsCount,
              })}
            </p>
          </div>
          <div
            className={clsx(styles.row, {
              [styles.lastElement]:
                userCanBuyData && userCanBuyData?.voucherCount === 0,
            })}
          >
            <p className={styles.th}>
              {text.t('purchaseModal.purchase.total')}:
            </p>
            <p className={styles.td}>
              {text.t('purchaseModal.purchase.currencyAppendix', {
                amount: formatCurrency(totalPrice),
              })}
            </p>
          </div>
          {userCanBuyData && userCanBuyData?.voucherCount > 0 && (
            <div className={clsx(styles.row, styles.lastElement)}>
              {userCanBuyData.voucherCount < cardsCount ? (
                <p className={styles.voucherText}>
                  {text.t('purchaseModal.purchase.vouchersPayForSomeTickets', {
                    numberOfVouchers: userCanBuyData.voucherCount,
                  })}
                </p>
              ) : (
                <p className={styles.voucherText}>
                  {text.t('purchaseModal.purchase.vouchersPayForAllTickets', {
                    amount: cardsCount,
                    numberOfVouchers: userCanBuyData.voucherCount,
                  })}
                </p>
              )}
            </div>
          )}
          {displayBonus && sumNotIncludedInBonus !== null && (
            <BonusDisplay
              displayBonus={displayBonus}
              purchaseTotal={totalPrice}
              onClose={onClose}
              onClickConfirm={onClickConfirm}
              isDisabled={!cardsCount}
              sumNotIncludedInBonus={sumNotIncludedInBonus}
            />
          )}
        </div>
      }
      buttons={
        displayBonus
          ? []
          : [
              <Button
                key="cancel-button"
                className={styles.button}
                onClick={onClose}
                isSecondary
              >
                {text.t('purchaseModal.purchase.cancelButton')}
              </Button>,
              <Button
                key="confirm-button"
                className={styles.button}
                onClick={onClickConfirm}
                isDisabled={!cardsCount}
              >
                {text.t('purchaseModal.purchase.confirmButton')}
              </Button>,
            ]
      }
    />
  );
}

ConfirmPurchase.propTypes = {
  isLoading: bool,
  onClickConfirm: func,
  onClose: func,
  cardPrice: number.isRequired,
  cardsCount: number.isRequired,
  totalPrice: number.isRequired,
  bingoName: string.isRequired,
  displayBonus: shape(),
  bingoGameId: number.isRequired,
  sumNotIncludedInBonus: number,
  setSumNotIncludedInBonus: func.isRequired,
};

ConfirmPurchase.defaultProps = {
  isLoading: false,
  onClickConfirm: () => {},
  onClose: () => {},
  displayBonus: null,
  sumNotIncludedInBonus: null,
};

export default ConfirmPurchase;
