import { create } from 'zustand';

export const ACTIVE_GAME = 'ACTIVE_GAME';
export const GAME_REPLAY = 'GAME_REPLAY';
export const PURCHASE_VIEW = 'PURCHASE_VIEW';

export const NONE = 'NONE';
export const BINGO = 'BINGO';
export const JACKPOT = 'JACKPOT';
export const MULTI_JACKPOT = 'MULTI_JACKPOT';
export const POT_BOOSTER = 'POT_BOOSTER';

const useGameStore = create((set) => ({
  gameState: PURCHASE_VIEW,
  prizeNotification: NONE,
  potBoosterNotification: NONE,
  gameStartVO: false,
  setGameStartVO: (value) => set(() => ({ gameStartVO: value })),
  setGameState: (value) => set(() => ({ gameState: value })),
  setPrizeNotification: (value) => set(() => ({ prizeNotification: value })),
  resetPrizeNotification: () => set(() => ({ prizeNotification: NONE })),
  setPotBoosterNotification: (value) =>
    set(() => ({ potBoosterNotification: value })),
  resetPotBoosterNotification: () =>
    set(() => ({ potBoosterNotification: NONE })),
}));

export default useGameStore;
