/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { number, func } from 'prop-types';
import clsx from 'clsx';
import { getIconsList } from 'utils/getUserProfileImg';

import styles from './ProfileIcons.module.scss';

const icons = getIconsList();

function ProfileIcons({ selectedIcon, onSelectNewIcon }) {
  return (
    <div className={styles.wrapper}>
      {icons.map((icon, index) => (
        <div
          key={`user-profile-${index}`}
          className={clsx(styles.iconWrapper, {
            [styles.active]:
              index === selectedIcon ||
              (index === icons.length - 1 && selectedIcon === -1),
          })}
        >
          <img
            className={styles.icon}
            src={icon}
            alt="profile icon"
            onClick={() => onSelectNewIcon('userAvatar', index)}
          />
        </div>
      ))}
    </div>
  );
}
ProfileIcons.propTypes = {
  selectedIcon: number.isRequired,
  onSelectNewIcon: func.isRequired,
};

export default ProfileIcons;
