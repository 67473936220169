/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { bool, func, shape } from 'prop-types';
import { shallow } from 'zustand/shallow';
import clsx from 'clsx';

import { text } from 'polyglot/polyglot';
import api from 'utils/api';
import { parseBingoCardsLayouts, validateTicketFormat } from 'utils/helpers';
import { formatCurrency } from 'utils/currency';

import useBreakpoint, { DESKTOP, TABLET_LANDSCAPE } from 'hooks/useBreakpoint';
import useGameStore, { ACTIVE_GAME, PURCHASE_VIEW } from 'stores/game';
import { useChatStore } from 'stores/chat';
import usePurchaseStore from 'stores/purchase';
import useUserStore from 'stores/user';
import useCurrentGameStore from 'stores/current-game';
import useUpcomingGameStore from 'stores/upcoming-game';
import useApiErrorStore from 'stores/api-error';
import useLayoutStore from 'stores/layout';
import tutorialStore from 'stores/tutorial';

import UpcomingGameBanner from 'components/UpcomingGameBanner/UpcomingGameBanner';
import Button from 'components/Button/Button';
import GameWallet from 'components/GameWallet/GameWallet';
import ListOfPrizes from 'components/GameInfoPanel/ListOfPrizes/ListOfPrizes';

import ChatIcon from 'assets/svg/chat-icon.svg';
import Cross from 'assets/svg/cross.svg';

import BuyLotteryTicketButtons from './BuyLotteryTicketButtons/BuyLotteryTicketButtons';
import BonusNotification from '../BonusNotification/BonusNotification';

import styles from './PurchaseLayout.module.scss';

const chatIconPositionSelector = (state) => state.setChatIconPosition;

const setIsChatExpandedSelector = (state) => state.setIsChatExpanded;
const gameStateSelector = (state) => state.gameState;

const isLoadingSelector = (state) => [state.isLoading, state.setIsLoading];
const bingoCardsListSelector = (state) => [
  state.bingoCardsList,
  state.setBingoCardsList,
];
const userSelector = (state) => [state.user];
const selectedCardIdsSelector = (state) => [
  state.selectedCardIds,
  state.resetSelectedCardIds,
];
const currentGameSelector = (state) => state.currentGame;
const prebuyTicketAmountSelector = (state) => state.prebuyTicketAmount;
const upcomingGameSelector = (state) => state.nextGame;
const apiErrorSelector = (state) => state.setErrorMessage;
const isSmallTicketsSelector = (state) => [
  state.isSmallTickets,
  state.setIsSmallTickets,
];
const isLongTicketsSelector = (state) => [
  state.isLongTickets,
  state.setIsLongTickets,
];
const setIs4x4TicketsSelector = (state) => state.setIs4x4Tickets;
const numberOfTicketsCurrentGameSelector = (state) =>
  state.updateTicketsCurrentGame;
const fetchNewCardsSelector = (state) => [
  state.fetchNewCards,
  state.setFetchNewCards,
];

function PurchaseLotteryTicketsPanel({
  setIsPurchaseDuringGame,
  isPurchaseDuringGame,
  setHasPurchaseModal,
  displayBonus,
}) {
  const mountedRef = useRef(true);

  const setChatIconPosition = tutorialStore(chatIconPositionSelector);
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === DESKTOP;
  const chatReference = useRef(0);

  const setIsChatExpanded = useChatStore(setIsChatExpandedSelector);
  const [isLoading, setIsLoading] = usePurchaseStore(
    isLoadingSelector,
    shallow
  );
  const [bingoCardsList, setBingoCardsList] = usePurchaseStore(
    bingoCardsListSelector,
    shallow
  );
  const [user] = useUserStore(userSelector);
  const [selectedTicketIds, resetSelectedCardIds] = usePurchaseStore(
    selectedCardIdsSelector,
    shallow
  );
  const currentGame = useCurrentGameStore(currentGameSelector);
  const nextGame = useUpcomingGameStore(upcomingGameSelector);
  const prebuyTicketAmount = usePurchaseStore(prebuyTicketAmountSelector);
  const setApiErrorMessage = useApiErrorStore(apiErrorSelector);
  const [isSmallTickets, setIsSmallTickets] = useLayoutStore(
    isSmallTicketsSelector,
    shallow
  );
  const [isLongTickets, setIsLongTickets] = useLayoutStore(
    isLongTicketsSelector,
    shallow
  );
  const setIs4x4Tickets = useLayoutStore(setIs4x4TicketsSelector);
  const updateTicketsCurrentGame = usePurchaseStore(
    numberOfTicketsCurrentGameSelector
  );
  const gameState = useGameStore(gameStateSelector);
  const [fetchNewCards, setFetchNewCards] = usePurchaseStore(
    fetchNewCardsSelector,
    shallow
  );

  const onClickChatButton = () => setIsChatExpanded(true);
  const onClickCloseButton = () => setIsPurchaseDuringGame(false);
  const bingoRoundIdRef = useRef();
  const isPurchaseView = gameState === PURCHASE_VIEW;

  const onClickBuy = () => {
    if (!user.isAuthenticated) {
      if (user.callback_login && typeof user.callback_login === 'function') {
        user.callback_login();
      }
      return;
    }
    setHasPurchaseModal(true);
  };

  const checkIfTicketIsSmall = (ticket) => {
    if (ticket.length === 5 && !isSmallTickets) {
      setIsSmallTickets(true);
    }
    if (ticket.length !== 5 && isSmallTickets) {
      setIsSmallTickets(false);
    }
  };

  const checkIfTicketIsLong = (ticketRow) => {
    if (ticketRow.length === 9 && !isLongTickets) {
      setIsLongTickets(true);
    }
    if (ticketRow.length < 9 && isLongTickets) {
      setIsLongTickets(false);
    }
  };

  const checkIfTicketIs4x4 = (ticket) => {
    const is4x4 = validateTicketFormat(ticket, 4, 4);
    setIs4x4Tickets(is4x4);
  };

  const checkTicketLayout = (ticket) => {
    checkIfTicketIsSmall(ticket.numbersMap);
    checkIfTicketIsLong(ticket.numbersMap[0]);
    checkIfTicketIs4x4(ticket);
  };

  const loadBingoCards = async () => {
    if (gameState === ACTIVE_GAME) return;
    if (
      !bingoCardsList.length ||
      currentGame.bingoRoundId !== bingoRoundIdRef.current
    ) {
      if (!isLoading && currentGame.bingoRoundId) {
        setIsLoading(true);
        if (bingoCardsList.length) setBingoCardsList([]);
        await api
          .get(`/api/Bingo/cards/${currentGame.bingoRoundId}`)
          .then((res) => {
            setIsLoading(false);
            if (mountedRef.current && res.length) {
              bingoRoundIdRef.current = currentGame.bingoRoundId;
              let purchasedCards = 0;
              const bingoCards = res.map((item, index) => {
                parseBingoCardsLayouts(item);
                if (item.bought) {
                  purchasedCards += 1;
                }
                item.canBePurchased = !item.bought;
                item.isPurchased = item.bought;
                item.id = item.checksum || item.cardId;
                item.cardNumber = String(index + 1);
                return item;
              });
              checkTicketLayout(res[0]);
              updateTicketsCurrentGame(purchasedCards);
              const maxNumberOfCardsReached =
                purchasedCards === currentGame.maxNumberOfCards;
              if (maxNumberOfCardsReached) {
                setBingoCardsList(
                  bingoCards.filter((ticket) => ticket.isPurchased)
                );
              } else {
                setBingoCardsList(bingoCards);
              }
              resetSelectedCardIds();
            }
          })
          .catch((err) => {
            if (mountedRef.current) {
              console.log('error on retrieving tickets: ', err);
              setApiErrorMessage(5);
              setIsLoading(false);
            }
          });
      }
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    if (mountedRef.current) {
      setTimeout(() => {
        loadBingoCards();
      }, Math.random() * 3000);
    }

    // Clean up.
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame]);

  useEffect(() => {
    if (fetchNewCards) {
      loadBingoCards();
      setFetchNewCards(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNewCards]);

  function updatePosition() {
    if (!isDesktop) {
      setChatIconPosition({
        chatReference: chatReference.current.getBoundingClientRect(),
      });
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updatePosition);
    updatePosition();
    return () => window.removeEventListener('resize', updatePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  useEffect(() => {
    setTimeout(() => {
      updatePosition();
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPurchaseView, breakpoint]);

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.bannerWrapper, {
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
        })}
      >
        <UpcomingGameBanner largeBanner hideInfoButton={isPurchaseDuringGame} />

        {breakpoint === DESKTOP && (
          <div className={styles.prizeWrapper}>
            <ListOfPrizes purchaseView displayNextGame={isPurchaseDuringGame} />
          </div>
        )}
      </div>
      <div
        className={clsx(styles.purchaseButtonsWrapper, {
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
        })}
      >
        <div className={styles.ticketButtonsWrapper}>
          <BuyLotteryTicketButtons isPrebuy={isPurchaseDuringGame} />
        </div>
        <div
          className={clsx(styles.innerWrapper, {
            [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
          })}
        >
          <Button
            onClick={onClickBuy}
            className={styles.payButton}
            isDisabled={
              isPurchaseDuringGame
                ? !prebuyTicketAmount || !nextGame.bingoGameId
                : !selectedTicketIds.length || !currentGame.bingoGameId
            }
          >
            <span className={styles.bold}>
              {text.t('buyLotteryTicketButtons.payButton')}
            </span>
            {isPurchaseDuringGame ? (
              <span className={styles.unBold}>
                {text.t('buyLotteryTicketButtons.totalAmount', {
                  amount: formatCurrency(
                    prebuyTicketAmount * nextGame.cardPrice
                  ),
                })}
              </span>
            ) : (
              <span className={styles.unBold}>
                {text.t('buyLotteryTicketButtons.totalAmount', {
                  amount: formatCurrency(
                    currentGame.cardPrice
                      ? currentGame.cardPrice * selectedTicketIds.length
                      : 0
                  ),
                })}
              </span>
            )}
          </Button>
          {user.isAuthenticated && displayBonus && breakpoint !== DESKTOP && (
            <div
              className={clsx(styles.bonus, {
                [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
              })}
            >
              <BonusNotification bonus={displayBonus} />
            </div>
          )}
          {breakpoint === TABLET_LANDSCAPE && (
            <div className={styles.smallBanner}>
              <UpcomingGameBanner nakedBanner />
            </div>
          )}
          {breakpoint !== DESKTOP && (
            <div
              className={clsx(styles.gameWalletAndChatWrapper, {
                [styles.isNotLoggedIn]: !user.isAuthenticated,
                [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
              })}
            >
              {user.isAuthenticated && (
                <div className={styles.gameWallet}>
                  <GameWallet />
                </div>
              )}
              <Button
                isIcon
                onClick={onClickChatButton}
                className={styles.chatIconButton}
              >
                <div className={styles.chatIconWrapper} ref={chatReference}>
                  <ChatIcon className={styles.chatIcon} />
                </div>
              </Button>
            </div>
          )}
        </div>
        {user.isAuthenticated && displayBonus && breakpoint === DESKTOP && (
          <div className={styles.bonus}>
            <BonusNotification bonus={displayBonus} />
          </div>
        )}
      </div>
      {isPurchaseDuringGame && (
        <button
          type="button"
          className={clsx(styles.closeButton, {
            [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
          })}
          onClick={onClickCloseButton}
        >
          <span className={styles.crossWrapper}>
            <Cross className={styles.crossIcon} />
          </span>
        </button>
      )}
    </div>
  );
}

PurchaseLotteryTicketsPanel.propTypes = {
  setIsPurchaseDuringGame: func.isRequired,
  isPurchaseDuringGame: bool.isRequired,
  setHasPurchaseModal: func.isRequired,
  displayBonus: shape(),
};

PurchaseLotteryTicketsPanel.defaultProps = {
  displayBonus: null,
};

export default PurchaseLotteryTicketsPanel;
