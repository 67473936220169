import { create } from 'zustand';

const useUpcomingGameStore = create((set) => ({
  nextGame: {
    backgroundBig: null,
    backgroundSmall: null,
    bingoGameId: null,
    bingoRoundId: null,
    cardPrice: null,
    jackpots: [],
    logoBig: null,
    logoSmall: null,
    maxNumberOfCards: null,
    name: null,
    numberOfCards: null,
    numberOfPlayers: null,
    outcallVoice: null,
    pot: null,
    prizes: [],
    rules: null,
    secondsBetweenNumbersDrawn: null,
    secondsToDisplayWinners: null,
    startsInSeconds: null,
  },
  setGame: (updatedGame) => set(() => ({ nextGame: updatedGame })),
}));

export default useUpcomingGameStore;
