// const appElement = document.getElementById('app');

// export const getCssVariable = (value, element = appElement) =>
//   getComputedStyle(element).getPropertyValue(value);

// eslint-disable-next-line import/prefer-default-export
export const getCssVariable = (value) => {
  const rootElement = document.getElementById('rsbingoclient');
  return getComputedStyle(rootElement).getPropertyValue(value);
};

// export const setCssVariable = (variable, value, element = appElement) => {
//   element.style.setProperty(variable, value);
// };
