import { useEffect } from 'react';
import useGameStatsStore from 'stores/current-game';
import useGameErrorStore from 'stores/game-error';
import useUpcomingGameStore from 'stores/upcoming-game';
import useUserStore from 'stores/user';
import {
  BONUS_ID,
  CLIENT_PROGRESS,
  CURRENT_PROGRESS,
  GAME_ID,
  INIT_PROGRESS,
  checkIfUserHasActiveBonus,
} from 'utils/customerBonusHelpers';

const useClientProgress = () => {
  const user = useUserStore((state) => state.user);
  const gameError = useGameErrorStore((state) => state.gameError);
  const currentGameId = useGameStatsStore(
    ({ currentGame }) => currentGame.bingoRoundId
  );
  const nextGameId = useUpcomingGameStore(
    ({ nextGame }) => nextGame.bingoRoundId
  );

  const gameId = nextGameId !== -1000 ? nextGameId : currentGameId;

  const userId = user?.userId;
  const clientProgressKey = userId && `${CLIENT_PROGRESS}_${userId}`;
  const activeBonus = checkIfUserHasActiveBonus(user, gameId);
  const bonusId = activeBonus?.id;
  const backendProgress = activeBonus?.qualification?.progress;

  const getClientProgress = () => {
    if (!userId) {
      return null;
    }
    const jsonObject = sessionStorage.getItem(clientProgressKey);
    const progressObject = jsonObject && JSON.parse(jsonObject);
    return progressObject;
  };

  const clientProgress = getClientProgress();
  const currentProgress = clientProgress?.[CURRENT_PROGRESS];
  const clientInitProgress = clientProgress?.[INIT_PROGRESS];
  const clientGameId = clientProgress?.[GAME_ID];
  const clientBonusId = clientProgress?.[BONUS_ID];

  const clientProgressIsValid =
    clientGameId === gameId && clientBonusId === bonusId;

  const getCurrentProgress = () =>
    clientProgressIsValid ? currentProgress : null;

  const updateCurrentProgress = (diff) => {
    if (!userId || !gameId || !bonusId) {
      console.error(`Couldn't update clientProgress: Missing required data.`);
    }

    if (clientProgressIsValid) {
      const newProgress = parseInt(currentProgress, 10) + parseInt(diff, 10);
      const updates = { [CURRENT_PROGRESS]: newProgress };
      sessionStorage.setItem(
        clientProgressKey,
        JSON.stringify({ ...clientProgress, ...updates })
      );
    }
  };

  const resetClientProgress = () => {
    if (
      !userId ||
      !gameId ||
      !bonusId ||
      !(backendProgress || backendProgress === 0)
    ) {
      console.error(`Couldn't reset clientProgress: Missing required data.`);
      return;
    }

    const syncedProgress = {
      [GAME_ID]: gameId,
      [BONUS_ID]: bonusId,
      [INIT_PROGRESS]: backendProgress,
      [CURRENT_PROGRESS]: backendProgress,
    };

    sessionStorage.setItem(clientProgressKey, JSON.stringify(syncedProgress));
  };

  // Reset clientProgress on gameStart or gameError
  useEffect(() => {
    if (!bonusId || !gameId || !(backendProgress || backendProgress === 0))
      return;

    if (
      clientInitProgress !== backendProgress ||
      clientGameId !== gameId ||
      clientBonusId !== bonusId ||
      gameError
    ) {
      resetClientProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameError, gameId, bonusId, backendProgress]);

  return {
    getCurrentProgress,
    updateCurrentProgress,
  };
};

export default useClientProgress;
