const sv = {
  topPanel: {
    listItem1: 'Spelregler & vinstplan',
    listItem2: 'Spelansvar',
    listItem3: 'Chat',
    listItem4: 'Inställningar',
    listItem5: 'Se guide',
    listItem6: 'BingoLobby',
    soundOffText: 'LJUD AV',
    soundOnText: 'LJUD PÅ',
    guideButton: 'Se guide',
    responsibilitiesButton: 'Spelansvar',
    loginButton: 'Logga in för att spela',
  },
  gameWallet: {
    walletText: 'Spelplånbok',
    buttonText: 'Sätt in',
  },
  gameBanner: {
    largeBannerTitlePrebuy: 'Tid till nästa spel',
    largeBannerTitleBuy: 'Tid till start',
    smallBannerTitle: 'Nästa bingospel',
  },
  gameInfo: {
    price: 'Pris',
    numberOfPlayersText: 'Antal spelare',
    numberOfPlayers: '%{amount} st',
    totalPott: 'Total pott',
    jackpott: 'JACKPOTT',
    withinText: 'inom',
    withinBalls: '%{amount} bollar',
  },
  settings: {
    heading: 'Inställningar',
    autosort: 'Autosortering',
    autosortExplanation:
      'Autosortering ser till att den bästa bingobrickan syns först i ledet.',
    automark: 'Autoduttning',
    automarkExplanation:
      'Använd autoduttning om du inte vill dutta bingobrickorna manuellt. (Lugn, även när du duttar själv så rättar vi brickan).',
    symbols: 'Dutt-symboler',
    colors: 'Färger',
    sound: 'Ljud',
    announcer: 'Utropare',
    effects: 'Effekter',
    music: 'Musik',
    profileimage: 'Profilbild',
  },
  buyLotteryTicketButtons: {
    heading: 'Köp brickor',
    minusButton: '-',
    plusButton: '+',
    predefinedButtons: '%{amount} st',
    maxButton: 'Max',
    buyButton: 'Köp till nästa ',
    payButton: 'Betala',
    amount: '%{amount}',
    totalAmount: 'Totalt %{amount}',
  },
  prepurchasedTickets: {
    oneTicket: '%{amount} köpt bricka till nästa spel',
    moreTickets: '%{amount} köpta brickor till nästa spel',
  },
  leaderboard: {
    heading: 'Ställning',
  },
  chat: {
    bingoRoomsChathostOnlineText: 'Chattvärd online:\n %{name}',
    bingoRoomsButtonOpen: 'Visa alla bingorum',
    bingoRoomsButtonClose: 'Visa chat',
    isClosedMessage: 'Chatten är stängd',
    loginMessage: 'Logga in för att chatta',
    toggleButton: 'Öppna / stäng chatt',
    closeButton: 'Stäng chatt',
    admin: 'Chattvärd:',
    private: 'Privatchatt:',
    modal: {
      startPrivateChat: {
        title: 'Starta privatchat',
        text: 'Vill du starta en privatchat med',
        secondaryText:
          'Du kan inte ha fler flikar öppna. Stäng ner en chatt för att kunna starta en ny.',
        cancelButton: 'Avbryt',
        confirmButton: 'Bekräfta',
        okButton: 'Ok',
      },
      privateChatRequest: {
        title: 'Privatchat inbjudan',
        subTitle: 'Vill starta en privatchat med dig',
        secondarySubtitle:
          'Du kan inte ha fler flikar öppna. Stäng ner en chatt för att kunna öppna en ny.',
        cancelButton: 'Nej tack',
        confirmButton: 'Starta chat',
        okButton: 'Ok',
      },
    },
  },
  bingoCard: {
    demo: 'Demo',
    purchase: {
      selectButton: 'Välj bricka',
      isSelected: 'Vald bricka',
      isPurchased: 'Betald bricka',
    },
    numbersLeft: '%{numbers} kvar till bingo!',
    bingo: 'Bingo!',
  },
  prizeplan: {
    title: '%{name} vinstplan',
    priceText: 'Pris',
    numberOfPlayersText: 'Antal spelare',
    totalPottText: 'Total pott',
    withinBallsText: 'Antal bollar',
    header1: 'Deldragning',
    header2: 'Radmönster',
    header3: 'Vinstandel',
    header4: 'Vinst',
    jackpotFullText: 'JACKPOTT inom %{number} bollar',
    jackpotText1: 'JACKPOTT',
    jackpotText2: 'inom %{number} bollar',
    subtitle: 'Spelregler',
  },
  gamingResponsibility: {
    heading1: 'Vårt arbete med spelansvar',
    preamble:
      'För mycket av det roliga är aldrig bra – och det gäller inte minst för spel. Drömmen om den stora vinsten som ska förändra livet finns hos oss de allra flesta och är en viktig drivkraft i spel.',
    content1:
      'Vår målsättning är att Miljonlotteriet ska ligga i framkant när det gäller spelansvar. Spelansvarsarbete minskar risken för att spelare ska utveckla spelproblem så att spel kan fortsätta vara roligt.',
    heading2: 'Åldersgräns',
    content2:
      'Man måste vara 18 år för att kunna få spela på Miljonlotteriets spel. 18-årsgränsen omfattar allt från prenumerationslotter och spel på internet till butiksförsäljning och försäljning via föreningar. Forskning visar att ju tidigare man börjar spela desto större är risken att man hamnar i ett osunt spelande.',
  },
  winnerListModal: {
    title: 'Spelet är slut',
    table: {
      header1: 'Deldragning',
      header2: 'Inom',
      header3: 'Vinst',
      header4: 'Vinnare',
    },
    closeButton: 'Stäng',
  },
  prizeNotification: {
    bingo: {
      title: 'Grattis till vinst!',
    },
    jackpot: {
      title: 'Grattis till jackpott!',
    },
  },
  purchaseModal: {
    purchase: {
      title: 'Köpbekräftelse',
      bingoCardPrice: 'Brickpris',
      bingoCardsAmount: 'Antal brickor',
      total: 'Totalt',
      countAppendix: '%{total} st',
      currencyAppendix: '%{amount}',
      vouchersPayForAllTickets:
        'Köpet är kostnadsfritt då det avser spel som du har fått i gåva av oss.',
      vouchersPayForSomeTickets:
        '%{numberOfVouchers} av brickorna betalas av din gåva från oss, resterande dras från din spelplånbok.',
      confirmButton: 'Bekräfta',
      cancelButton: 'Avbryt',
    },
    receipt: {
      title: 'Kvitto',
      goodLuck: 'Lycka till %{name}!',
      returnButton: 'Gå till mina brickor',
    },
    incuffientFunds: {
      title: 'Slut på pengar',
      firstText:
        'Du har tyvärr inte tillräckligt med pengar i din spelplånbok.',
      secondText: 'Fyll på så att du kan fortsätta',
      button: 'Sätt in pengar',
    },
    noGameLimitsSet: {
      title: 'Köp kan ej genomföras',
      text: 'Du måste sätta insättningsgräns innan du kan spela.',
      closeModalButtonText: 'OK',
    },
  },
  gameErrorModal: {
    tooFewPlayers: {
      title: 'För få spelare',
      content1:
        'Detta bingospel fick för få spelare för att starta. Om du har köpt brickor sätts dina pengar tillbaka.',
      content2: 'Nästa bingoomgång öppnas nu för köp.',
      nextButton: 'Gå till nästa bingo',
      backButton: 'Tillbaka',
    },
  },
  apiErrorModal: {
    title: 'Något har gått fel!',
    titleWhenBuying: 'Kan ej köpa brickor!',
    content0: '',
    content1:
      'Problem uppstod vid hämtning av användaruppgifter, prova att logga ut och logga in igen för att försöka igen.',
    content2:
      'Vi lyckades inte uppdatera ditt saldo, ladda om sidan för korrekt värde.',
    content3: 'Vi lyckades inte hämta dina inställningar.',
    content4: 'Vi lyckades inte spara dina inställningar.',
    content5: 'Inga brickor kunde hämtas, försök igen.',
    content6:
      'Problem uppstod i hämtningen av brickor, ladda om sidan för att försöka igen.',
    content7: 'Problem uppstod med anslutningen, vi försöker återansluta.',
    content8:
      'Återanslutning misslyckades. Ladda om sidan för att ansluta igen.',
    content9: 'Återanslutningen lyckades!',
    content10: 'Ett problem uppstod vid köp, försök igen.',
    content11: 'Du måste logga in för att kunna se återuppspelning av brickan.',
    content12: 'Du måste sätta ett chatnamn för att kunna spela.',
    content13: 'Du har överskridit din förlustgräns.',
    content14: 'Du har överskridit din tidsgräns.',
    content15: 'Du har spelstopp aktiverat.',
    content16: 'Du har blivit blockerad från bingospel.',
    content17: 'Bingorundan har stängts för försäljning.',
    closeButtonText: 'OK',
  },
  bingoRooms: {
    header: 'Nästa spel i våra bingorum:',
    jackpotWithSum: 'Jackpott: %{value}',
    jackpot: 'Jackpott:',
    nextGameText: 'Nästa spel:',
    buttonText: 'Till spelet',
  },
  tutorial: {
    greetingHeading: 'Hej, vad kul att du är här!',
    greetingParagraph:
      'Vill du ha en rundtur i vårt nya bingo? Du kan alltid välja att se den senare.',
    yesButton: 'Ja',
    noButton: 'Nej',
    nextStepButton: 'Nästa steg',
    lastStepButton: 'Stäng guide',
    step1Text: 'Här sätter du in pengar för att börja spela',
    step2Text:
      'Hur vill du ha ditt bingo? Här väljer du om du vill dutta själv, vilken duttsymbol du önskar, färg och profilbild.',
    step3Text: 'Här kan du stänga av och sätta igång ljudet',
    step4Text: 'Här hittar du info om pågående spel',
    step5ViewText: 'Här kan du ändra vy på bingobrickorna',
    step5ChatText: 'Här kan du fälla ut chatten',
  },
  bonusModal: {
    purchaseConfirmation: {
      firstParagraph1: 'Du kommer att ha',
      firstParagraph2: 'uppnått',
      firstParagraph3: 'din bonus!',
      firstParagraph4: 'kvar till bonus!',
      bonusPayoutText: 'Bonus!',
      voucherText: '%{amount} gratisspel!',
    },
    receipt: {
      firstParagraph1: 'Du har',
      firstParagraph2: 'uppnått',
      firstParagraph3: 'din bonus!',
      firstParagraph4: 'kvar till bonus!',
      moneyPayout: '%{amount} sätts in i din spelplånbok.',
      voucherPayout: 'Dina gratisspel har betalats ut.',
      percentPayout: 'Din bonus har betalats ut!',
    },
    purchaseView: {
      purchaseView: 'kvar till bonus!',
      bonusPayoutText: 'Bonus!',
      voucherText: '%{amount} gratisspel!',
    },
  },
  potbooster: {
    header: 'POTRAKETEN',
    totalPot: 'Total pott:',
  },
  currencyFormat: 'SEK',
  currencyLocale: 'sv-SE',
  doNotSupportLandscape: 'Rotera din enhet för att spela bingo',
  doNotSupportIE: 'Klienten stödjer inte denna webbläsare.',
  isOtherActiveSession:
    'Du kan bara vara ansluten till ett bingorum i taget, stäng ner de andra anslutningarna och prova igen',
  isOtherActiveSessionButton: 'Prova igen',
  blacklistedWordMessage: 'Meddelandet skickades ej på grund av spärrade ord',
};

export default sv;
