import { create } from 'zustand';

const useLayoutStore = create((set) => ({
  layoutId: 1,
  isSmallTickets: false,
  isLongTickets: false,
  isFreeSquareBingo: false,
  isTinyTickets: false,
  is4x4Tickets: false,
  setLayoutId: (layoutId) => set({ layoutId }),
  setIsSmallTickets: (newValue) =>
    set(() => ({
      isSmallTickets: newValue,
    })),
  setIsLongTickets: (newValue) =>
    set(() => ({
      isLongTickets: newValue,
    })),
  setIsFreeSquareBingo: (newValue) =>
    set(() => ({
      isFreeSquareBingo: newValue,
    })),
  setIsTinyTickets: (newValue) =>
    set(() => ({
      isTinyTickets: newValue,
    })),
  setIs4x4Tickets: (newValue) =>
    set(() => ({
      is4x4Tickets: newValue,
    })),
}));

export default useLayoutStore;
