import { create } from 'zustand';

const useApiErrorStore = create((set) => ({
  isModalVisible: false,
  errorMessageID: 0,
  setErrorMessage: (value) =>
    set(() => ({ errorMessageID: value, isModalVisible: true })),
  resetErrorMessage: () =>
    set(() => ({ errorMessageID: 0, isModalVisible: false })),
}));

export default useApiErrorStore;
