import React, { useRef, useLayoutEffect, useEffect } from 'react';
import clsx from 'clsx';
import { bool } from 'prop-types';
import { shallow } from 'zustand/shallow';

import useLayoutStore from 'stores/layout';
import tutorialStore from 'stores/tutorial';
import useGameStatsStore from 'stores/current-game';

import LayoutShape1 from 'assets/svg/layout-shape-1.svg';
import LayoutShape2 from 'assets/svg/layout-shape-2.svg';
import LayoutShape3 from 'assets/svg/layout-shape-3.svg';
import LayoutShape4 from 'assets/svg/layout-shape-4.svg';
import LayoutShape5 from 'assets/svg/layout-shape-5.svg';
import LayoutShape6 from 'assets/svg/layout-shape-6.svg';
import LayoutShape7 from 'assets/svg/layout-shape-7.svg';
import LayoutShape8 from 'assets/svg/layout-shape-8.svg';
import LayoutShape9 from 'assets/svg/layout-shape-9.svg';

import styles from './LayoutButtons.module.scss';

const currentLayoutSelector = (state) => [state.layoutId, state.setLayoutId];
const isSmallTicketsSelector = (state) => state.isSmallTickets;
const isLongTicketsSelector = (state) => state.isLongTickets;
const layoutButtonsPositionSelector = (state) => state.setLayoutButtonsPosition;
const isTinyTicketsSelector = (state) => state.isTinyTickets;
const currentGameSelector = (state) => state.currentGame;

function LayoutButtons({ isPartOfTutorial }) {
  const [currentLayoutId, setLayoutId] = useLayoutStore(
    currentLayoutSelector,
    shallow
  );
  const setLayoutButtonsPosition = tutorialStore(layoutButtonsPositionSelector);
  const isSmallTickets = useLayoutStore(isSmallTicketsSelector);
  const isLongTickets = useLayoutStore(isLongTicketsSelector);
  const isTinyTickets = useLayoutStore(isTinyTicketsSelector);
  const currentGame = useGameStatsStore(currentGameSelector);

  const layoutButtonsReference = useRef(0);

  function updatePosition() {
    if (!isPartOfTutorial) {
      setLayoutButtonsPosition({
        // eslint-disable-next-line prettier/prettier
        layoutButtonsPosition:
          layoutButtonsReference.current.getBoundingClientRect(),
      });
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updatePosition);
    updatePosition();
    return () => window.removeEventListener('resize', updatePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallTickets, isLongTickets]);

  const sendDataLayer = (value) => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'ga4_event',
      ga4_data: {
        event_name: 'bingo_change_layout',
        section: 'Bingo client',
        bingo_name: currentGame.name,
        label: value,
        allow_auto_collect: true,
      },
    });
  };

  const returnFirstButton = () => {
    if (isSmallTickets) {
      return (
        <LayoutShape5
          className={clsx(styles.layoutIcon, {
            [styles.active]: currentLayoutId === 1,
            [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 1,
          })}
        />
      );
    }
    if (isTinyTickets) {
      return (
        <LayoutShape7
          className={clsx(styles.layoutIcon, {
            [styles.active]: currentLayoutId === 1,
            [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 1,
          })}
        />
      );
    }
    return (
      <LayoutShape2
        className={clsx(styles.layoutIcon, {
          [styles.active]: currentLayoutId === 1,
          [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 1,
        })}
      />
    );
  };

  const returnSecondButton = () => {
    if (isSmallTickets) {
      return (
        <LayoutShape4
          className={clsx(styles.layoutIcon, {
            [styles.active]: currentLayoutId === 2,
            [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 2,
          })}
        />
      );
    }
    if (isTinyTickets) {
      return (
        <LayoutShape8
          className={clsx(styles.layoutIcon, styles.isSmaller, {
            [styles.active]: currentLayoutId === 2,
            [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 2,
          })}
        />
      );
    }
    if (isLongTickets) {
      return (
        <LayoutShape3
          className={clsx(styles.layoutIcon, {
            [styles.active]: currentLayoutId === 2,
            [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 2,
          })}
        />
      );
    }
    return (
      <LayoutShape1
        className={clsx(styles.layoutIcon, {
          [styles.active]: currentLayoutId === 2,
          [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 2,
        })}
      />
    );
  };

  const returnThirdButton = () => {
    if (isSmallTickets) {
      return (
        <LayoutShape6
          className={clsx(styles.layoutIcon, {
            [styles.active]: currentLayoutId === 3,
            [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 3,
          })}
        />
      );
    }
    if (isTinyTickets) {
      return (
        <LayoutShape9
          className={clsx(styles.layoutIcon, {
            [styles.active]: currentLayoutId === 3,
            [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 3,
          })}
        />
      );
    }
    if (isLongTickets) {
      return (
        <LayoutShape1
          className={clsx(styles.layoutIcon, {
            [styles.active]: currentLayoutId === 3,
            [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 3,
          })}
        />
      );
    }
    return (
      <LayoutShape3
        className={clsx(styles.layoutIcon, {
          [styles.active]: currentLayoutId === 3,
          [styles.activeTutorial]: isPartOfTutorial && currentLayoutId !== 3,
        })}
      />
    );
  };

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isLongTickets]: isLongTickets,
      })}
      ref={layoutButtonsReference}
    >
      <button
        type="button"
        className={clsx(styles.layoutButton, {
          [styles.isLongTickets]: isLongTickets,
        })}
        onClick={() => {
          sendDataLayer(1);
          setLayoutId(1);
        }}
      >
        {returnFirstButton()}
      </button>
      <button
        type="button"
        className={clsx(styles.layoutButton, {
          [styles.isLongTickets]: isLongTickets,
        })}
        onClick={() => {
          sendDataLayer(2);
          setLayoutId(2);
        }}
      >
        {returnSecondButton()}
      </button>
      <button
        type="button"
        className={clsx(styles.layoutButton, {
          [styles.isLongTickets]: isLongTickets,
        })}
        onClick={() => {
          sendDataLayer(3);
          setLayoutId(3);
        }}
      >
        {returnThirdButton()}
      </button>
    </div>
  );
}

LayoutButtons.propTypes = {
  isPartOfTutorial: bool,
};

LayoutButtons.defaultProps = {
  isPartOfTutorial: false,
};

export default LayoutButtons;
