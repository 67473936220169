import React, { useLayoutEffect, useState, useRef } from 'react';
import { arrayOf, shape, string, object } from 'prop-types';
import clsx from 'clsx';
import throttle from 'lodash.throttle';
import useBreakpoint, { TABLET_LANDSCAPE } from 'hooks/useBreakpoint';
import useLayoutStore from 'stores/layout';

import DragScroll from 'components/DragScroll/DragScroll';
import BingoCard from 'components/BingoCard/BingoCard';

import styles from './LayoutWithFooterScrollTiny.module.scss';

function LayoutWithFooterScrollTiny({ items }) {
  const is4x4Tickets = useLayoutStore((state) => state.is4x4Tickets);
  const topRowRef = useRef(null);
  const spacerRef = useRef(null);
  const breakpoint = useBreakpoint();

  const [itemsTop, setItemsTop] = useState([]);
  const [itemsFooter, setItemsFooter] = useState([]);

  useLayoutEffect(() => {
    const wrapper = topRowRef.current;

    const onResize = throttle(() => {
      const wrapperWidth = wrapper.offsetWidth;
      const spacerWidth = spacerRef.current.clientWidth;
      const availableSlotsInTop = Math.floor(wrapperWidth / spacerWidth);
      const top = items.slice(0, availableSlotsInTop);
      const footer = items.slice(availableSlotsInTop);

      setItemsTop(top);
      setItemsFooter(footer);
    }, 500);

    // create an Observer instance
    const resizeObserver = new ResizeObserver(onResize);

    resizeObserver.observe(wrapper);

    return () => {
      resizeObserver.unobserve(wrapper);
    };
  }, [items]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
        [styles.isOneTicket]: items.length === 1,
        [styles.is4x4Tickets]: is4x4Tickets,
      })}
    >
      <div className={styles.topRowWrapper}>
        <div ref={topRowRef} className={styles.topRow}>
          <div ref={spacerRef} className={styles.spacer} />

          {itemsTop.map((card) => (
            <div key={card.id} className={styles.item}>
              <BingoCard
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...card}
                hasFooter
                isLargeTinyTicket
              />
            </div>
          ))}
        </div>
      </div>

      <DragScroll classNameScrollbar={styles.scrollbar}>
        <div className={styles.footer}>
          {itemsFooter.map((card) => (
            <div key={card.id} className={styles.item}>
              <BingoCard
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...card}
                isSmallTinyTicket
                hasFooter
              />
            </div>
          ))}
        </div>
      </DragScroll>
    </div>
  );
}

LayoutWithFooterScrollTiny.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      numbersMap: arrayOf(arrayOf(object)),
    })
  ),
};

LayoutWithFooterScrollTiny.defaultProps = {
  items: [],
};

export default LayoutWithFooterScrollTiny;
