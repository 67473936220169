import { create } from 'zustand';

const useLeaderboardStore = create((set) => ({
  leaderboard: [],
  resetLeaderboard: () => set({ leaderboard: [] }),
  setLeaderboard: (newLeaderboard) =>
    set(() => ({
      leaderboard: newLeaderboard,
    })),
}));

export default useLeaderboardStore;
