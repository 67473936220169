/* eslint-disable react/no-array-index-key */

import React from 'react';
import clsx from 'clsx';
import {
  string,
  oneOfType,
  arrayOf,
  bool,
  func,
  number,
  object,
} from 'prop-types';

import Star from 'assets/svg/star-simple.svg';
import useLayoutStore from 'stores/layout';
import useGameStore, { ACTIVE_GAME } from 'stores/game';

import NumberBlock from './NumberBlock/NumberBlock';

import styles from './Numbers.module.scss';

const longTicketSelector = (state) => state.isLongTickets;
const freeSquareBingoSelector = (state) => state.isFreeSquareBingo;
const gameStateSelector = (state) => state.gameState;
const isTinyTicketsSelector = (state) => state.isTinyTickets;
const is4x4TicketsSelector = (state) => state.is4x4Tickets;

function Numbers({
  id,
  numbersMap,
  markedNumbers,
  currentSymbol,
  currentSymbolColorId,
  isHistory,
  onClick,
  winningNumbers,
  isLongTicketHistoryCard,
  isTallHistoryCard,
}) {
  const isLongTicket = useLayoutStore(longTicketSelector);
  const isFreeSquareBingo = useLayoutStore(freeSquareBingoSelector);
  const isTinyTickets = useLayoutStore(isTinyTicketsSelector);
  const is4x4Tickets = useLayoutStore(is4x4TicketsSelector);
  const gameState = useGameStore(gameStateSelector);
  const isActiveGame = gameState === ACTIVE_GAME;

  const emptyBlock = () => {
    if (isFreeSquareBingo) {
      return [
        <Star
          className={styles.emptyBlockIcon}
          viewBox="0 0 18 17"
          fill="var(--theme-color-1)"
        />,
      ];
    }
    return '';
  };

  const isActive = (blockInUse, blockValue) => {
    if (isFreeSquareBingo && !blockInUse && isActiveGame) {
      return true;
    }
    return markedNumbers.some((r) => blockValue?.includes(r));
  };

  const nextWinningNumbers = (block) => {
    const blockValues = block.Value ? [block.Value] : block.Values;

    return blockValues?.some((num) => {
      const isWinning = winningNumbers.flat().includes(Number(num));
      const isMarked = markedNumbers.includes(Number(num));

      return isWinning && !isMarked;
    });
  };

  return numbersMap.map((rows, rowIndex) => (
    <div
      className={clsx(styles.row, {
        [styles.isLongTicket]: isLongTicket,
        [styles.isLongTicketHistoryCard]: isLongTicketHistoryCard,
        [styles.isTinyTickets]: isTinyTickets,
        [styles.isTinyTicketsHistory]: isTinyTickets && isHistory,
        [styles.is4x4Tickets]: is4x4Tickets,
        [styles.isTallHistoryCard]: isTallHistoryCard,
      })}
      key={`${id}-row-${rowIndex}`}
    >
      {rows.map((block, i) => (
        <NumberBlock
          key={`${id}-row-${rowIndex}-number-
            ${
              (block.Value !== undefined ? block.Value : block.Values) ||
              i + 100
            }-
            ${winningNumbers ? winningNumbers.length : null}`}
          value={block.InUse ? block.Value || block.Values : emptyBlock()}
          symbol={currentSymbol}
          symbolColorId={currentSymbolColorId}
          isActive={isActive(block.InUse, block.Value || block.Values)}
          isHistory={isHistory}
          onClick={onClick}
          isAnimating={winningNumbers && nextWinningNumbers(block)}
          valueIsIcon={isFreeSquareBingo && !block.InUse}
          isTallHistoryCard={isTallHistoryCard}
          isLongHistoryCard={isLongTicketHistoryCard}
        />
      ))}
    </div>
  ));
}

Numbers.propTypes = {
  id: oneOfType([string, number]).isRequired,
  numbersMap: arrayOf(arrayOf(object)).isRequired,
  onClick: func.isRequired,
  markedNumbers: arrayOf(number),
  currentSymbol: string,
  currentSymbolColorId: number,
  isHistory: bool,
  canAnimate: bool,
  isLongTicketHistoryCard: bool,
  isTallHistoryCard: bool,
};

Numbers.defaultProps = {
  markedNumbers: [],
  currentSymbol: null,
  currentSymbolColorId: null,
  isHistory: false,
  canAnimate: false,
  isLongTicketHistoryCard: false,
  isTallHistoryCard: false,
};

// Only update if not equal.
const propsAreEqual = (prev, next) =>
  prev.markedNumbers.length === next.markedNumbers.length &&
  prev.currentSymbol === next.currentSymbol &&
  prev.currentSymbolColorId === next.currentSymbolColorId &&
  prev.isHistory === next.isHistory &&
  prev.isLongTicketHistoryCard === next.isLongTicketHistoryCard;

export default React.memo(Numbers, propsAreEqual);
