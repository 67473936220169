import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { arrayOf, shape, string, number, object } from 'prop-types';

import useGameStore, { ACTIVE_GAME } from 'stores/game';
import useBreakpoint, { DESKTOP, TABLET_LANDSCAPE } from 'hooks/useBreakpoint';
import useMenuStore from 'stores/menu';

import DragScroll from 'components/DragScroll/DragScroll';
import BingoCard from 'components/BingoCard/BingoCard';
import LastDrawnBalls from 'components/GameInfoPanel/LastDrawnBalls/LastDrawnBalls';
import ListOfPrizes from 'components/GameInfoPanel/ListOfPrizes/ListOfPrizes';

import styles from './LayoutLongDefault.module.scss';

const gameStateSelector = (state) => state.gameState;
const menuExpandedSelector = (state) => state.isMenuExpanded;

function LayoutLongDefault({ items, layoutId }) {
  const [firstCard, secondCard, thirdCard, ...restCards] = items;
  const gameState = useGameStore(gameStateSelector);
  const isMenuExpanded = useMenuStore(menuExpandedSelector);
  const breakpoint = useBreakpoint();
  const cards = layoutId === 1 || layoutId === 0 ? items : restCards;

  const useWindowInnerHeight = () => {
    const [windowInnerHeight, setWindowInnerHeight] = useState(() => {
      if (typeof window !== 'undefined') {
        return window.innerHeight;
      }
    });
    useEffect(() => {
      setWindowInnerHeight(window.innerHeight);
      const onResize = () => {
        setWindowInnerHeight(window.innerHeight);
      };
      window.addEventListener('resize', onResize);

      return () => window.removeEventListener('resize', onResize);
    }, []);

    return windowInnerHeight;
  };

  const innerHeight = useWindowInnerHeight();

  return (
    <>
      <div
        className={clsx(styles.miniCard, {
          [styles.isVisible]: layoutId === 0 && gameState === ACTIVE_GAME,
        })}
      >
        {firstCard && (
          <span className={styles.card}>
            <BingoCard
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...firstCard}
              hasFooter
              footerIsOnTop
            />
          </span>
        )}
        <LastDrawnBalls isVertical />
        <div className={styles.prizes}>
          <ListOfPrizes isVertical />
        </div>
      </div>
      <DragScroll
        classNameWrapper={styles.dragScrollWrapper}
        classNameScrollbar={clsx(styles.scrollbar, {
          [styles.isHidden]: layoutId === 0 && gameState === ACTIVE_GAME,
        })}
        forceUpdate={layoutId}
      >
        <div
          className={clsx(styles.wrapper, {
            [styles.isHidden]: layoutId === 0 && gameState === ACTIVE_GAME,
            [styles.isMenuExpanded]: isMenuExpanded,
            [styles.isActiveGame]: gameState === ACTIVE_GAME,
            [styles.isOneOrTwoTickets]: items.length < 3,
            [styles.isLayoutIdTwo]: layoutId === 2,
            [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
          })}
        >
          <div
            className={clsx(styles.innerWrapper, {
              [styles.isColumn]: layoutId === 3,
            })}
          >
            {layoutId !== 1 && layoutId !== 0 && (
              <div
                className={clsx(styles.firstRow, {
                  [styles.isColumn]: layoutId === 1 || layoutId === 2,
                })}
              >
                {firstCard && (
                  <div key={firstCard.id} className={clsx(styles.item)}>
                    <BingoCard
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...firstCard}
                      isLarge
                      hasFooter
                      footerIsOnTop
                    />
                  </div>
                )}
                {secondCard && (
                  <div key={secondCard.id} className={clsx(styles.item)}>
                    <BingoCard
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...secondCard}
                      isLarge
                      hasFooter
                      footerIsOnTop
                    />
                  </div>
                )}
                {thirdCard && (
                  <div
                    key={thirdCard.id}
                    className={clsx(styles.item, {
                      [styles.isHidden]:
                        (items.length < 14 && layoutId === 3) ||
                        (layoutId === 2 &&
                          innerHeight < 730 &&
                          breakpoint === DESKTOP),
                    })}
                  >
                    <BingoCard
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...thirdCard}
                      isLarge
                      hasFooter
                      footerIsOnTop
                    />
                  </div>
                )}
              </div>
            )}
            <div
              className={clsx(styles.row, {
                [styles.isGridSixRows]: layoutId === 2,
                [styles.isGrid]: layoutId === 3,
                [styles.isList]: layoutId === 1 || layoutId === 0,
                [styles.isMenuExpanded]: isMenuExpanded,
                [styles.isActiveGame]: gameState === ACTIVE_GAME,
              })}
            >
              {(thirdCard && layoutId === 3 && items.length < 14) ||
                (thirdCard &&
                  layoutId === 2 &&
                  innerHeight < 730 &&
                  breakpoint === DESKTOP && (
                    <div key={thirdCard.id} className={clsx(styles.item)}>
                      <BingoCard
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...thirdCard}
                        isSmallTinyTicket={layoutId === 2}
                        isLarge={layoutId === 1}
                        hasFooter
                        footerIsOnTop
                      />
                    </div>
                  ))}
              {cards.map((card) => (
                <div key={card.id} className={styles.item}>
                  <BingoCard
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...card}
                    isLarge={layoutId === 1 || layoutId === 0}
                    hasFooter
                    footerIsOnTop
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </DragScroll>
    </>
  );
}

LayoutLongDefault.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      numbersMap: arrayOf(arrayOf(object)),
    })
  ),
  layoutId: number,
};

LayoutLongDefault.defaultProps = {
  items: [],
  layoutId: 1,
};

export default LayoutLongDefault;
