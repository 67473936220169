import React, { useRef, useEffect } from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';
import { text } from 'polyglot/polyglot';
import useUserStore from 'stores/user';
import useSettingsStore from 'stores/settings';
import useTutorialStore from 'stores/tutorial';
import useMenuStore from 'stores/menu';
import { getUserProfileImg } from 'utils/getUserProfileImg';

import GameWallet from 'components/GameWallet/GameWallet';

import styles from './UserProfileContainer.module.scss';

const userAvatarSelector = (state) => state.settings.userAvatar;
const userSelector = (state) => state.user;
const setUserProfilePositionSelector = (state) => state.setUserProfilePosition;
const menuOpenSelector = (state) => state.isMenuOpen;

function UserProfileContainer({ isTutorialView }) {
  const user = useUserStore(userSelector);
  const userAvatar = useSettingsStore(userAvatarSelector);
  const userAvatarSrc = getUserProfileImg(userAvatar);
  const setUserProfilePosition = useTutorialStore(
    setUserProfilePositionSelector
  );
  const isSideMenuActive = useMenuStore(menuOpenSelector);
  const wrapperPosition = useRef();

  const loginUser = () => {
    if (user.callback_login && typeof user.callback_login === 'function') {
      user.callback_login();
    }
  };

  const updateProfilePosition = () => {
    if (!isTutorialView && wrapperPosition.current) {
      setUserProfilePosition(wrapperPosition.current.getBoundingClientRect());
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateProfilePosition);
    updateProfilePosition();
    return () => {
      window.removeEventListener('resize', updateProfilePosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSideMenuActive && !isTutorialView) {
      setTimeout(() => {
        updateProfilePosition();
      }, 350);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSideMenuActive]);

  return (
    <div className={styles.wrapper} ref={wrapperPosition}>
      {!user || !user.isAuthenticated ? (
        <button
          type="button"
          className={styles.loginButton}
          onClick={loginUser}
        >
          {text.t('topPanel.loginButton')}
        </button>
      ) : (
        <div
          className={clsx(styles.userContainer, {
            [styles.isTutorialView]: isTutorialView,
          })}
        >
          <img src={userAvatarSrc} alt="" className={styles.userIcon} />
          <div className={styles.userInformation}>
            <span>{user.nickname}</span>
            <GameWallet insideUserProfile isTutorialView={isTutorialView} />
          </div>
        </div>
      )}
    </div>
  );
}

UserProfileContainer.propTypes = {
  isTutorialView: bool,
};

UserProfileContainer.defaultProps = {
  isTutorialView: false,
};

export default UserProfileContainer;
