import React, { useEffect, useRef } from 'react';
import { arrayOf, number, func } from 'prop-types';

import PixiApp from './PixiApp';

import styles from './PotBoosterAnimation.module.scss';

function PotBoosterAnimation({
  potBoosterMultipliers,
  selectedPotBoosterMultiplier,
  pot,
  potTotal,
  time,
  onAnimComplete,
}) {
  const canvasRef = useRef();
  const appRef = useRef();

  useEffect(() => {
    console.log('mount a potBooster');
    console.log('potBoosterMultipliers', potBoosterMultipliers);
    console.log('selectedPotBoosterMultiplier', selectedPotBoosterMultiplier);
    console.log('pot', pot);
    console.log('potTotal', potTotal);
    console.log('time', time);

    const potData = {
      multiplier: selectedPotBoosterMultiplier,
      multiplierList: potBoosterMultipliers,
      pot,
      total: potTotal,
      time: time * 1000,
    };

    appRef.current = new PixiApp();
    appRef.current.init(canvasRef.current, potData, () => {
      if (onAnimComplete) onAnimComplete();
    });

    return () => {
      if (appRef.current) {
        appRef.current.destroy();
        appRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper} ref={canvasRef} />
    </div>
  );
}

PotBoosterAnimation.propTypes = {
  potBoosterMultipliers: arrayOf(number),
  selectedPotBoosterMultiplier: number,
  pot: number,
  potTotal: number,
  time: number,
  onAnimComplete: func,
};

PotBoosterAnimation.defaultProps = {
  potBoosterMultipliers: [],
  selectedPotBoosterMultiplier: 2,
  pot: 0,
  potTotal: 0,
  time: 10,
  onAnimComplete: null,
};

export default PotBoosterAnimation;
