import React from 'react';
import { string } from 'prop-types';

import styles from './NakedBanner.module.scss';

function NakedBanner({ name, minutes, seconds }) {
  return (
    <div className={styles.wrapper}>
      <span>{name}</span>
      {minutes && seconds && (
        <span className={styles.time}>{`${minutes}:${seconds}`}</span>
      )}
    </div>
  );
}

NakedBanner.propTypes = {
  name: string.isRequired,
  minutes: string.isRequired,
  seconds: string.isRequired,
};

export default NakedBanner;
