import { create } from 'zustand';

const tutorialStore = create((set) => ({
  tutorialIsActive: false,
  setTutorialIsActive: (value) => set(() => ({ tutorialIsActive: value })),
  tutorialStep: 0,
  setTutorialStep: (value) => set(() => ({ tutorialStep: value })),
  tutorialDialoguePosition: {},
  setTutorialDialoguePosition: (value) =>
    set(() => ({ tutorialDialoguePosition: value })),
  cogwheelPosition: null,
  setCogwheelPosition: (value) => set(() => ({ cogwheelPosition: value })),
  speakerPosition: null,
  setSpeakerPosition: (value) => set(() => ({ speakerPosition: value })),
  chatIconPosition: {},
  setChatIconPosition: (value) => set(() => ({ chatIconPosition: value })),
  layoutButtonsPosition: {},
  setLayoutButtonsPosition: (value) =>
    set(() => ({ layoutButtonsPosition: value })),
  userProfilePosition: null,
  setUserProfilePosition: (value) =>
    set(() => ({ userProfilePosition: value })),
}));

export default tutorialStore;
