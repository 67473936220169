import React from 'react';
import clsx from 'clsx';
import { arrayOf, shape, string, object } from 'prop-types';

import useBreakpoint, { TABLET_LANDSCAPE } from 'hooks/useBreakpoint';
import useLayoutStore from 'stores/layout';

import DragScroll from 'components/DragScroll/DragScroll';
import BingoCard from 'components/BingoCard/BingoCard';

import styles from './Layout3Rows.module.scss';

function Layout3Rows({ items }) {
  const breakpoint = useBreakpoint();
  const is4x4Tickets = useLayoutStore((state) => state.is4x4Tickets);

  return (
    <DragScroll
      classNameScrollbar={styles.scrollbar}
      classNameWrapper={styles.scrollBarWrapper}
      forceUpdate={items.length}
    >
      <div
        className={clsx(styles.wrapper, {
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
          [styles.isOneTicket]: items.length === 1,
          [styles.is4x4Tickets]: is4x4Tickets,
        })}
      >
        <div className={styles.row}>
          {items.map((card) => (
            <div key={card.id} className={styles.item}>
              <BingoCard
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...card}
                hasFooter
              />
            </div>
          ))}
        </div>
      </div>
    </DragScroll>
  );
}

Layout3Rows.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      numbersMap: arrayOf(arrayOf(object)),
    })
  ),
};

Layout3Rows.defaultProps = {
  items: [],
};

export default Layout3Rows;
